<template>
  <div
    v-if="ready"
    class="portal-template primary"
    :style="{ background: clientTemplate.primaryColor + '!important' }"
  >
    <div role="region" aria-label="Claim tracking header">
      <v-container class="container--fluid pa-0">
        <v-toolbar class="white">
          <v-toolbar-title>
            <img
              :src="
                clientTemplate.logoURL
                  ? clientTemplate.logoURL
                  : '/img/temp-logo.png'
              "
              class="img-responsive logo-img"
              :alt="
                (clientTemplate.displayName
                  ? clientTemplate.displayName
                  : 'Policy') + ' - Logo'
              "
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-container>
    </div>
    <div
      class="content-section"
      role="region"
      aria-label="Claim tracking content"
    >
      <div class="content-container">
        <div
          v-bar="{ useScrollbarPseudo: true }"
          class="details-content elevation-1"
        >
          <div class="scroll-content">
            <div v-if="!isJobCompleted">
              <v-tabs-items v-model="selectedClaimIndex">
                <ValidationObserver v-if="!isUserVerified && isTFAEnabled()">
                  <v-card flat>
                    <v-card-text>
                      <CustomerVerificationClaimTracking :job-id="jobId" />
                    </v-card-text>
                  </v-card>
                </ValidationObserver>
                <v-tab-item
                  v-for="(claimTrackingModel, index) in claimTrackingModelList"
                  :key="index"
                  :value="index"
                >
                  <ClaimTrackingDetails
                    v-if="isUserVerified || !isTFAEnabled()"
                    ref="claimTrackingDetail"
                    :claim-tracking-details-model="claimTrackingModel"
                    :selected-claim-index="selectedClaimIndex"
                    :total-claims-count="claimTrackingModelList.length"
                    :previous-latitude="engineerLocationPreviousLatitude"
                    :previous-longitude="engineerLocationPreviousLongitude"
                    :heading-magnetic-north="headingMagneticNorth"
                    :show-progress-bar="
                      showProgressBar(
                        claimTrackingModel.claimVisitComplete !== undefined
                      )
                    "
                  ></ClaimTrackingDetails>
                </v-tab-item>
              </v-tabs-items>
              <v-btn
                v-if="claimTrackingModelList.length && selectedClaimIndex > 0"
                icon
                class="tab-icon tab-icon--previous"
                :style="{ color: clientTemplate.complementaryColor }"
                text
                label="Move to Previous emergency"
                aria-label="Move to Previous emergency"
                @click="selectedClaimIndex -= 1"
              >
                <v-icon small>arrow_back_ios</v-icon>
              </v-btn>
              <v-btn
                text
                icon
                :style="{ color: clientTemplate.complementaryColor }"
                class="tab-icon tab-icon--next"
                dark
                label="Move to Next emergency"
                aria-label="Move to Next emergency"
                @click="selectedClaimIndex += 1"
              >
                <v-icon small>arrow_forward_ios</v-icon>
              </v-btn>
            </div>
            <div v-else class="job-complete-notification px-5">
              <MessageCard
                message="Your tracking link is expired!"
              ></MessageCard>
            </div>
            <v-tabs
              v-model="selectedClaimIndex"
              background-color="grey lighten-4"
              dark
              :slider-color="clientTemplate.complementaryColor"
            >
              <template v-for="claimTrackingModel in claimTrackingModelList">
                <v-tab
                  v-if="claimTrackingModel.claimEmergencyDetail"
                  :key="claimTrackingModel.id"
                  ripple
                  class="grey--text"
                >
                  <span>
                    <template
                      v-if="
                        claimTrackingModel.claimEmergencyDetail.typeDescription
                      "
                    >
                      {{
                        claimTrackingModel.claimEmergencyDetail.typeDescription
                      }}
                    </template>
                    <template v-else>EMERGENCY</template>
                    <span
                      v-if="
                        claimTrackingModel.claimEmergencyDetail
                          .detailDescription
                      "
                      class="tab-sub-text"
                    >
                      ({{
                        claimTrackingModel.claimEmergencyDetail
                          .detailDescription
                      }})
                    </span>
                  </span>
                </v-tab>
              </template>
            </v-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import ClaimTrackingDetails from '@/components/ClaimTrackingDetails.vue'
import Environment from '@/common/environment'
import ClaimTrackingModel from '@/models/ClaimTracking/ClaimTrackingModel'
import ClaimEmergencyDetail from '@/models/ClaimTracking/ClaimEmergencyDetail'
import SignalRHubConnection from '@/signalr/SignalRHubConnection'
import EngineerAssigned from '@/models/ClaimTracking/EngineerAssigned'
import CoverIncluded from '@/models/ClaimTracking/CoverIncluded'
import EngineerOnTheWay from '@/models/ClaimTracking/EngineerOnTheWay'
import EngineerOnSite from '@/models/ClaimTracking/EngineerOnSite'
import ClaimVisitComplete from '@/models/ClaimTracking/ClaimVisitComplete'
import ClaimRequest from '@/models/ClaimTracking/ClaimRequest'
import { ClaimTrackingType } from '@/common/enums'
import Shared from '@/common/shared'
import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import { ValidationObserver } from 'vee-validate'
import CustomerVerificationClaimTracking from '@/components/CustomerVerificationClaimTracking.vue'
import JobController from '@/api/jobController'

import Vuebar from 'vuebar'
Vue.use(Vuebar)

@Component({
  name: 'ClaimTracking',
  components: {
    ClaimTrackingDetails,
    MessageCard,
    ValidationObserver,
    CustomerVerificationClaimTracking,
  },
})
export default class ClaimTracking extends Vue {
  public claimTrackingModelList: ClaimTrackingModel[] = []
  private claimTrackingModelDetail: ClaimTrackingModel =
    new ClaimTrackingModel()
  private claimEmergencyDetail: ClaimEmergencyDetail[] = []
  public selectedClaimIndex = 0
  private signalRHub: SignalRHubConnection = new SignalRHubConnection(
    'jobTracking'
  )
  public engineerLocationPreviousLatitude = 0
  public engineerLocationPreviousLongitude = 0
  public headingMagneticNorth = 0
  public isJobCompleted = false
  private isCurrentTabIsActive = true
  private localTimer: Date = new Date()
  private ldClientReady = false
  public jobId = ''

  private destroyed() {
    if (this.signalRHub != null) {
      this.signalRHub.disconnect()
    }
  }

  private async created() {
    await this.$ld.waitForInitialization()
    this.ldClientReady = true
    if (this.isTFAEnabled()) {
      JobController.GetJobId().then((res) => {
        this.jobId = res
      })
    } else {
      this.GetJobTrackingDocuments()
      this.signalRJobTrackingRequest()
    }

    this.$store.dispatch('generalModule/updateTrackingId', this.trackingId)
    document.addEventListener('visibilitychange', () => {
      this.isCurrentTabIsActive = document.hidden ? false : true
    })
  }

  public get ready(): boolean {
    return this.ldClientReady && (!this.isTFAEnabled() || !!this.jobId)
  }

  private async GetJobTrackingDocuments() {
    this.isJobCompleted = false
    try {
      this.claimTrackingModelList = await this.$store.dispatch(
        'insurerPortalModule/getJobTrackingDocuments'
      )
    } catch (err: any) {
      this.claimTrackingModelList = []

      if (err.response.status === 401) {
        this.isJobCompleted = true
      } else {
        this.$router.replace('/')
      }
      return
    }

    this.claimTrackingModelList.forEach((element) => {
      this.claimEmergencyDetail.push(element.claimEmergencyDetail)
    })
  }

  private async GetJobTrackingDocumentsWithToken() {
    this.isJobCompleted = false
    try {
      this.claimTrackingModelList = await this.$store.dispatch(
        'claimTrackingModule/getJobTrackingDocuments'
      )
    } catch (err: any) {
      this.claimTrackingModelList = []

      if (err.response.status === 401) {
        this.isJobCompleted = true
      } else if (err.response.status === 403) {
        this.$store.dispatch(
          'claimTrackingModule/resetClaimTrackingState',
          this.jobId
        )
      } else {
        this.$router.replace('/')
      }
      return
    }

    this.claimTrackingModelList.forEach((element) => {
      this.claimEmergencyDetail.push(element.claimEmergencyDetail)
    })
  }

  private async signalRJobTrackingRequest() {
    this.signalRHub.addHandler(
      'jobTrackingRequest',
      async (documentId: string, type: ClaimTrackingType) => {
        const result = await this.$store.dispatch(
          'insurerPortalModule/getJobLocation',
          documentId
        )

        let claimTrackingModel: ClaimTrackingModel | undefined
        switch (type) {
          case ClaimTrackingType.TrackClaimEmergencyDetail: {
            const claimEmergencyDetail: ClaimEmergencyDetail = Object.assign(
              new ClaimEmergencyDetail(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail &&
                c.claimEmergencyDetail.detailId ===
                  claimEmergencyDetail.detailId
            )
            if (!claimTrackingModel) {
              // some time it is possible claim request comes before claim emergency detail request
              claimTrackingModel = this.claimTrackingModelList.find(
                (c) =>
                  c.claimRequest.forEmergencyDetailId ===
                  claimEmergencyDetail.detailId
              )
            }
            if (claimTrackingModel) {
              claimTrackingModel.claimEmergencyDetail = claimEmergencyDetail
            } else {
              claimTrackingModel = new ClaimTrackingModel()
              claimTrackingModel.claimEmergencyDetail = claimEmergencyDetail
              this.claimTrackingModelList.push(claimTrackingModel)
            }
            break
          }
          case ClaimTrackingType.TrackClaimRequest: {
            const claimRequest: ClaimRequest = Object.assign(
              new ClaimRequest(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail &&
                c.claimEmergencyDetail.detailId ===
                  claimRequest.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.claimRequest = claimRequest
            } else {
              claimTrackingModel = new ClaimTrackingModel()
              claimTrackingModel.claimRequest = claimRequest
              this.claimTrackingModelList.push(claimTrackingModel)
            }
            break
          }
          case ClaimTrackingType.TrackCoverIncluded: {
            const coverIncluded: CoverIncluded = Object.assign(
              new CoverIncluded(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                coverIncluded.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.coverIncluded = coverIncluded
            }
            break
          }
          case ClaimTrackingType.TrackEngineerAssigned: {
            const engineerAssigned: EngineerAssigned = Object.assign(
              new EngineerAssigned(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerAssigned.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.engineerAssigned = engineerAssigned
            }
            break
          }
          case ClaimTrackingType.TrackEngineerOnTheWay: {
            if (!this.isCurrentTabIsActive) {
              return
            }
            const engineerOnTheWay: EngineerOnTheWay = Object.assign(
              new EngineerOnTheWay(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerOnTheWay.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              if (!claimTrackingModel.engineerOnTheWay) {
                this.engineerLocationPreviousLatitude =
                  engineerOnTheWay.latitude
                this.engineerLocationPreviousLongitude =
                  engineerOnTheWay.longitude
              } else {
                // take past lat long to show transaction
                this.engineerLocationPreviousLatitude =
                  claimTrackingModel.engineerOnTheWay.latitude
                this.engineerLocationPreviousLongitude =
                  claimTrackingModel.engineerOnTheWay.longitude
              }
              this.headingMagneticNorth = engineerOnTheWay.headingMagneticNorth
              claimTrackingModel.engineerOnTheWay = engineerOnTheWay
            }
            break
          }
          case ClaimTrackingType.TrackEngineerOnSite: {
            const engineerOnSite: EngineerOnSite = Object.assign(
              new EngineerOnSite(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                engineerOnSite.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.engineerOnSite = engineerOnSite
            }
            break
          }
          case ClaimTrackingType.TrackClaimVisitComplete: {
            const claimVisitComplete: ClaimVisitComplete = Object.assign(
              new ClaimVisitComplete(),
              result
            )
            claimTrackingModel = this.claimTrackingModelList.find(
              (c) =>
                c.claimEmergencyDetail.detailId ===
                claimVisitComplete.forEmergencyDetailId
            )
            if (claimTrackingModel) {
              claimTrackingModel.claimVisitComplete = claimVisitComplete
            }
            break
          }
        }
      }
    )

    // assign empty model on job abandoned
    this.signalRHub.addHandler('deleteTrackingDocuments', (visitId: string) => {
      const trackingModelIndex: number = this.claimTrackingModelList.findIndex(
        (model) =>
          model.engineerAssigned
            ? model.engineerAssigned.engineerVisitDetailId === visitId
            : false
      )
      if (trackingModelIndex !== -1) {
        const trackingModel = this.claimTrackingModelList[trackingModelIndex]
        // revert emergency tracking on abandoned
        trackingModel.engineerAssigned = null
        trackingModel.engineerOnTheWay = null
        trackingModel.engineerOnSite = null
        this.claimTrackingModelList.splice(trackingModelIndex, 1, trackingModel)
        // revert the progress bar and header text
        const trackingDetail = this.$refs
          .claimTrackingDetail as ClaimTrackingDetails[]
        const index = this.claimTrackingModelList.indexOf(trackingModel)
        trackingDetail[index].updatedClaimTrackingModel(trackingModel, true)
      }
    })

    // later on we will pass tracking ID over here
    this.signalRHub.connect(this.trackingId)
    await this.$store.dispatch(
      'generalModule/addUserToNotificationsForJobTracking'
    )
  }

  private mounted() {
    window.addEventListener(
      'beforeunload',
      () => {
        // send event updates to google analytics
        Shared.sendEventsInGoogleAnalytics(
          'Tracking Page',
          'TIME SPENT',
          this.localTimer
        )
      },
      false
    )
  }

  public showProgressBar(isEmergencyCompleted: boolean) {
    return !!isEmergencyCompleted
  }

  public get isUserVerified(): boolean {
    return this.$store.getters['claimTrackingModule/isUserVerifiedSuccess']
  }

  @Watch('isUserVerified')
  private async verificationChanged(verified: boolean) {
    if (verified && this.isTFAEnabled()) {
      await this.GetJobTrackingDocumentsWithToken()
      await this.signalRJobTrackingRequest()
    }
  }

  //Cannot be a getter - causing synchonisation issues
  public isTFAEnabled(): boolean {
    return !!this.$ld.variation('fnol-65-two-factor-auth-on-claim-tracking')
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get trackingId(): string {
    return this.$route.params.trackingId
  }
}
</script>

<style scoped lang="scss">
.content-container {
  box-sizing: border-box;
  height: 100vh;
  margin-top: -168px;
  padding-top: 168px;

  // Account for the header height change
  @media (max-width: 960px) {
    margin-top: -160px;
    padding-top: 160px;
  }
}

::v-deep .content-section {
  padding-bottom: 24px !important;
  padding-top: 24px !important;
  position: relative;

  .tab-icon {
    position: absolute;
    top: 50%;
    font-weight: 600;

    i {
      font-size: 24px !important;
    }

    &--previous {
      left: 0px;

      i {
        padding-left: 9px;
      }
    }

    &--next {
      right: 0px;
    }

    @media (max-width: 420px) {
      &--previous {
        left: -13px;
      }

      &--next {
        right: -11px;
      }
    }
  }

  .details-content {
    background-color: #fff;
    height: 100%;
    margin: auto;
    max-width: 100%;
    width: 500px;

    .v-card {
      padding-left: 80px !important;
      padding-right: 40px !important;
    }

    @media (max-width: 420px) {
      .v-card {
        padding-left: 50px !important;
        padding-right: 0px !important;
      }

      .carousel {
        &__right {
          right: -12px;
        }

        &__left {
          left: -12px;
        }
      }
    }
  }

  .v-tabs {
    bottom: 0;
    flex-wrap: wrap;
    height: auto;
    position: absolute;
    text-align: center;
    width: 100%;

    &-bar {
      box-shadow: 0px -1px 1px 0px #f1f1f1;
      width: 500px;
      z-index: 2;
      width: 100%;

      > i {
        color: #909090;
        display: inline-flex;
      }
    }

    .v-tab {
      flex-wrap: wrap;
      text-align: center;
      max-width: inherit !important;
      font-size: unset;

      @media (max-width: 420px) {
        font-size: 12px;
      }

      .tab-sub-text {
        display: block;
        font-size: 11px;
        width: 100%;
      }

      > span {
        display: block;
        width: 100%;
      }

      &--active {
        background-color: #ffffff;
        box-shadow: 0px 0px 3px 0px #c5c5c5;
        color: #ca054f !important;
        font-weight: 600;
      }
    }
  }
}

.portal-template {
  height: 100%;
}

header .v-toolbar__title {
  display: flex;
  max-width: 230px;
}

.logo-img {
  height: 42px;
  object-fit: contain;
}

.scroll-content {
  overflow-y: auto;
  height: 100%;
}
</style>
