<template>
  <v-container class="pa-0" role="region" aria-label="Phone number">
    <v-row class="pa-0 ma-0">
      <v-col cols="12" class="contact-number pa-0">
        <v-select
          :id="selectClassName"
          v-model="selectedCountry"
          :items="countries"
          :class="'selected-country pt-0 ' + selectClassName"
          append-icon=""
          hide-details
          aria-label="Code"
          single-line
          :disabled="isDisabled"
          @change="changedCountryValue"
        >
          <template slot="selection" slot-scope="data">
            <div
              :id="'selected-country-code-' + selectClassName"
              class="v-select__selection"
              aria-haspopup="listbox"
              role="button"
              aria-expanded="false"
              :aria-label="'Code' + data.item.cc + data.item.name"
            >
              {{ data.item.cc || 'INT' }}
            </div>
          </template>
          <template #item="{ item, on }">
            <v-list-item
              role="text"
              tabindex="0"
              :aria-label="
                getSelectedItem(item.code)
                  ? item.name +
                    (item.cc ? ' (' + item.cc + ')' : '') +
                    'list item selected'
                  : item.name + (item.cc ? ' (' + item.cc + ')' : '')
              "
              class="country-list-item d-flex"
              v-on="on"
            >
              <v-list-item-avatar>
                <div :class="'flag flag-' + item.code"></div>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle
                  v-text="item.name + (item.cc ? ' (' + item.cc + ')' : '')"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
        <ValidationProvider
          v-slot="{ errors }"
          name="Phone Contact"
          :rules="{
            required: !isDisabled,
            validCountry: { code: selectedCountry.code },
            possiblePhoneNumber: { code: selectedCountry.code },
            validPhoneNumber: { code: selectedCountry.code },
          }"
        >
          <v-text-field
            v-if="isValidationRequired"
            ref="txtNumber"
            v-model="phoneNumber"
            :error-messages="errors"
            single-line
            class="call-number-txt pt-0 d-inline-block"
            label="Enter Number"
            aria-required="true"
            :disabled="isDisabled"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0 hide">
      <v-col cols="12" class="country-list">
        <v-list>
          <v-list-item
            v-for="(item, index) in countries"
            :key="index"
            class="d-flex"
            tow-line
          >
            <v-list-item-icon>
              <div :class="'flag flag-' + item.code"></div>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-subtitle v-text="item.name + ' (' + item.cc + ')'" />
            </v-list-item-content>
            <v-divider></v-divider>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'
import InsurerPortalModel from '@/models/InsurerPortalModel'

@Component
export default class PhoneNumber extends Vue {
  @Prop() public isValidationRequired: boolean
  @Prop() public selectClassName: string
  @Prop() public isDisabled: boolean

  // list of available countries (when adding to this list, make sure to add them to the style section)
  private countries: any[] = [
    { name: 'Great Britain', cc: '+44', code: 'gb' },
    { name: 'Ireland', cc: '+353', code: 'ie' },
    { name: 'International', code: 'eu' },
  ]
  private selectedCountry: any = this.countries[0]
  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get phoneNumber(): string {
    return this.formatPhoneNumber(
      this.insurerPortal.mobilePhone,
      PhoneNumberFormat.NATIONAL
    )
  }

  private set phoneNumber(value) {
    this.$store.dispatch(
      'insurerPortalModule/setPhoneNumber',
      this.formatPhoneNumber(value.trim())
    )
    if (!this.isCountryCodeCorrect(this.insurerPortal.mobilePhone)) {
      this.setDefaultCountry()
    }
  }

  private mounted() {
    if (this.phoneNumber) {
      this.phoneNumber = this.phoneNumber.trim().replace(/[^0-9]/g, '')
      this.setDefaultCountry()
    }
  }

  private changedCountryValue(value: any): void {
    this.selectedCountry = value
  }
  private isCountryCodeCorrect(phoneNumber: string): boolean {
    if (!this.selectedCountry.cc) {
      return false
    }
    const isCCInputted =
      phoneNumber.length - this.selectedCountry.cc.length >= 1
    return !isCCInputted || phoneNumber.includes(this.selectedCountry.cc)
  }

  private formatPhoneNumber(
    value: string,
    formatType = PhoneNumberFormat.INTERNATIONAL
  ) {
    if (this.selectedCountry.code === 'eu') {
      return value
    }
    let util: any = PhoneNumberUtil.getInstance()
    try {
      const phoneNumberObject: any = util.parseAndKeepRawInput(
        value,
        this.selectedCountry.code
      )
      return util.format(phoneNumberObject, formatType).replace(/[ (-)]/g, '')
    } catch (error) {
      // ignore this error
    }
    return value
  }

  private setDefaultCountry(): void {
    // check if we can get a country code out of it
    if (
      !this.insurerPortal.mobilePhone ||
      this.insurerPortal.mobilePhone.startsWith('0')
    ) {
      this.selectedCountry = this.countries[0]
    } else if (this.insurerPortal.mobilePhone.startsWith('+')) {
      const matchCountryCodes: string = this.countries
        .filter((x) => x.cc)
        .map((x) => x.cc.replace('+', '\\+'))
        .join('|')
      const matchPhoneNumber = matchCountryCodes + '\\d+'
      if (RegExp(matchPhoneNumber).test(this.insurerPortal.mobilePhone)) {
        const matches = this.insurerPortal.mobilePhone.match(matchCountryCodes)
        if (matches && matches.length > 0) {
          const index = this.countries.map((e) => e.cc).indexOf(matches[0])
          if (index >= 0) {
            this.selectedCountry = this.countries[index]
          } else {
            this.selectedCountry = this.countries[0]
          }
        } else {
          this.selectedCountry = this.countries[0]
        }
      } else {
        this.selectedCountry = this.countries[this.countries.length - 1]
      }
    }
  }

  private getSelectedItem(code: string) {
    return this.selectedCountry && this.selectedCountry.code === code
  }

  public get flagUrl() {
    return `url('${process.env.PUBLIC_URL}/img/flags.png')`
  }
}
</script>

<style scoped lang="scss">
.selected-country {
  width: 50px;
  display: inline-block;
}
.call-number-txt {
  width: calc(100% - 50px);
}
.country-list-item {
  ::v-deep .v-list-item__subtitle {
    font-size: 14px;
  }
}
</style>
