<template>
  <div role="region" aria-label="header">
    <h1 class="title pa-2">Record not found.</h1>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class NotFound extends Vue {}
</script>
