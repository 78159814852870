<template>
  <v-dialog
    v-model="isOpen"
    content-class="thanks-dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-card-text>
        <div class="text-center">
          <v-icon
            class="thanks-dialog__icon"
            :style="{ color: clientTemplate.primaryColor }"
          >
            {{ isClaimCovered ? 'check_circle' : 'done' }}
          </v-icon>
          <div class="display-1 mt-1">Thank you!</div>
        </div>
        <div class="thanks-dialog__content text-center pa-2 mt-3">
          <p class="thanks-dialog__description mb-0">
            {{
              isClaimCovered
                ? 'A member of our team will be in touch with you shortly.'
                : 'Thanks for contacting us, a member of the team will be in touch.'
            }}
          </p>
          <v-btn
            :style="{ backgroundColor: clientTemplate.primaryColor }"
            dark
            label="Track my claim"
            aria-label="Track my claim"
            @click.stop="trackClaim"
          >
            Track my Claim
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import ClientTemplate from '@/models/client/client-template'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class ThanksDialog extends Vue {
  @Prop({ default: false }) private isOpen: boolean
  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }
  private get clientName(): string {
    return this.$route.params.clientName
  }

  private trackClaim() {
    const url: string = this.clientName
      ? `${this.clientName}/claimtracking`
      : 'claimtracking'
    this.$router.replace(`/${url}/${this.getTrackingId}`)
  }

  private get isClaimCovered(): boolean {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
      .isPolicyCovered
  }
}
</script>

<style scoped lang="scss">
.thanks-dialog {
  &__icon {
    font-size: 100px !important;
  }
  &__content {
    font-size: 18px;
  }
  &__description {
    line-height: 1.5;
  }
}
</style>
