<template>
  <div
    class="portal-template"
    :style="{ backgroundColor: clientTemplate.primaryColor }"
  >
    <div
      role="region"
      aria-label="Upload Invoice Receipt for Customer Appointed Tradespeople header"
    >
      <v-container class="container--fluid pa-0">
        <v-toolbar fixed class="white">
          <v-toolbar-title>
            <img
              :src="
                clientTemplate.logoURL
                  ? clientTemplate.logoURL
                  : environment.policyHolderAppBaseLogoUrl
              "
              class="img-responsive logo-img"
              :alt="
                (clientTemplate.displayName
                  ? clientTemplate.displayName
                  : 'Policy') + ' - Logo'
              "
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </v-container>
    </div>
    <div
      class="content-section pa-4"
      role="region"
      aria-label="Upload Invoice Receipt for Customer Appointed Tradespeople content"
    >
      <div class="details-content elevation-1">
        <div v-if="isLoading">
          <v-progress-circular
            class="loading-spinner"
            :width="2"
            :size="50"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else>
          <template v-if="isLinkValid">
            <div class="template-tab">
              <div class="mid-content">
                <div v-bar="{ useScrollbarPseudo: true }">
                  <div>
                    <v-card flat>
                      <v-card-text>
                        <ImageUpload
                          ref="imageUpload"
                          :image-type="catInvoiceImageType"
                          :allow-multiple-uploads="false"
                          :header-text="'Upload Invoice Receipt for Customer Appointed Tradespeople'"
                        />
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="job-complete-notification px-5">
            <MessageCard message="Unknown or expired link!"></MessageCard>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import MessageCard from '@/components/MessageCard.vue'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ImageType } from '@/common/enums'

@Component({
  name: 'uploadInvoiceCAT',
  components: {
    ImageUpload,
    MessageCard,
  },
})
export default class UploadInvoiceCAT extends Vue {
  private isLinkValid = false
  private recordId = ''
  private catInvoiceImageType: ImageType = ImageType.CATInvoice
  private isLoading = true

  private async created() {
    this.recordId = this.$route.params.recordId
    if (this.$route.params.trackingId && this.recordId) {
      if (!this.getTrackingId) {
        this.$store.dispatch(
          'generalModule/updateTrackingId',
          this.$route.params.trackingId
        )
      }
      await this.$store
        .dispatch(
          'imageModule/submitAuthoriseCustomerToUploadReceipt',
          this.recordId
        )
        .then((isValid: boolean) => {
          this.isLoading = false
          this.isLinkValid = isValid
        })
    }
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
</script>

<style scoped>
.toolbar {
  z-index: 9;
}
.portal-template {
  height: 100%;
  min-width: 100%;
}
.template-tab .mid-content {
  overflow-y: auto;
  height: calc(100vh - 179px);
}
.portal-template >>> .v-toolbar__title {
  max-width: 230px;
}
.logo-img {
  height: 42px;
  object-fit: contain;
}
.mid-content >>> .emergency-question-content > div {
  width: 100%;
}
.content-section {
  padding-top: 90px !important;
  padding-bottom: 24px !important;
}
.content-section .details-content {
  max-width: 500px;
  margin: auto;
  background-color: #fff;
}
.content-section,
.content-section .details-content {
  height: 100%;
}
.template-tab {
  height: 100%;
}
.insureractions {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 500px;
  margin: auto;
  z-index: 2;
  bottom: 24px;
  border-top: 1px solid #e2e2e2 !important;
}
.insureractions >>> .v-btn .v-btn__content {
  padding: 0px 6px !important;
  font-size: 12px;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
}
@media (max-width: 768px) {
  .v-container {
    padding: 0px !important;
  }
}
@media (max-width: 548px) {
  .insureractions {
    left: 24px;
    right: 24px;
  }
}
@media (max-width: 420px) {
  .content-section {
    padding: 10px !important;
    padding-top: 70px !important;
  }
  .header .v-toolbar__title {
    max-width: 180px !important;
  }
  .insureractions {
    bottom: 10px !important;
    left: 10px !important;
    right: 10px !important;
  }
}
</style>
