<template>
  <div class="wrapper">
    <h1 v-if="!clientSettings">Not Found</h1>
    <h1 v-else>This portal is currently offline</h1>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { IClientTemplate } from './models/client/interfaces/client-template'

@Component
export default class PortalOffline extends Vue {
  @Prop()
  public clientSettings: IClientTemplate
}
</script>

<style lang="css" scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: blue;
  color: white;
  line-height: normal;
  text-align: center;
}
</style>
