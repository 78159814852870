var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"client-details",attrs:{"role":"region","aria-label":"Customer details"}},[_c('div',{attrs:{"role":"form","aria-label":"Customer detail form"}},[_c('v-container',{staticClass:"px-2",attrs:{"fluid":""}},[_c('h2',{staticClass:"client-details__title",style:({ color: _vm.clientTemplate.primaryColor })},[_vm._v(" About You ")]),_c('v-row',{staticClass:"mb-0 px-4 ma-0",style:({ background: _vm.clientTemplate.secondaryColor }),attrs:{"wrap":""}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select-custom',{class:'required client-title',attrs:{"id":'client-title',"aria-label":"Title","return-object":"","items":_vm.clientTitleList,"required":"","single-line":"","error-messages":errors,"placeholder":"Select Title"},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-list-item-content',{staticClass:"v-select__selection",attrs:{"id":'selected-title',"aria-haspopup":"listbox","role":"button","aria-expanded":"false","aria-label":'Title ' + data.item}},[_vm._v(" "+_vm._s(data.item)+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{attrs:{"id":data.item,"role":"text","tabindex":"0","aria-label":_vm.getSelectedItem(data.item)
                      ? data.item + ' list item selected'
                      : data.item}},[_vm._v(" "+_vm._s(data.item)+" ")])]}}],null,true),model:{value:(_vm.insurerPortal.clientTitle),callback:function ($$v) {_vm.$set(_vm.insurerPortal, "clientTitle", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insurerPortal.clientTitle"}})]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"first name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{ref:"clientForenameField",staticClass:"required",attrs:{"label":"First Name","aria-required":"true","maxlength":"50","required":"","error-messages":errors},model:{value:(_vm.insurerPortal.clientForename),callback:function ($$v) {_vm.$set(_vm.insurerPortal, "clientForename", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insurerPortal.clientForename"}})]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"last name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{staticClass:"required",attrs:{"error-messages":errors,"label":"Last Name","aria-required":"true","maxlength":"50","required":""},model:{value:(_vm.insurerPortal.clientLastName),callback:function ($$v) {_vm.$set(_vm.insurerPortal, "clientLastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insurerPortal.clientLastName"}})]}}])})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Contact Preference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-radio-group',{class:_vm.contactPreference !== _vm.contactPreferenceEnum.SMS
                    ? 'custom-value'
                    : 'show-number',attrs:{"id":"contactPreference","disabled":_vm.isUserVerifiedSuccess,"error-messages":errors,"mandatory":false,"name":"contactPreferenceRadioGroup"},model:{value:(_vm.contactPreference),callback:function ($$v) {_vm.contactPreference=$$v},expression:"contactPreference"}},[_c('v-row',{staticClass:"mx-0"},[_c('v-col',{attrs:{"cols":"auto pa-0"}},[_c('div',{staticClass:"client-details__radio-btn"},[_c('v-radio',{attrs:{"color":"primary","value":_vm.contactPreferenceEnum.SMS,"label":"SMS","name":"contactPreferenceRadioSMS","role":"radio"}})],1),_c('div',{staticClass:"client-details__radio-btn"},[_c('v-radio',{staticClass:"pr-3",attrs:{"color":"primary","value":_vm.contactPreferenceEnum.Email,"label":"Email","name":"contactPreferenceRadioEmail","role":"radio"}})],1)]),_c('v-col',{staticClass:"pa-0"},[_c('div',{staticClass:"mt-2",class:_vm.contactPreference !== _vm.contactPreferenceEnum.SMS
                          ? 'client-details__input-content--hidden'
                          : ''},[_c('PhoneNumber',{ref:"contactPreferenceSMS",attrs:{"is-validation-required":true,"is-disabled":_vm.isUserVerifiedSuccess ||
                          _vm.contactPreference !== _vm.contactPreferenceEnum.SMS}})],1),_c('div',{staticClass:"mt-2",class:_vm.contactPreference !== _vm.contactPreferenceEnum.Email
                          ? 'client-details__input-content--hidden'
                          : ''},[_c('ValidationProvider',{attrs:{"name":"Contact Email","rules":{
                          required:
                            _vm.contactPreference === _vm.contactPreferenceEnum.Email,
                          email: true,
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors: emailErrors }){return [_c('v-text-field',{staticClass:"required pt-0",attrs:{"error-messages":emailErrors,"aria-label":"Contact Email","aria-required":"true","maxlength":"50","disabled":_vm.isUserVerifiedSuccess ||
                            _vm.contactPreference !== _vm.contactPreferenceEnum.Email,"required":""},model:{value:(_vm.insurerPortal.email),callback:function ($$v) {_vm.$set(_vm.insurerPortal, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"insurerPortal.email"}})]}}],null,true)})],1)])],1)],1)]}}])})],1)])],1),(_vm.clientTemplate.privacyPolicyText)?_c('div',{staticClass:"mt-4"},[_c('h2',{staticClass:"client-details__title",style:({
            color: _vm.clientTemplate.primaryColor,
          })},[_vm._v(" Terms & Conditions ")]),_c('div',{style:({ background: _vm.clientTemplate.secondaryColor })},[_c('div',{staticClass:"mt-2 pa-2 client-details__termsConditions",style:({
              background: _vm.clientTemplate.secondaryColor,
            })},[_c('span',{style:({
                color: _vm.clientTemplate.complementaryColor,
              })},[_vm._v(" Please read our terms and conditions carefully. ")]),_c('a',{style:({
                color: _vm.clientTemplate.complementaryColor,
              }),attrs:{"href":"Javascript:void(0)"},on:{"click":_vm.showPrivacyPolicyDetailsDialog}},[_vm._v("Terms & Conditions")]),_c('div',[_c('ValidationProvider',{attrs:{"name":"isTermsAndServicesAccepted","rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function({ invalid, validated }){return [_c('v-checkbox',{attrs:{"required":"","error":validated && invalid,"color":validated && invalid
                      ? _vm.clientTemplate.errorColor
                      : _vm.clientTemplate.complementaryColor,"label":"By submitting your claim you agree to be contacted by email and phone."},model:{value:(_vm.isTermsAndServicesAccepted),callback:function ($$v) {_vm.isTermsAndServicesAccepted=$$v},expression:"isTermsAndServicesAccepted"}})]}}],null,false,179173816)})],1)])])]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }