<template>
  <section role="region" aria-label="Claim tracking details">
    <div class="multiple-tracking">
      <v-row v-if="claimTrackingDetailModel" class="flex-wrap pa-4 ma-0">
        <v-col cols="12" class="text-center mb-6 pa-0">
          <h1 class="custom-title">YOUR CLAIM STATUS</h1>
        </v-col>

        <!-- progress bar indication -->
        <div
          v-if="showProgressBar && isProgressBarLoaderVisible"
          class="refresh-progressbar"
        >
          <v-card :style="{ color: clientTemplate.primaryColor }" flat>
            <v-card-text>
              checking for updates
              <v-progress-linear
                v-if="showProgressBar && isProgressBarLoaderVisible"
                indeterminate
                :style="{ color: clientTemplate.secondaryColor }"
                class="mb-0"
              />
            </v-card-text>
          </v-card>
        </div>

        <v-col cols="12" class="pa-0">
          <v-row class="flex-wrap ma-0">
            <v-col
              v-if="currentClaimDetails"
              cols="12"
              class="text-center pa-0"
            >
              <div class="claim-progress-bar">
                <v-progress-circular
                  aria-label="progress-bar"
                  :value="trackingCompletionRatio"
                  :size="180"
                  :rotate="-90"
                  :width="10"
                  :style="{ color: clientTemplate.primaryColor }"
                />
                <div class="claim-info-process-bar">
                  <span class="text-center px-6">
                    <span class="custom-body">
                      {{ currentClaimDetails.description }}:
                    </span>
                    <br />
                    <span
                      class="time custom-display"
                      :style="{
                        color: clientTemplate.complementaryColor,
                      }"
                    >
                      {{ currentClaimDetails.createdAtTime }}
                    </span>
                    <br />
                    <span class="custom-subtitle">{{
                      currentClaimDetails.createdAtDate
                    }}</span>
                  </span>
                </div>
              </div>
            </v-col>

            <v-col cols="12" class="text-center pa-0">
              <p
                v-if="
                  claimTrackingDetailsModel &&
                  claimTrackingDetailsModel.claimRequest
                "
                :style="{ color: clientTemplate.complementaryColor }"
                class="pt-2 ma-0 slider-count-text"
              >
                Job ID: {{ claimTrackingDetailsModel.claimRequest.jobId }}
              </p>
              <p
                :style="{ color: clientTemplate.complementaryColor }"
                class="pt-2 ma-0 slider-count-text"
              >
                Emergency {{ currentClaimIndex }} of {{ totalClaimsCount }}
              </p>
            </v-col>

            <v-col cols="12" class="pa-0">
              <v-card class="px-6 pt-4 pb-6 elevation-0 process-cycle">
                <div
                  v-if="claimTrackingDetailModel.claimRequest"
                  class="step-process"
                >
                  <div class="inner-content elevation-1 white px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{ claimTrackingDetailModel.claimRequest.description }}
                    </h2>
                    <span class="show-date grey--text">
                      <v-icon small>calendar_today</v-icon>
                      <span>
                        {{
                          getETADate(
                            claimTrackingDetailModel.claimRequest.createdAt
                          )
                        }}
                      </span>
                    </span>
                  </div>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <v-icon class="mb-2" color="white">get_app</v-icon>
                  </span>
                </div>
                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">CLAIM REQUEST RECEIVED"</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3">
                    <v-icon class="mb-2 grey--text">get_app</v-icon>
                    <br />
                  </span>
                </div>

                <div
                  v-if="claimTrackingDetailModel.coverIncluded"
                  class="step-process"
                >
                  <span class="inner-content elevation-1 px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{ claimTrackingDetailModel.coverIncluded.description }}
                    </h2>
                    <span class="show-date grey--text"
                      ><v-icon small>calendar_today</v-icon>
                      <span>
                        {{
                          getETADate(
                            claimTrackingDetailModel.coverIncluded.createdAt
                          )
                        }}</span
                      >
                      >
                    </span>
                  </span>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <v-icon class="mb-2" color="white">check</v-icon>
                  </span>
                </div>

                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">COVER INCLUDED</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3">
                    <v-icon class="mb-2 grey--text">check</v-icon>
                    <br />
                  </span>
                </div>

                <div
                  v-if="claimTrackingDetailModel.engineerAssigned"
                  class="step-process"
                >
                  <span class="inner-content elevation-1 px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{
                        claimTrackingDetailModel.engineerAssigned.description
                      }}
                    </h2>
                    <span class="profile-details">
                      <v-avatar slot="activator" size="40px">
                        <img
                          v-if="
                            claimTrackingDetailModel.engineerAssigned
                              .fileURL !== null &&
                            claimTrackingDetailModel.engineerAssigned
                              .fileURL !== ''
                          "
                          :src="
                            claimTrackingDetailModel.engineerAssigned.fileURL
                          "
                          alt=""
                        />
                        <img v-else src="/img/user.png" alt="" />
                      </v-avatar>
                      <span class="profile-details__name">
                        <h4>
                          {{ claimTrackingDetailModel.engineerAssigned.name }}
                        </h4>
                      </span>
                      <span class="show-date grey--text"
                        ><v-icon small>calendar_today</v-icon>
                        <span>
                          {{
                            getETADate(
                              claimTrackingDetailModel.engineerAssigned
                                .createdAt
                            )
                          }}</span
                        >
                      </span>
                    </span>
                  </span>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center grey lighten-3 svg-icon"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <svg
                      x="0px"
                      y="0px"
                      width="18px"
                      height="18px"
                      viewBox="0 0 535.806 535.807"
                    >
                      <g>
                        <path
                          d="M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z"
                          fill="#fff"
                        />
                        <path
                          d="M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z"
                          fill="#fff"
                        />
                        <path
                          d="M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z"
                          fill="#fff"
                        />
                        <path
                          d="M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z"
                          fill="#fff"
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>
                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">ENGINEER ASSIGNED</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3 svg-icon">
                    <svg
                      x="0px"
                      y="0px"
                      width="18px"
                      height="18px"
                      viewBox="0 0 535.806 535.807"
                    >
                      <g>
                        <path
                          d="M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z"
                          fill="#9e9e9e"
                        />
                        <path
                          d="M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z"
                          fill="#9e9e9e"
                        />
                        <path
                          d="M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z"
                          fill="#9e9e9e"
                        />
                        <path
                          d="M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z"
                          fill="#9e9e9e"
                        />
                        />
                      </g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                      <g></g>
                    </svg>
                  </span>
                </div>

                <div
                  v-if="claimTrackingDetailModel.engineerOnTheWay"
                  class="step-process"
                >
                  <span class="inner-content elevation-1 px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{
                        claimTrackingDetailModel.engineerOnTheWay.description
                      }}
                    </h2>
                    <div
                      v-if="
                        claimTrackingDetailModel.engineerOnTheWay
                          .customerProxyNumber &&
                        !claimTrackingDetailModel.claimVisitComplete
                      "
                      class="details"
                    >
                      >
                      <span class="d-inline-block pt-2">
                        <v-icon class="mt-0">call</v-icon>
                        <b class="pt-2">
                          {{
                            claimTrackingDetailModel.engineerOnTheWay
                              .customerProxyNumber
                          }}</b
                        >
                      </span>
                    </div>
                    <div
                      v-if="
                        claimTrackingDetailModel.engineerOnTheWay
                          .customerProxyNumber &&
                        !claimTrackingDetailModel.claimVisitComplete
                      "
                    >
                      <span class="d-inline-block mt-1 pb-1 caption"
                        ><v-icon class="mt-0">info</v-icon> Calls will be
                        Recorded</span
                      >
                    </div>
                    <span class="show-date grey--text"
                      ><v-icon small>calendar_today</v-icon>
                      <span>
                        {{
                          getETADate(
                            claimTrackingDetailModel.engineerOnTheWay.createdAt
                          )
                        }}</span
                      >
                    </span>
                  </span>
                  <div v-if="isMapCoordinatesExists" class="mt-2 map">
                    <Map
                      :map-id="new Date().getTime().toString()"
                      :source-latitude="
                        previousLatitude
                          ? previousLatitude
                          : claimTrackingDetailsModel.engineerOnTheWay.latitude
                      "
                      :source-longitude="
                        previousLongitude
                          ? previousLongitude
                          : claimTrackingDetailsModel.engineerOnTheWay.longitude
                      "
                      :previous-latitude="
                        claimTrackingDetailsModel.engineerOnTheWay.latitude
                      "
                      :previous-longitude="
                        claimTrackingDetailsModel.engineerOnTheWay.longitude
                      "
                      :destination-latitude="customerAddressLatitude"
                      :destination-longitude="customerAddressLongitude"
                      :heading-magnetic-north="headingMagneticNorth"
                      :show-fullscreen-control="true"
                      :disable-default-u-i-control="true"
                      style="height: 200px"
                      :zoom-level="15"
                    ></Map>
                  </div>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center grey lighten-4"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <v-icon class="mb-2 white--text">local_shipping</v-icon>
                  </span>
                </div>
                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">ENGINEER ON THE WAY</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3">
                    <v-icon class="mb-2 grey--text">local_shipping</v-icon>
                    <br />
                  </span>
                </div>

                <div
                  v-if="claimTrackingDetailModel.engineerOnSite"
                  class="step-process"
                >
                  <span class="inner-content elevation-1 px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{ claimTrackingDetailModel.engineerOnSite.description }}
                    </h2>
                    <span class="show-date grey--text"
                      ><v-icon small>calendar_today</v-icon>
                      <span>
                        {{
                          getETADate(
                            claimTrackingDetailModel.engineerOnSite.createdAt
                          )
                        }}</span
                      >
                      >
                    </span>
                  </span>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center grey lighten-3"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <v-icon class="mb-2 white--text">build</v-icon>
                  </span>
                </div>
                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">ENGINEER ON JOB SITE</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3">
                    <v-icon class="mb-2 grey--text">build</v-icon>
                    <br />
                  </span>
                </div>

                <div
                  v-if="claimTrackingDetailModel.claimVisitComplete"
                  class="step-process"
                >
                  <span class="inner-content elevation-1 px-2 py-4">
                    <h2
                      class="subheading font-weight-bold"
                      :style="{
                        color: clientTemplate.primaryColor + '!important',
                      }"
                    >
                      {{
                        claimTrackingDetailModel.claimVisitComplete.description
                      }}
                    </h2>
                    <span class="show-date grey--text"
                      ><v-icon small>calendar_today</v-icon>
                      <span>
                        {{
                          getETADate(
                            claimTrackingDetailModel.claimVisitComplete
                              .createdAt
                          )
                        }}</span
                      >
                    </span>
                  </span>
                  <span
                    class="bar"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  ></span>
                  <span
                    class="bar-circle text-center grey lighten-3"
                    :style="{
                      background: clientTemplate.primaryColor + '!important',
                    }"
                  >
                    <v-icon class="mb-2 white--text">home</v-icon>
                  </span>
                </div>
                <div v-else class="step-process">
                  <span class="inner-content grey lighten-3 px-2 py-4">
                    <span class="grey--text">Visit completed</span>
                  </span>
                  <span class="bar"></span>
                  <span class="bar-circle text-center grey lighten-3">
                    <v-icon class="mb-2 grey--text">home</v-icon>
                    <br />
                  </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-show="!ready" cols="12" class="pa-0">
          <v-progress-circular
            class="loading-spinner"
            :width="2"
            :size="50"
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import moment, { Moment } from 'moment'
import Environment from '@/common/environment'
import ClaimTrackingModel from '@/models/ClaimTracking/ClaimTrackingModel'
import Shared from '@/common/shared'
import Map from '@/components/Map.vue'
import ClientTemplate from '@/models/client/client-template'

@Component({
  name: 'ClaimTrackingDetails',
  components: {
    Map,
  },
})
export default class ClaimTrackingDetails extends Vue {
  @Prop() public claimTrackingDetailsModel: ClaimTrackingModel
  @Prop() public totalClaimsCount: number
  @Prop() private selectedClaimIndex: number
  @Prop() public previousLatitude: number
  @Prop() public previousLongitude: number
  @Prop() public headingMagneticNorth: number
  @Prop() public showProgressBar: boolean
  public ready = false
  public currentClaimDetails: any = {}
  public trackingCompletionRatio = 100
  private numberOfSteps = 6
  private trackingCompletionRatioGap: number = 100 / this.numberOfSteps
  public claimTrackingDetailModel: ClaimTrackingModel = new ClaimTrackingModel()
  private showMap = false
  public customerAddressLatitude = 0
  public customerAddressLongitude = 0

  private isJobAbandoned = false
  private timerProgressBarHandle: number | null = null
  public isProgressBarLoaderVisible = false
  private intervalToShowLoader: number = 1000 * 30
  private intervalToHideLoader: number = 1000 * 3

  public get currentClaimIndex(): number {
    return this.selectedClaimIndex + 1
  }

  public get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get claimTrackingModel(): ClaimTrackingModel {
    return this.claimTrackingDetailModel
  }

  private set claimTrackingModel(value: ClaimTrackingModel) {
    this.claimTrackingDetailModel = value
  }

  public updatedClaimTrackingModel(
    value: ClaimTrackingModel,
    isJobAbandoned: boolean
  ) {
    this.isJobAbandoned = false
    if (isJobAbandoned) {
      this.isJobAbandoned = isJobAbandoned
    }
    this.claimTrackingDetailModel = new ClaimTrackingModel()
    this.claimTrackingModel = value
  }

  @Watch('selectedClaimIndex')
  private getSelectedClaimIndex() {
    // start timer to show update notification
    this.startTimerToShowProgressbar()
    return this.selectedClaimIndex
  }

  @Watch('totalClaimsCount')
  private getTotalClaimsCount() {
    return this.totalClaimsCount
  }

  @Watch('claimTrackingDetailModel')
  private claimTrackingDetailsModelBindings(newValue: ClaimTrackingModel) {
    this.claimTrackingModel = newValue
    this.updateProgressBarStatus(this.claimTrackingModel, true)
  }

  private created() {
    this.claimTrackingDetailModel = this.claimTrackingDetailsModel
  }

  private mounted() {
    this.ready = true
    this.updateProgressBarStatus(this.claimTrackingDetailModel, false)
    // start timer to show update notification
    if (this.showProgressBar) {
      this.resetInactivityTimer()
    }
  }

  private updateProgressBarStatus(
    claimTrackingModel: ClaimTrackingModel,
    isFromSignalR: boolean
  ) {
    let response
    // bind default value of loader
    if (claimTrackingModel) {
      if (claimTrackingModel.claimVisitComplete) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.claimVisitComplete
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 6)
        // stop timer if emergency is completed
        this.stopProgressBarTimer()
      } else if (claimTrackingModel.engineerOnSite) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerOnSite
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 5)
      } else if (claimTrackingModel.engineerOnTheWay) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerOnTheWay
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 4)
      } else if (claimTrackingModel.engineerAssigned) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.engineerAssigned
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 3)
      } else if (claimTrackingModel.coverIncluded) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.coverIncluded
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 2)
      } else if (claimTrackingModel.claimRequest) {
        if (!isFromSignalR || this.isJobAbandoned) {
          response = this.claimTrackingDetailModel.claimRequest
        }
        this.setProgressRatio(this.trackingCompletionRatioGap * 1)
      } else {
        this.setProgressRatio(0)
      }
    }

    if (response && (!isFromSignalR || this.isJobAbandoned)) {
      this.showClaimDetails(response)
    }
  }

  public getETADate(date: Moment): string {
    if (date) {
      return Shared.getFormatedDate(
        moment(date),
        this.environment.dateTimeFormat
      )
    }
    return ''
  }

  public get jobId(): string {
    return this.claimTrackingDetailsModel.claimRequest.jobId
  }

  private setProgressRatio(remainingSteps: number) {
    this.trackingCompletionRatio = remainingSteps
  }

  private showClaimDetails(claimDetails: any) {
    if (claimDetails) {
      this.currentClaimDetails = claimDetails
      const date = this.getETADate(claimDetails.createdAt).split(' ')
      this.currentClaimDetails.createdAtDate = date[0]
      this.currentClaimDetails.createdAtTime = date[1]
    }
  }

  public get isMapCoordinatesExists(): boolean {
    if (
      this.claimTrackingDetailModel &&
      this.claimTrackingDetailModel.engineerOnTheWay &&
      this.claimTrackingDetailModel.engineerOnTheWay.latitude &&
      this.claimTrackingDetailModel.engineerOnTheWay.latitude !== 0 &&
      this.claimTrackingDetailModel.engineerOnTheWay.longitude &&
      this.claimTrackingDetailModel.engineerOnTheWay.longitude !== 0 &&
      !this.claimTrackingDetailModel.engineerOnSite &&
      !this.claimTrackingDetailModel.claimVisitComplete
    ) {
      this.openEngineerLocationMap()
      return true
    }
    return false
  }

  private async openEngineerLocationMap() {
    if (
      this.customerAddressLatitude !== 0 &&
      this.customerAddressLongitude !== 0
    ) {
      this.showMap = true
      return
    }

    const result = await this.$store.dispatch(
      'insurerPortalModule/getJobLocation'
    )
    if (result) {
      this.customerAddressLatitude = result.latitude
      this.customerAddressLongitude = result.longitude
      this.showMap = true
      return
    }
  }

  private startTimerToShowProgressbar() {
    if (this.showProgressBar) {
      this.resetInactivityTimer()
    } else {
      this.stopProgressBarTimer()
    }
  }

  private resetInactivityTimer(): void {
    this.stopProgressBarTimer()
    this.timerProgressBarHandle = window.setInterval(
      this.showProgressBarLoader,
      this.intervalToShowLoader
    )
  }

  private showProgressBarLoader() {
    this.isProgressBarLoaderVisible = true
    setTimeout(() => {
      this.isProgressBarLoaderVisible = false
    }, this.intervalToHideLoader)
  }

  private stopProgressBarTimer() {
    if (this.timerProgressBarHandle) {
      clearTimeout(this.timerProgressBarHandle)
      this.timerProgressBarHandle = null
    }
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
</script>
<style lang="scss">
.progress-circular {
  svg {
    transform: rotate(-90deg) !important;
  }
}
.svg-icon {
  svg {
    margin-top: 3px;
  }
}
</style>

<style scoped lang="scss">
.multiple-tracking {
  ::v-deep .custom-title {
    /* copy from old title helper class of vuetify */
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }
  ::v-deep .custom-body {
    font-size: 14px !important;
  }
  ::v-deep .custom-subtitle {
    font-size: 16px !important;
  }
  ::v-deep .custom-display {
    font-size: 45px !important;
    line-height: 48px !important;
    letter-spacing: normal !important;
  }
  .process-cycle {
    padding-bottom: 65px !important;
  }
}

.details-content {
  .refresh-progressbar {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 6;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    ::v-deep .v-card {
      margin: auto;
      padding: 0 !important;
      width: 300px;
      text-align: center;
      margin-top: 400px;
    }
  }
}
.claim-progress-bar {
  position: relative;
}
.claim-info-process-bar {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 200px;
  width: 200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  * {
    margin: auto;
    width: 100%;
    height: auto !important;
  }
}
.subheading {
  font-size: 16px !important;
}
.step-process {
  text-transform: uppercase;
  display: block;
  position: relative;
  .v-icon {
    font-size: 18px;
    display: inline-block;
    margin-top: 5px;
    vertical-align: middle;
  }
  .inner-content {
    display: inline-block;
    width: 400px;
    max-width: 100%;
    margin-bottom: 30px;
    cursor: pointer;
    position: relative;
  }
  .bar {
    left: -38px;
    position: absolute;
    width: 6px;
    background-color: #eee;
    bottom: 0px;
    margin: auto;
    top: 0px;
  }
  .bar-circle {
    position: absolute;
    left: -50px;
    top: 0px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  &:last-child {
    .inner-content {
      margin-bottom: 0px;
    }
    .bar {
      display: none;
    }
  }
  &.active {
    color: #fff;
    .v-icon {
      color: #fff;
    }
    .inner-content,
    .bar {
      background: #03587f !important;
    }
  }
  .show-date span {
    position: relative;
    top: 4px;
  }
}
.profile-details {
  width: 100%;
  vertical-align: middle;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 100% !important;
  }
  &__name {
    width: calc(100% - 65px);
    display: inline-block;
    margin-left: 10px;
    padding-top: 2px;
  }
  .v-avatar {
    vertical-align: top;
    margin-top: 5px;
  }
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}
.slider-count-text {
  margin-bottom: 0px;
  font-weight: 600;
}
.map-close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1;
  background-color: #fff;
  ::v-deep .v-btn {
    margin: 0px;
    height: auto;
    width: auto;
    &:hover .v-btn__content:before {
      content: '';
      background-color: transparent;
    }
  }
}
.map {
  padding-bottom: 188px;
  position: relative;
  height: 0;
  margin-bottom: 10px;
}
@media (max-width: 420px) {
  .process-cycle {
    padding-right: 0px !important;
  }
  .step-process {
    .bar {
      left: -25px;
    }
    .bar-circle {
      left: -37px;
    }
    .inner-content {
      max-width: 93%;
      &.py-4 {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
      }
    }
  }
  .subheading {
    font-size: 13px !important;
  }
  .profile-details {
    .details {
      font-size: 12px !important;
    }
  }
  .custom-display {
    font-size: 28px !important;
  }
}
</style>
