<template>
  <section role="region" aria-label="Customer address">
    <div class="client-details">
      <div role="form" aria-label="Customer address form">
        <v-container fluid class="px-2">
          <h2 class="title" :style="{ color: clientTemplate.primaryColor }">
            Your Property
          </h2>
          <v-row
            class="pa-2 flex-wrap ma-0"
            :style="{ background: clientTemplate.secondaryColor }"
          >
            <template v-if="isShowDetailAddress">
              <v-col cols="12" class="pa-0">
                <ValidationProvider
                  v-slot="{ errors, inValid }"
                  name="Address Line1"
                  rules="required"
                >
                  <v-text-field
                    ref="addressLine1"
                    v-model.trim="addressLine1"
                    label="Address Line1"
                    aria-required="true"
                    maxlength="100"
                    required
                    :error-messages="errors"
                    :error="inValid"
                    class="required"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model.trim="addressLine2"
                  label="Address Line2"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model.trim="addressLine3"
                  label="City"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-text-field
                  v-model.trim="addressLine4"
                  label="County"
                  maxlength="100"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" class="pa-0">
                <ValidationProvider
                  v-slot="{ errors, inValid }"
                  name="Postcode"
                  rules="required"
                >
                  <v-text-field
                    v-model.trim="postcode"
                    label="Postcode"
                    aria-required="true"
                    required
                    class="required"
                    maxlength="20"
                    :error-messages="errors"
                    :error="inValid"
                  >
                  </v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-btn
                  text
                  color="primary"
                  class="mr-0 pa-0 float-right"
                  aria-label="Add address manually"
                  @click="toggleActiveAddressSearch(true)"
                >
                  <span>Back</span>
                </v-btn>
              </v-col>
            </template>
            <template v-else>
              <v-col cols="12" class="pa-0">
                <ValidationProvider
                  v-slot="{ errors, inValid }"
                  rules="required"
                  name="Post Code"
                  mode="lazy"
                >
                  <v-autocomplete-custom
                    v-if="!appInEditMode"
                    id="customer-address-select"
                    ref="searchAddressField"
                    v-model="addressSearchModel"
                    tabindex="0"
                    label="Search Post Code"
                    aria-required="true"
                    autofocus
                    :loading="searchAddressRunning"
                    :items="searchResult"
                    autocomplete="off"
                    :search-input.sync="searchAddress"
                    item-text="Description"
                    return-object
                    no-filter
                    :menu-props="{ maxHeight: '250' }"
                    class="required customer-address-select"
                    aria-owns="customer-address-select"
                    aria-haspopup="listbox"
                    :error="inValid"
                    :error-messages="errors"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-list-item-content
                        id="selected-address"
                        class="v-select__selection"
                        aria-haspopup="listbox"
                        role="button"
                        :aria-label="
                          'Selected address ' + data.item.Description
                        "
                      >
                        {{ data.item.Description }}
                      </v-list-item-content>
                    </template>
                  </v-autocomplete-custom>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-btn
                  text
                  color="primary"
                  class="mr-0 pa-0 float-right"
                  aria-label="Add address manually"
                  @click="toggleActiveAddressSearch(false)"
                >
                  <span> Add Address Manually </span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch, Inject } from 'vue-property-decorator'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import VAutocompleteCustom from './custom-vuetify/VAutocompleteCustom'

@Component({
  components: {
    ValidationProvider,
    VAutocompleteCustom,
  },
})
export default class CustomerAddress extends Vue {
  @Inject('formWrapperGetter') formWrapperGetter: () => InstanceType<
    typeof ValidationObserver
  >
  $refs!: {
    [k: string]: any
  }

  private isShowDetailAddress = false
  private searchAddressRunning = false
  private searchResult: any = []
  private searchAddress = ''
  private waitForMoreInputTimeoutHandle: number | null = null
  private addressSearchModel: any = ''

  private get formWrapper() {
    return this.formWrapperGetter()
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get addressLine1() {
    return this.insurerPortal.addressLine1
  }
  private set addressLine1(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine1', value)
  }

  private get addressLine2() {
    return this.insurerPortal.addressLine2
  }
  private set addressLine2(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine2', value)
  }

  private get addressLine3() {
    return this.insurerPortal.addressLine3
  }
  private set addressLine3(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine3', value)
  }

  private get addressLine4() {
    return this.insurerPortal.addressLine4
  }
  private set addressLine4(value: string) {
    this.$store.dispatch('insurerPortalModule/setAddressLine4', value)
  }

  private get postcode() {
    return this.insurerPortal.postcode
  }
  private set postcode(value: string) {
    this.$store.dispatch('insurerPortalModule/setPostcode', value)
  }

  @Watch('getTrackingId', { immediate: true })
  private async getLoqateApiKeyForAddress() {
    if (this.getTrackingId && !this.getLoqateApiKey && !this.appInEditMode) {
      await this.$store.dispatch('insurerPortalModule/getLoqateApiKey')
    }
  }

  private mounted() {
    if (this.appInEditMode) {
      this.searchAddress = 'Test'
      this.addressLine1 = '392, St. Georges Crescent'
      this.addressLine2 = 'Old Marske'
      this.addressLine3 = 'Redcar'
      this.addressLine4 = 'Cleveland'
      this.postcode = 'TS11 8BU'
      this.isShowDetailAddress = true
      this.searchResult = []
    }
  }

  private get getLoqateApiKey(): string {
    return this.$store.getters['generalModule/loqateApiKey']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  @Watch('addressSearchModel')
  private async addressSearchModelChange(item: any) {
    if (item && item.Type !== 'Address') {
      this.addressVerification(item.Text, item.Id, true)
      return
    }
    if (item && this.getLoqateApiKey) {
      const hasData = await this.$store.dispatch(
        'insurerPortalModule/retrieveAddressLoqateApi',
        {
          id: item.Id,
          apiKey: this.getLoqateApiKey,
        }
      )

      if (hasData) {
        this.isShowDetailAddress = true
        this.$refs.searchAddressField.blur()
      }
    }
  }

  @Watch('searchAddress')
  private addressVerification(text: string, id: string, isReSearch: boolean) {
    const item = this.searchResult.find(
      (i: any) => i.Description === this.searchAddress
    )
    if (!isReSearch && (!this.searchAddress || item)) {
      return
    }
    if (this.waitForMoreInputTimeoutHandle) {
      window.clearTimeout(this.waitForMoreInputTimeoutHandle)
    }
    this.waitForMoreInputTimeoutHandle = window.setTimeout(async () => {
      if (this.getLoqateApiKey) {
        this.searchAddressRunning = true
        const reSearchId = isReSearch ? id : ''

        const { hasData, searchResult } = await this.$store.dispatch(
          'insurerPortalModule/findAddressFromLoqateApi',
          {
            text: text,
            apiKey: this.getLoqateApiKey,
            id: reSearchId,
          }
        )
        this.searchAddressRunning = false

        if (!hasData) return (this.searchResult = [])

        this.searchResult = searchResult
        this.isShowDetailAddress = false

        if (isReSearch) {
          this.$refs.searchAddressField.focus()
          this.$refs.searchAddressField.isMenuActive = true
        }
      }
    }, 300)
  }

  private toggleActiveAddressSearch(isActive: boolean) {
    this.isShowDetailAddress = !isActive
    this.addressSearchModel = ''
    this.clearAddressFields()
    this.formWrapper.reset()
    setTimeout(() => {
      let inputElement: any
      if (isActive) {
        inputElement = this.$refs.searchAddressField
      } else {
        inputElement = this.$refs.addressLine1
      }
      if (inputElement) {
        inputElement.focus()
      }
    }, 0)
  }

  private clearAddressFields() {
    this.addressLine1 = ''
    this.addressLine2 = ''
    this.addressLine3 = ''
    this.addressLine4 = ''
    this.postcode = ''

    this.searchResult = []
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }
}
</script>

<style lang="scss" scoped>
.client-details {
  .v-select__selection {
    flex-basis: auto;
    display: block !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .title {
    margin-bottom: 15px;
    font-family: inherit !important;
    font-size: 20px !important;
  }

  .v-btn {
    letter-spacing: inherit;
    &.v-size--default {
      font-size: 13px;
    }
  }

  .heading-text {
    font-family: inherit;
    white-space: pre-line;
    font-size: 1.4rem;
    line-height: initial;
    color: initial;
  }

  .bold-select {
    font-weight: 600;
  }

  @media (max-width: 768px) {
    .client-details {
      .container {
        .layout {
          margin: auto 0px !important;
          margin-bottom: 10px !important;
        }
      }

      .title {
        margin: auto 0px !important;
        margin-top: 20px !important;
        margin-bottom: 10px !important;
      }
    }
  }
}
.client-details .title {
  margin-bottom: 15px;
}
.bold-select {
  font-weight: 600;
}
.header .toolbar__title {
  display: flex;
}
.custom-value >>> .input-group input {
  opacity: 0;
}
.hide {
  display: none;
}
.btn--floating .v-icon {
  height: auto !important;
  width: auto !important;
}
@media (max-width: 768px) {
  .client-details .container > .layout,
  .client-details .title {
    margin: auto 0px !important;
  }
  .client-details .container > .layout {
    margin-bottom: 10px !important;
  }
  .client-details .title {
    margin-top: 20px !important;
    margin-bottom: 10px !important;
  }
}
@media (max-width: 420px) {
  .client-details .layout.px-3 {
    padding: 8px !important;
  }
}
</style>
