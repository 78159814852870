import { render, staticRenderFns } from "./RecaptchaVerification.vue?vue&type=template&id=3058cefa&scoped=true"
import script from "./RecaptchaVerification.vue?vue&type=script&lang=ts"
export * from "./RecaptchaVerification.vue?vue&type=script&lang=ts"
import style0 from "./RecaptchaVerification.vue?vue&type=style&index=0&id=3058cefa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@6.26.3_css-loader@6.11.0_webpack@5.98._lpz7yfflcfbikbc3glhfyeagle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3058cefa",
  null
  
)

export default component.exports