<template>
  <div role="region" aria-label="cookie notice header">
    <v-container>
      <v-container :class="{ 'ot-text-style': !useDefaultCookieBanner }">
        <v-layout v-if="clientTemplate.cookieNoticeText">
          <v-flex class="cookie-notice">
            <h2 class="mb-2">Cookie Notice</h2>
            <v-flex class="mb-3">
              <!-- eslint-disable vue/no-v-html -->
              <span
                class="caption"
                v-html="sanitizeHTML(clientTemplate.cookieNoticeText)"
              />
              <!-- eslint-enable vue/no-v-html -->
            </v-flex>
          </v-flex>
        </v-layout>
      </v-container>
      <InternalCookiePolicy v-if="useDefaultCookieBanner" />
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import InternalCookiePolicy from '@/components/cookie/InternalCookiePolicy.vue'
import ClientTemplate from '@/models/client/client-template'
import Shared from '@/common/shared'

const oneTrustContent = [
  {
    placeholder: '{{COOKIETEXT}}',
    HTML: '<div id="ot-sdk-cookie-policy"></div>',
  },
  {
    placeholder: '{{COOKIEBUTTON}}',
    HTML: '<div id="ot-sdk-btn" class="ot-preference-btn ot-sdk-show-settings" tabindex="0"></div>',
  },
]

@Component({
  components: {
    InternalCookiePolicy,
  },
})
export default class CookieNotice extends Vue {
  private sanitizeHTML = Shared.sanitizeHTML

  private get useDefaultCookieBanner(): boolean {
    return this.$store.getters['cookieModule/useDefaultCookieBanner']
  }

  public get clientTemplate(): ClientTemplate {
    const clientTemplate = this.$store.getters['clientModule/clientTemplate']

    if (!this.useDefaultCookieBanner) {
      oneTrustContent.forEach((content) => {
        clientTemplate.cookieNoticeText =
          clientTemplate.cookieNoticeText.replace(
            content.placeholder,
            content.HTML
          )
      })
    }

    return clientTemplate
  }
}
</script>

<style lang="scss">
.ot-preference-btn {
  margin-bottom: 2em;
  max-width: 140px;
  text-align: center;
}
.ot-text-style {
  padding: 0;
  color: #696969;

  h2 {
    font-size: 1.2em;
  }
}
</style>
