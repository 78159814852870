<template>
  <v-dialog
    content-class="coverd-modal-dailog"
    :value="showPrivacyPolicyDetails"
    max-width="900"
    persistent
    scrollable
    @input="hidePrivacyPolicyDetails"
  >
    <v-card>
      <v-toolbar
        card
        dark
        :style="{ backgroundColor: clientTemplate.primaryColor }"
      >
        <v-toolbar-title>Privacy Policy</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          label="Close privacy policy detail dialog"
          aria-label="Close privacy policy detail dialog"
          @click.native="hidePrivacyPolicyDetails"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <!-- eslint-disable vue/no-v-html -->
        <span
          class="privacy-policy"
          v-html="sanitizeHTML(clientTemplate.privacyPolicyText)"
        ></span>
        <!-- eslint enable -->
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :style="{ color: clientTemplate.primaryColor }"
          text
          label="Close privacy policy detail dialog"
          aria-label="Close privacy policy detail dialog"
          @click.native="hidePrivacyPolicyDetails"
          >Close</v-btn
        >
        <v-btn
          :style="{ backgroundColor: clientTemplate.primaryColor }"
          class="mr-0 white--text"
          label="Mark Terms and services as accepted"
          aria-label="Mark Terms and services as accepted"
          @click="acceptTermsAndServices"
          >I Agree</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import ClientTemplate from '@/models/client/client-template'
import Shared from '@/common/shared'

@Component({
  name: 'PrivacyPolicyModal',
})
export default class PrivacyPolicyModal extends Vue {
  private sanitizeHTML = Shared.sanitizeHTML

  private mounted() {
    const privacyPolicyElement = document.querySelector(
      '.privacy-policy'
    ) as any
    if (privacyPolicyElement) {
      const linkElements = privacyPolicyElement.querySelectorAll(
        'a'
      ) as HTMLElement[]
      if (linkElements && linkElements.length > 0) {
        linkElements.forEach((link) => {
          link.style.color = this.clientTemplate.complementaryColor
        })
      }
    }
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get showPrivacyPolicyDetails(): boolean {
    return this.$store.getters['cookieModule/showPrivacyPolicyDetails']
  }

  private hidePrivacyPolicyDetails(): void {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', false)
  }

  private acceptTermsAndServices(): void {
    this.$store.dispatch('cookieModule/acceptTermsAndServices', true)
    this.hidePrivacyPolicyDetails()
  }
}
</script>
