<template>
  <div
    id="topScroll"
    class="portal-template"
    :style="{ backgroundColor: clientTemplate.primaryColor }"
  >
    <div role="region" aria-label="header">
      <v-container class="container--fluid pa-0">
        <v-app-bar fixed class="white">
          <v-toolbar-title>
            <img
              :src="
                clientTemplate.logoURL
                  ? clientTemplate.logoURL
                  : environment.policyHolderAppBaseLogoUrl
              "
              class="img-responsive logo-img"
              :alt="
                (clientTemplate.displayName
                  ? clientTemplate.displayName
                  : 'Policy') + ' - Logo'
              "
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-container>
    </div>
    <div class="focus-able-content"></div>
    <div class="content-section px-6" role="region" aria-label="main content">
      <div class="content-container">
        <div class="details-content elevation-1">
          <ValidationObserver ref="formWrapper">
            <div class="template-tab">
              <div v-show="ready" class="mid-content">
                <div>
                  <div>
                    <v-tabs
                      :value="currentTabHref"
                      background-color="white"
                      slider-color="primary"
                    >
                      <v-tab
                        v-for="(title, index) in stepTitles"
                        :key="`${title}-${index}`"
                        :href="`step${index + 1}`"
                      >
                        {{ title }}
                      </v-tab>
                      <!--Step 1-->
                      <!-- Select tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 1"
                        v-show="currentStep === 1"
                        value="step1"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text
                              class="content-section__emergency-selection"
                            >
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep1"
                                :description.sync="
                                  clientTemplate.descriptionStep1
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <EmergencySelection />
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 2-->
                      <!-- Detail tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 2"
                        v-show="currentStep === 2"
                        value="step2"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep2"
                                :description.sync="
                                  clientTemplate.descriptionStep2
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <ClientDetail />
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 3-->
                      <!-- Verification tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 3"
                        v-show="currentStep === 3"
                        value="step3"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep3"
                                :description.sync="
                                  clientTemplate.descriptionStep3
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <CustomerVerification @userVerified="nextStep" />
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 4-->
                      <!-- Address tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 4"
                        v-show="currentStep === 4"
                        value="step4"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :app-in-edit-mode="appInEditMode"
                                :heading.sync="clientTemplate.headingStep4"
                                :description.sync="
                                  clientTemplate.descriptionStep4
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <CustomerAddress />
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 5-->
                      <!-- Explain tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 5"
                        v-show="currentStep === 5"
                        value="step5"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep5"
                                :description.sync="
                                  clientTemplate.descriptionStep5
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <section
                                role="region"
                                aria-label="Emergency question expansion panel"
                              >
                                <EmergencyQuestionGenerator />
                              </section>
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 6-->
                      <!-- Customer Availability tab -->
                      <v-tab-item
                        v-if="highestVisitedStep >= 6"
                        v-show="currentStep === 6"
                        value="step6"
                      >
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep6"
                                :description.sync="
                                  clientTemplate.descriptionStep6
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <div>
                                <CustomerAvailabilityPreview />
                              </div>
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>

                      <!--Step 7-->
                      <!-- Upload tab -->
                      <v-tab-item v-show="currentStep === 7" value="step7">
                        <ValidationObserver>
                          <v-card flat>
                            <v-card-text>
                              <EditableHeader
                                :heading.sync="clientTemplate.headingStep7"
                                :description.sync="
                                  clientTemplate.descriptionStep7
                                "
                                :primary-color="clientTemplate.primaryColor"
                                :complementary-color="
                                  clientTemplate.complementaryColor
                                "
                              />
                              <ImageUpload :image-type="emergencyImageType" />
                            </v-card-text>
                          </v-card>
                        </ValidationObserver>
                      </v-tab-item>
                    </v-tabs>
                  </div>
                </div>
              </div>
              <div
                class="text-right pr-4 pa-2 white insureractions"
                role="region"
                aria-label="Stepper action button"
              >
                <v-btn
                  v-if="currentStep !== 1 && currentStep !== 7"
                  :style="{ color: clientTemplate.primaryColor }"
                  text
                  dark
                  label="Previous step"
                  aria-label="Previous step"
                  @click="previousStep()"
                >
                  <v-icon small>arrow_back_ios</v-icon>&nbsp; Previous
                </v-btn>
                <v-btn
                  v-if="currentStep === 6"
                  class="white--text"
                  :style="{ background: clientTemplate.primaryColor }"
                  :disabled="isLoading"
                  :loading="isLoading"
                  label="Submit"
                  aria-label="Submit"
                  @click="saveClaim"
                >
                  Submit
                </v-btn>
                <v-btn
                  v-if="currentStep === 7"
                  class="white--text"
                  :style="{ background: clientTemplate.primaryColor }"
                  :disabled="isLoading"
                  :loading="isLoading"
                  label="Done"
                  aria-label="Done"
                  @click="isThanksDialogOpen = true"
                >
                  Done
                </v-btn>
                <v-btn
                  v-if="currentStep !== 6 && currentStep !== 7"
                  class="white--text"
                  :style="{ background: clientTemplate.primaryColor }"
                  :disabled="!ready || isLoading"
                  :loading="isLoading"
                  label="Next step"
                  aria-label="Next step"
                  @click="nextStep()"
                >
                  Next &nbsp;
                  <v-icon small>arrow_forward_ios</v-icon>
                </v-btn>
              </div>
              <div v-show="!ready">
                <v-progress-circular
                  class="loading-spinner"
                  :width="2"
                  :size="50"
                  indeterminate
                  color="primary"
                />
              </div>
            </div>
          </ValidationObserver>
        </div>
      </div>
      <ThanksDialog :is-open="isThanksDialogOpen"></ThanksDialog>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import Shared from '@/common/shared'
import { ImageType } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import EmergencyQA from '@/models/EmergencyQA'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import EmergencySelection from '@/components/EmergencySelection.vue'
import ClientDetail from '@/components/ClientDetail.vue'
import CustomerAvailabilityPreview from '@/components/CustomerAvailabilityPreview.vue'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EditableHeader from '@/components/EditableHeader.vue'
import CustomerAddress from '@/components/CustomerAddress.vue'
import CustomerVerification from '@/components/CustomerVerification.vue'

import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ThanksDialog from '@/components/ThanksDialog.vue'

@Component({
  name: 'InsurerPortal',
  components: {
    EmergencyQuestionGenerator,
    EmergencySelection,
    ClientDetail,
    DateTimePicker,
    CustomerAvailabilityPreview,
    EditableHeader,
    CustomerAddress,
    CustomerVerification,
    MessageCard,
    ImageUpload,
    ValidationObserver,
    ValidationProvider,
    ThanksDialog,
  },
  provide() {
    return {
      formWrapperGetter: () => this.$refs.formWrapper,
    }
  },
})
export default class InsurerPortal extends Vue {
  private ready = false

  private localTimer: Date = new Date()
  private localTimerForClaim: Date = new Date()

  private defaultQuestionSelectionOption = 'Select an Option'
  private emergencyImageType: ImageType = ImageType.ExampleofEmergency
  private isThanksDialogOpen = false

  $refs!: {
    formWrapper: InstanceType<typeof ValidationObserver>
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get totalSteps(): number {
    return this.$store.getters['generalModule/navigation'].totalSteps
  }

  private get highestVisitedStep(): number {
    return this.$store.getters['generalModule/navigation'].highestVisitedStep
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  public get emergenciesQAs() {
    return this.insurerPortal.emergenciesQAs
  }

  private set emergenciesQAs(payload: EmergencyQA[]) {
    this.$store.dispatch('insurerPortalModule/setEmergenciesQAs', payload)
  }

  private get healthAndSafetyQAs() {
    return this.insurerPortal.healthAndSafetyQAs
  }

  private get accessNoteQA() {
    return this.insurerPortal.accessNoteQA
  }

  private get vulnerabilityQA() {
    return this.insurerPortal.vulnerabilityQA
  }

  private get emergencies() {
    return this.insurerPortal.emergencies
  }

  private set emergencies(payload: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', payload)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.insurerPortal.customerAvailability
  }

  private set customerAvailability(payload: CustomerAvailabilityModel) {
    this.$store.dispatch('insurerPortalModule/setCustomerAvailability', payload)
  }

  public stepTitles: string[] = [
    'Select',
    'Detail',
    'Verification',
    'Address',
    'Explain',
    'Customer Availability',
    'Upload',
  ]

  public currentTab = 'step1'

  public gaNextStepEvents = [
    { step: 1, event: 'Emergencies Details Completed' },
    { step: 2, event: 'Contact Preference Completed' },
    { step: 3, event: 'User Verification Completed' },
    { step: 4, event: 'Address Search Completed' },
    { step: 5, event: 'Emergency Question Completed' },
    { step: 6, event: 'Customer Availability' },
    { step: 7, event: 'Image Upload Completed' },
  ]

  public gaPreviousStepEvents = [
    { step: 2, event: 'Back to Emergency Details' },
    { step: 3, event: 'Back To Contact Preference' },
    { step: 4, event: 'Back To User Verification' },
    { step: 5, event: 'Back To Address Search' },
    { step: 6, event: 'Back To Emergency Question List' },
  ]

  public get currentTabHref(): string {
    return `step${this.currentStep}`
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  // We take a param, incase we need to move forward
  // more than one step in the form, in the future
  public async nextStep(stepValue = 1) {
    if (!(await this.validateCurrentStep())) return
    const gaEvent = this.gaNextStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'NEXT')
    }

    this.$store.dispatch('generalModule/nextStep', stepValue)
    this.scrollToTop()
  }

  public previousStep(stepValue = 1) {
    const gaEvent = this.gaPreviousStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'PREVIOUS')
    }
    this.$store.dispatch('generalModule/previousStep', stepValue)
    this.scrollToTop()
  }

  public async validateCurrentStep(): Promise<boolean> {
    const isValid = await this.activeStepValidator.validate()
    if (!isValid) {
      for (const key of Object.keys(this.activeStepValidator.fields).sort()) {
        if (this.activeStepValidator.fields[key].invalid) {
          this.activeStepValidator.refs[key]?.$el.scrollIntoView()
          return false
        }
      }
    }
    return true
  }

  public get activeStepValidator() {
    return this.$refs.formWrapper.observers[this.currentStep - 1]
  }

  public async submitForm() {
    await this.$refs.formWrapper.validate()
  }

  private async created() {
    await this.$store.dispatch('insurerPortalModule/getEmergencies')
    this.ready = true

    this.handleLinkClicksInGA('Client Portal', 'LOADED')
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private async saveClaim() {
    if (!(await this.validateCurrentStep())) return
    if (this.appInEditMode) {
      this.nextStep()
      return
    }
    this.isLoading = true
    const emergenciesWithoutDetail = this.insurerPortal.emergencies.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ({ emergencyDetail, ...x }) => x
    )
    this.emergencies = emergenciesWithoutDetail

    // find answer with default option value and set null
    this.emergenciesQAs = this.emergenciesQAs.map((x) =>
      x.answer === this.defaultQuestionSelectionOption
        ? { ...x, answer: '' }
        : x
    )

    try {
      const result = await this.$store.dispatch('insurerPortalModule/addJob', {
        subDomain: window.location.host,
        portalId: this.$store.getters['clientModule/portalId'],
        model: this.insurerPortal,
      })
      if (result) {
        this.nextStep()
      }
      this.isLoading = false
    } catch (error) {
      // TODO: handle error
      this.isLoading = false
    }
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }

  private handleLinkClicksInGA(
    categoryName: string,
    actionName: string,
    time: Date | null = null
  ) {
    if (this.appInEditMode) {
      // return if app is in edit mode
      return
    }
    // send event updates to google analytics
    Shared.sendEventsInGoogleAnalytics(
      categoryName,
      actionName,
      time ? time : this.localTimer
    )
    // reset timer for second button click
    this.localTimer = new Date()
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get clientName(): string {
    return this.$route.params.clientName
  }
}
</script>

<style>
.emergency-selection.v-card__text {
  font-size: unset;
  line-height: unset;
  letter-spacing: unset;
}

.theme--light.v-card > .v-card__text {
  color: unset !important;
}
</style>

<style lang="scss" scoped>
.content-container {
  height: 100vh;
  margin-top: -230px;
  padding-top: 230px;
  box-sizing: border-box;
}
.details-content {
  height: 100%;
  z-index: 21;
  position: relative;
}
.toolbar {
  z-index: 9;
}
.portal-template {
  height: 100%;
  min-width: 100%;
  ::v-deep .v-toolbar__title {
    max-width: 230px;
  }
}
.template-tab {
  .mid-content {
    overflow-y: auto;
    height: 100%;
  }
  ::v-deep .v-tabs__wrapper {
    display: none;
  }
  ::v-deep .v-tabs-bar {
    display: none;
  }
  ::v-deep .v-tabs__items {
    width: 100%;
  }
}

.logo-img {
  height: 42px;
  object-fit: contain;
}

.custom-display-1 {
  font-size: 24px !important;
  line-height: 28px !important;
}
.step-heading {
  border-left: 3px solid #000;
  padding-left: 30px;
}
.mid-content {
  ::v-deep .emergency-question-content {
    .v-expansion-panel__header {
      width: 100%;
    }
  }
}

pre {
  font-family: inherit;
  white-space: pre-line;
}

.content-section {
  padding-top: 90px !important;
  padding-bottom: 24px !important;
  height: 100%;
  .details-content {
    max-width: 500px;
    margin: auto;
    background-color: #fff;
  }
  &__emergency-selection {
    font-size: unset;
    line-height: unset;
    letter-spacing: unset;
  }
}

.mid-content {
  .v-tabs {
    height: 100%;
  }
}

.template-tab {
  height: 100%;
}

.insureractions {
  border-top: 1px solid #e2e2e2 !important;
  ::v-deep {
    .v-btn {
      .v-btn__content {
        padding: 0px 6px !important;
        font-size: 12px;
      }
    }
  }
}

.validate-captcha {
  width: 304px;
}

.red-border-checkbox {
  ::v-deep .v-icon,
  ::v-deep .v-label {
    color: rgb(255, 86, 86);
  }
}

.error-class {
  text-decoration: none;
  font-weight: bold;
  cursor: none;
  pointer-events: none;
  color: #ff5252 !important;
}
.terms-checkbox {
  ::v-deep .v-label {
    font-size: 14px;
    font-family: inherit;
  }
}

@media (max-width: 768px) {
  .v-container {
    padding: 0px !important;
  }
}
@media (max-width: 548px) {
  .insureractions {
    left: 24px;
    right: 24px;
  }
}

@media (max-width: 420px) {
  .content-section {
    padding: 10px !important;
    padding-top: 70px !important;
  }
  .step-heading {
    padding-left: 25px;
  }
  header {
    .v-toolbar__title {
      max-width: 180px !important;
    }
  }
  .v-expansion-panel__body {
    .v-card__text.pa-3 {
      padding: 8px !important;
    }
  }
  .emergency-question-content {
    .v-expansion-panel__header {
      div {
        padding-left: 30px;
      }
    }
  }
  .remove-emegency {
    .v-icon {
      font-size: 17px !important;
    }
  }

  .insureractions {
    bottom: 10px !important;
    left: 10px !important;
    right: 10px !important;
  }
}

.insuer-portal {
  height: 100%;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
}

@-moz-document url-prefix() {
  .emergency-selection {
    box-sizing: border-box;
  }
}
@media (min-width: 420px) {
  .content-container {
    margin-top: -250px;
    padding-top: 250px;
  }
}
</style>
