<template>
  <section role="region" aria-label="Google captcha verification">
    <div>
      <v-row>
        <v-col class="text-xs-center">
          <ValidationProvider
            ref="recaptchaValidator"
            v-slot="{ errors }"
            rules="recaptchaResponse"
          >
            <input type="hidden" :value="isRecaptchaValid" />
            <div
              v-if="errors.length > 0"
              class="recaptcha-error"
              :aria-invalid="errors.length > 0"
              :aria-errormessage="errors[0]"
            >
              <h2>{{ errors[0] }}</h2>
              <v-btn @click="verifyCustomer">Retry Recaptcha</v-btn>
            </div>
          </ValidationProvider>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script lang="ts">
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component
export default class RecaptchaVerification extends Vue {
  $refs!: {
    recaptchaValidator: InstanceType<typeof ValidationProvider>
  }

  public async created() {
    this.verifyCustomer()
  }

  public set recaptchaToken(token: string | null) {
    this.$store.dispatch('generalModule/submitRecaptchaToken', token)
  }

  public get recaptchaToken(): string | null {
    return this.$store.getters['generalModule/recaptchaToken']
  }

  public get recaptchaResponse(): RecaptchaResponse | null {
    return this.$store.getters['generalModule/recaptchaResponse']
  }

  public get isRecaptchaValid(): boolean {
    return this.recaptchaResponse !== null && this.recaptchaResponse.success
  }

  public async retrieveRecaptchToken() {
    try {
      await this.$recaptchaLoaded()
      this.recaptchaToken = await this.$recaptcha('login')
    } catch (error) {
      this.recaptchaToken = null
    }
  }

  public async verifyCustomer() {
    if (this.$refs.recaptchaValidator) {
      this.$refs.recaptchaValidator.setErrors([])
    }

    await this.retrieveRecaptchToken()
    if (this.recaptchaToken) {
      this.$emit('verifyCustomer')
    }
  }

  // Vee validate wont trigger unless the field is clicked :(
  @Watch('recaptchaResponse', { deep: true })
  public async triggerRecaptchaValidation() {
    await this.$refs.recaptchaValidator.validate()
  }
}
</script>

<style lang="scss" scoped>
.recaptcha-error {
  padding: 20px;
  margin: 5px;
  border: 2px solid red;
  color: red;
}
</style>
