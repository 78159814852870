<template>
  <section role="region" aria-label="Upload Image - File Selection">
    <div>
      <label for="imageUpload">
        <span
          class="image-upload__btn"
          :class="{ 'image-upload__btn--disabled': disabledUpload }"
        >
          {{ fileSelectionLabel }}
          <span class="image-upload__icon">
            <v-icon>attachment</v-icon>
          </span>
        </span>
      </label>
      <h2 class="font-weight-bold text-h6 float-right image-upload__counter">
        {{ imageCounter }}
      </h2>
      <ValidationProvider
        ref="provider"
        v-slot="{ errors }"
        :rules="{
          image: true,
          validFilesLength: {
            length: allowMultipleUploads ? 10 : 1,
            addedFilesLength: imagesToUpload.length,
          },
          imageUploadMimes: ['image/jpeg', 'image/jpg', 'image/png'],
          imageUploadSize: 1024,
        }"
        name="Images"
      >
        <input
          id="imageUpload"
          class="image-upload__input"
          type="file"
          accept="image/*"
          name="image"
          :multiple="allowMultipleUploads"
          :disabled="disabledUpload"
          :label="fileSelectionLabel"
          aria-label="Select Files"
          @change="addPreviewImages($event)"
        />
        <v-btn
          v-if="imagesToUpload.length > 0"
          class="mb-3"
          aria-label="Upload Files"
          @click="uploadFiles()"
          >{{ uploadButtonText }}</v-btn
        >
        <div v-for="(msg, index) in errors" :key="`${msg}-${index}`">
          <h3 aria-label="Error message for image upload" class="red--text">
            {{ msg }}
          </h3>
        </div>
      </ValidationProvider>
      <ImageDisplay
        :images="imagesToUpload"
        :image-header="`Preview images to upload`"
        :region-label="`Preview images to upload`"
        :remove-image-label="`Remove preview Image`"
        @removeImage="removePreviewImage($event)"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { ImageType } from '@/common/enums'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import ImageBase from '@/models/image/image-base'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ImageDisplay from '@/components/image/ImageDisplay.vue'

@Component({
  components: {
    ImageDisplay,
  },
})
export default class ImageFileSelection extends Vue {
  @Prop({ default: true })
  private allowMultipleUploads: boolean
  @Prop({ default: ImageType.NotSet })
  private imageType: ImageType

  private imageExistErrorMessages: string[] = []

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private get imagesToUpload(): ImageBase[] {
    const imagesToUpload: ImageBase[] =
      this.$store.getters['imageModule/imagesToUpload']
    return imagesToUpload.filter((x) => x.imageType === this.imageType)
  }

  private get uploadedImages(): ImageBase[] {
    const uploadedImages: ImageBase[] =
      this.$store.getters['imageModule/uploadedImages']
    return uploadedImages.filter((x) => x.imageType === this.imageType)
  }

  private get disabledUpload(): boolean {
    return (
      (!this.allowMultipleUploads && this.imagesToUpload.length > 0) ||
      (this.allowMultipleUploads && this.imagesToUpload.length === 10)
    )
  }

  private async addPreviewImages(event: any) {
    const { valid } = await (this.$refs.provider as any).validate(event)
    this.imageExistErrorMessages = []
    // // Image is invalid, quit upload process and show error message to user.
    if (!valid) {
      return
    }

    const files: FileList = event.target.files

    for (const file of files) {
      if (
        this.imagesToUpload.findIndex(
          (image: ImageBase) => image.fileName === file.name
        ) > -1 ||
        this.uploadedImages.findIndex(
          (image: ImageBase) => image.fileName === file.name
        ) > -1
      ) {
        this.imageExistErrorMessages.push(
          'The image with name ' + file.name + ' already exists.'
        )
      } else {
        const imageToUpload: ImageBase = new ImageBase()
        imageToUpload.previewUrl = URL.createObjectURL(file)
        imageToUpload.imageType = this.imageType
        imageToUpload.imageFile = file
        imageToUpload.fileName = file.name

        this.$store.dispatch('imageModule/submitImageToUpload', imageToUpload)
      }
    }
    event.target.value = null
  }

  private get uploadButtonText(): string {
    return this.allowMultipleUploads
      ? 'Upload Selected Files'
      : 'Upload Selected File'
  }

  private get imageCounter(): string {
    const maxAmount = this.allowMultipleUploads ? 10 : 1
    return `${this.imagesToUpload.length}/${maxAmount}`
  }

  private get fileSelectionLabel(): string {
    return this.allowMultipleUploads ? 'Select Files' : 'Select File'
  }

  private async removePreviewImage(img: ImageBase) {
    this.$store.dispatch('imageModule/submitPreviewImageToRemove', img)
  }

  private async uploadFiles() {
    this.$emit('closeModal')
    await this.$store.dispatch('imageModule/submitUploadImages', this.imageType)
  }
}
</script>
