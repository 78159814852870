<template>
  <div :class="isCurrentTime ? 'time-access-content' : ''">
    <div class="dateTimeControl">
      <v-row v-if="showControlOption" class="flex-wrap ma-0">
        <v-col cols="12" class="pa-0">
          <span class="font-weight-bold" tabindex="0">{{
            initialDateTimeControlOptionMsg
          }}</span>
        </v-col>
        <v-col cols="12" class="text-right pa-0 btn-group">
          <v-btn
            text
            color="primary"
            small
            class="mr-0 pa-0"
            aria-label="Just now"
            @click="OnClickPresentTime"
            @keyup.enter.prevent="OnClickPresentTime"
          >
            Just now
          </v-btn>
          <v-btn
            text
            color="primary"
            small
            class="mr-0 pa-0"
            aria-label="Enter date manually"
            @click="setFocusToDateTimeInput"
            @keyup.enter.prevent="setFocusToDateTimeInput"
          >
            Enter date manually
          </v-btn>
        </v-col>
      </v-row>
      <div v-else>
        <v-row class="flex-wrap">
          <v-col cols="11">
            <ValidationProvider
              v-slot="{ errors }"
              name="dateTimePicker"
              :rules="{
                required: true,
                validDateFormat: {
                  format: environment.dateTimeFormat,
                },
                emergencyValidDateTime: {
                  format: environment.dateTimeFormat,
                },
              }"
              :custom-messages="{
                required: 'The First Noticed date is required',
                validDateFormat: 'The First Noticed date is invalid',
                emergencyValidDateTime: 'The First Noticed date is invalid',
              }"
            >
              <v-text-field
                ref="dateTimeInput"
                v-model="newDate"
                :label="placeHolderText"
                return-masked-value
                :mask="getDateTimeFormatMaskValue"
                aria-required="true"
                required
                :value="selectedValueForDisplay"
                :error-messages="errors"
                class="required"
                :placeholder="globalDateTimeFormat"
                :aria-label="`${placeHolderText} ${
                  newDate
                    ? 'Selected date ' + newDate
                    : 'Please enter date and time in ' +
                      globalDateTimeFormat +
                      'format'
                }`"
                @keyup="validateDate(newDate, timeModel)"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="1" class="pa-0 pt-8">
            <v-menu
              v-if="!isStaticLocation"
              v-model="showControl"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              :disabled="disabled"
              nudge-left="50"
              min-width="300px"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  aria-label="Open calender dialog"
                  class="ma-0"
                  v-on="on"
                >
                  <v-icon class="mr-0 text--darken-2" color="grey">
                    event
                  </v-icon>
                </v-btn>
              </template>
              <v-card class="date-time-picker">
                <v-container>
                  <v-row class="flex-wrap ma-0">
                    <v-col>
                      <v-date-picker
                        v-if="selectedTab == DateTab"
                        v-model="dateModel"
                        no-title
                        scrollable
                        :min="minDate"
                        :max="maxDate"
                        color="primary"
                        @input="clickAnyDate"
                      />
                      <v-time-picker
                        v-if="selectedTab == TimeTab"
                        v-model="timeModel"
                        scrollable
                        format="24hr"
                        :min="minTime"
                        :max="maxTime || defaultMaxTime"
                        color="primary"
                        no-title
                      />
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          label="Cancel"
                          aria-label="Cancel"
                          @click="onClickCancel"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          label="Ok"
                          aria-label="Ok"
                          @click="onClickOk"
                        >
                          OK
                        </v-btn>
                      </v-card-actions>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>
        <v-card v-if="isStaticLocation" class="date-time-picker">
          <v-container>
            <v-row class="flex-wrap ma-0">
              <v-col cols="4" class="left-part primary">
                <div class="c-datepicker__header">
                  <div class="c-datepicker__header-day">
                    <span class="js-day">{{ dayName }}</span>
                  </div>
                  <div class="c-datepicker__header-date">
                    <span
                      class="c-datepicker__header-date__month date-item js-date-month"
                    >
                      {{ yearName }}
                    </span>
                    <span
                      class="c-datepicker__header-date__day date-item js-date-day"
                    >
                      {{ dayValue }}
                    </span>
                    <span
                      class="c-datepicker__header-date__time date-item js-date-time"
                    >
                      <span
                        class="c-datepicker__header-date__hours js-date-hours active"
                      >
                        >
                        {{ timeValue }}
                      </span>
                    </span>
                  </div>
                </div>
                <div class="activators">
                  <div class="pointer">
                    <v-icon @click.prevent="onClickDate">event</v-icon>
                  </div>
                  <div class="pointer">
                    <v-icon @click.prevent="onClickTime">access_time</v-icon>
                  </div>
                </div>
              </v-col>
              <v-col cols="8">
                <v-date-picker
                  v-if="selectedTab == DateTab"
                  v-model="dateModel"
                  no-title
                  scrollable
                  :min="minDate"
                  :max="maxDate"
                  color="primary"
                  @input="clickAnyDate"
                >
                </v-date-picker>
                <v-time-picker
                  v-if="selectedTab == TimeTab"
                  v-model="timeModel"
                  scrollable
                  format="24hr"
                  :min="minTime"
                  :max="maxTime"
                  color="primary"
                  no-title
                >
                  >
                </v-time-picker>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="onClickCancel">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="onClickOk">OK</v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import moment from 'moment'
import Shared from '@/common/shared'
import Environment from '@/common/environment'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

enum Tab {
  Date,
  Time,
}

const DATE_FORMAT = 'YYYY-MM-DD'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = DATE_FORMAT + ' ' + TIME_FORMAT

@Component({
  components: { ValidationProvider, ValidationObserver },
})
export default class DateTimePicker extends Vue {
  public static DATE_FORMAT = DATE_FORMAT
  public isValidDate = false

  private DateTab: Tab = Tab.Date
  private TimeTab: Tab = Tab.Time
  private controlDate: moment.Moment | null | undefined = null
  private initialValue: moment.Moment | null | undefined = null

  @Prop() private dateTime: moment.Moment
  @Prop() private placeHolderText: string
  @Prop() private minDate: string
  @Prop() private maxDate: string
  @Prop() private minTime: string
  @Prop() private maxTime: string
  @Prop() private displayFormat: string
  @Prop() private isStaticLocation: boolean
  @Prop() private isValidationRequired: boolean
  @Prop() private disabled: boolean
  @Prop() private isCurrentTime: boolean
  @Prop() private initialDateTimeControlOptionMsg: string
  @Prop({ default: -1 }) private index: number
  private globalDateTimeFormat: string = this.environment.dateTimeFormat
  private newDate: any = ''

  private showControl = false
  private dateModel = ''
  private timeModel = '00:00'
  private selectedTab: Tab = Tab.Date

  private pauseEmit = true
  private showControlOption = true

  private mounted() {
    this.pauseEmit = true
    this.setDateTimeModelsFromValue(this.dateTime)
    this.setControlDate()
    this.pauseEmit = false
  }

  @Watch('dateTime')
  private valueChanged(newValue: moment.Moment | null | undefined) {
    this.pauseEmit = true
    this.setDateTimeModelsFromValue(newValue)
    this.setControlDate()
    this.pauseEmit = false
    if (this.dateTime) {
      this.showControlOption = false
    }
  }

  @Watch('dateModel')
  private dateChanged() {
    this.setControlDate()
    this.selectedTab = Tab.Time
  }

  @Watch('timeModel')
  private timeChanged() {
    this.setControlDate()
  }

  @Watch('showControl')
  private controlVisiblityChanged(showingControl: boolean) {
    if (showingControl) {
      this.selectedTab = Tab.Date
      this.initialValue = this.dateTime
    }
  }

  private setControlDate(): void {
    if (this.dateModel && this.timeModel) {
      const val = this.dateModel + ' ' + this.timeModel
      this.controlDate = moment(val, DATE_TIME_FORMAT)
      this.newDate = moment(val, DATE_TIME_FORMAT)
      this.newDate = Shared.getFormatedDate(
        moment(this.newDate),
        this.environment.dateTimeFormat
      )
    } else {
      this.controlDate = undefined
    }
    if (!this.pauseEmit) {
      this.emitValueEvent(this.controlDate)
    }
  }

  private emitValueEvent(value: moment.Moment | null | undefined) {
    this.$emit('update:dateTime', value)
  }

  private setDateTimeModelsFromValue(
    newValue: moment.Moment | null | undefined
  ): void {
    if (!moment.isMoment(newValue) || !newValue.isValid()) {
      this.dateModel = ''
      this.timeModel = '00:00'
      return
    }

    const newDate: moment.Moment = moment(
      newValue.format(DATE_FORMAT),
      DATE_FORMAT
    )
    const newTime: moment.Moment = moment(
      newValue.format(TIME_FORMAT),
      TIME_FORMAT
    )

    if (this.minDate) {
      if (moment(this.minDate, DATE_FORMAT).isAfter(newDate)) {
        return
      }
    }
    if (this.maxDate) {
      if (moment(this.maxDate, DATE_FORMAT).isBefore(newDate)) {
        return
      }
    }
    if (this.minTime) {
      if (moment(this.minTime, TIME_FORMAT).isAfter(newTime)) {
        return
      }
    }
    if (this.maxTime) {
      if (moment(this.maxTime, TIME_FORMAT).isBefore(newTime)) {
        return
      }
    }

    const ndm: string = newValue.format(DATE_FORMAT)
    if (ndm !== this.dateModel) {
      this.dateModel = ndm
    }

    const ntm: string = newValue.format(TIME_FORMAT)
    if (ntm !== this.timeModel) {
      this.timeModel = ntm
    }
  }

  private get defaultMaxTime() {
    const currentMomentDate = moment()
    return currentMomentDate.format(DATE_FORMAT) === this.dateModel
      ? currentMomentDate.format(TIME_FORMAT)
      : undefined
  }

  private get selectedValueForDisplay(): string {
    if (moment.isMoment(this.dateTime) && this.dateTime.isValid()) {
      // return formatted date as per set in Environment json
      const formattedDate: string = this.displayFormat
        ? Shared.getFormatedDate(moment(this.dateTime), this.displayFormat)
        : Shared.getFormatedDate(
            moment(this.dateTime),
            this.environment.dateTimeFormat
          )
      return formattedDate
    } else {
      if (this.isValidationRequired !== true) {
        return '---- -- -- --:--'
      } else {
        return ''
      }
    }
  }

  private get yearName(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('MMMM YYYY')
    }
    return '-- ----'
  }

  private get dayName(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('dddd')
    }
    return '--'
  }

  private get dayValue(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('DD')
    }
    return '--'
  }

  private get timeValue(): string {
    if (moment.isMoment(this.controlDate) && this.controlDate.isValid()) {
      return this.controlDate.format('HH:mm')
    }
    return '--:--'
  }

  private get getDateTimeFormatMaskValue(): string {
    return this.globalDateTimeFormat.replace(/[a-zA-Z]/g, '#')
  }

  private onClickDate() {
    this.selectedTab = Tab.Date
  }

  private onClickTime() {
    this.selectedTab = Tab.Time
  }

  private onClickOk() {
    this.isValidDate = true
    this.showControl = false
  }

  private onClickCancel() {
    // user cancelled, reset dateTime back to value given on display
    this.emitValueEvent(this.initialValue)
    this.showControl = false
  }

  // Click present time icon to set current value
  private OnClickPresentTime() {
    // close current date picker control
    this.showControl = false
    this.emitValueEvent(moment(new Date()))
    this.isValidDate = true
    this.setFocusToDateTimeInput()
  }

  private setFocusToDateTimeInput() {
    this.showControlOption = false
    setTimeout(() => {
      const inputElement: any = this.$refs.dateTimeInput as any
      if (inputElement) {
        inputElement.focus()
      }
    }, 0)
  }

  private clickAnyDate() {
    this.dateChanged()
  }

  private validateDate(dateString: any) {
    this.isValidDate = moment(
      dateString,
      this.environment.dateTimeFormat,
      true
    ).isValid()
    if (
      this.isValidDate &&
      (moment(dateString, this.environment.dateTimeFormat).isAfter(
        this.dateTime
      ) ||
        moment(this.maxDate, DATE_FORMAT).isBefore(this.dateTime))
    ) {
      this.controlDate = moment(dateString, this.environment.dateTimeFormat)
      this.setDateTimeModelsFromValue(this.controlDate)
    } else {
      this.controlDate = undefined
    }
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }
}
</script>

<style lang="scss" scoped>
.date-time-picker {
  ::v-deep .v-card {
    box-shadow: none;
    &__actions {
      .v-btn {
        padding: 0 16px;
        &.v-size {
          &--default {
            font-size: inherit;
          }
        }
      }
    }
  }
  .v-picker--date.v-card {
    width: 100%;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  ::v-deep .v-picker {
    font-size: inherit;
    &__body {
      flex: none;
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }
    &--time {
      min-height: 296px;
    }
  }
  .v-date-picker-years {
    max-height: 274px;
  }

  .v-container,
  .v-date-time-picker {
    .flex {
      padding: 0;
    }
  }
  ::v-deep .v-time-picker-clock {
    background: rgba(0, 0, 0, 0.05);
    height: 270px;
    width: 270px;
    padding: 0px;

    &__container {
      align-items: flex-start;
      padding: 0;
      height: 270px !important;
    }
    &__item {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .left-part {
    position: relative;
    color: #ffffff;
    .activators {
      position: absolute;
      bottom: 10px;
      left: 10px;
      right: 10px;
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
      .pointer {
        cursor: pointer;
      }
      i {
        color: #ffffff;
      }
    }
  }
}

.c-datepicker {
  &__header {
    text-align: center;
    &-day {
      padding: 10px;
      font-size: 17px;
      line-height: 21px;
      background: rgba(0, 0, 0, 0.2);
    }
    &-date {
      padding: 30px 10px;
    }
    .date-item {
      display: block;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 10px;
    }
    .js-date-day {
      line-height: 64px;
      font-size: 60px;
    }
  }
}

.time-access-content {
  position: relative !important;
  ::v-deep .v-input {
    padding-right: 0px;
  }
}

.btn-group {
  ::v-deep .v-btn {
    &.v-size {
      &--small {
        font-size: 13px;
        .v-btn__content {
          padding: 0 8px;
        }
      }
    }
  }
}
</style>
