<template>
  <section role="region" aria-label="Customer verification">
    <RecaptchaVerification
      v-if="isReCaptchaEnabled"
      ref="recaptchaVerification"
      @verifyCustomer="verifyCustomer"
    />
    <div role="form" aria-label="verification form">
      <v-container fluid class="px-2 pt-2 verification__container">
        <h2
          class="verification__title mb-1"
          :style="{ color: clientTemplate.primaryColor }"
        >
          Verification Code
        </h2>
        <v-row
          wrap
          :style="{ background: clientTemplate.secondaryColor }"
          class="pa-3 ma-0"
        >
          <v-col cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="Verification code"
              rules="required|numeric|length:6"
            >
              <v-text-field
                v-model="verificationCode"
                label="Verification Code"
                aria-required="true"
                counter="6"
                maxlength="6"
                required
                :error-messages="errors"
                class="required"
                :disabled="isUserVerifiedSuccess"
              >
              </v-text-field
            ></ValidationProvider>
          </v-col>
          <v-col
            v-if="!isUserVerifiedSuccess"
            cols="12"
            class="text-right mt-1 pa-0"
          >
            <span
              class="verification__resend-label"
              :style="{
                color: clientTemplate.complementaryColor + '!important',
              }"
            >
              You didn't receive a code?
              <a
                :class="
                  showTimer || disableResendButton
                    ? 'verification__resend-btn--disabled'
                    : ''
                "
                class="verification__resend-btn"
                :disabled="showTimer"
                href="Javascript:void(0)"
                :style="{
                  color: clientTemplate.complementaryColor + '!important',
                }"
                @click="resendVerificationCode()"
                >Resend</a
              >
            </span>
            <span v-if="showTimer" class="verification__resend-timer">
              <v-icon
                class="px-1"
                small
                :style="{ color: clientTemplate.complementaryColor }"
                >timer</v-icon
              >
              <b :style="{ color: clientTemplate.complementaryColor }"
                >00:{{ getTimeLeft }}</b
              >
            </span>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="validateUserVerificationMessage" class="px-2">
        <span
          tabindex="0"
          class="verification__error-text"
          :style="{ color: clientTemplate.complementaryColor }"
        >
          {{ validateUserVerificationMessage }}
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import ClientDetail from '@/components/ClientDetail.vue'
import InsurerPortalModel from '../models/InsurerPortalModel'
import Shared from '../common/shared'
import RequestVerificationCodeModel from '../models/RequestVerificationCodeModel'
import ClientTemplate from '@/models/client/client-template'
import {
  ContactPreference as ContactPreferenceEnum,
  ResponseType,
} from '@/common/enums'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import VerifyPortalAccessUserModel from '@/models/VerifyPortalAccessUserModel'
import RecaptchaVerification from '@/components/RecaptchaVerification.vue'
import RecaptchaResponse from '@/models/recaptcha/recaptcha-response'
import ValidateVerificationCodeModel from '@/models/ValidateVerificationCodeModel'
import ResponseOfValidateVerificationCodeModel from '@/models/ResponseOfValidateVerificationCodeModel'
import Environment from '@/common/environment'

@Component({
  components: { ClientDetail, RecaptchaVerification },
})
export default class CustomerVerification extends Vue {
  private validateUserVerificationMessage = ''
  private verificationCodeValue = ''

  private regexSixDigit = new RegExp('\\d{6}')

  private showTimer = true
  private disableResendButton = false
  private isResendSuccessful = false
  private timer = 0
  private timeLimitToEnableResendButton = 60
  private timeLeftToEnableResendButton = 60
  private contactPreferenceEnum = ContactPreferenceEnum
  private localVerifyCustomer: VerifyPortalAccessUserModel | null = null

  $refs!: {
    recaptchaVerification: RecaptchaVerification
  }

  private get verificationCode(): string {
    return this.verificationCodeValue
  }

  private set verificationCode(value) {
    this.verificationCodeValue = value
    if (this.regexSixDigit.test(value)) {
      this.validateVerificationCode()
    }
  }

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['generalModule/navigation'].isUserVerifiedSuccess
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get termsAndServicesAccepted(): boolean {
    return this.$store.getters['cookieModule/termsAndServicesAccepted']
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  public get recaptchaToken() {
    return this.$store.getters['generalModule/recaptchaToken']
  }

  public get isRecaptchaValid(): boolean {
    const recaptchaResponse: RecaptchaResponse | null =
      this.$store.getters['generalModule/recaptchaResponse']
    return recaptchaResponse !== null && recaptchaResponse.success
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get highestVisitedStep(): number {
    return this.$store.getters['generalModule/navigation'].highestVisitedStep
  }

  @Watch('currentStep')
  public handleCustomerInforUpdate(_: any, oldValue: number) {
    if (
      this.highestVisitedStep < 4 &&
      oldValue === 2 &&
      this.isCustomerInforUpdated
    ) {
      if (this.isReCaptchaEnabled) {
        this.$refs.recaptchaVerification.verifyCustomer()
      } else {
        this.verifyCustomer()
      }
    }
  }

  private get isCustomerInforUpdated(): boolean {
    if (this.localVerifyCustomer) {
      if (
        this.insurerPortal.contactPreference ===
        this.contactPreferenceEnum.Email
      ) {
        return this.localVerifyCustomer.email !== this.insurerPortal.email
      } else {
        return (
          this.localVerifyCustomer.mobilePhone !==
          this.insurerPortal.mobilePhone
        )
      }
    }
    return false
  }

  private async verifyCustomer(): Promise<void> {
    try {
      const {
        clientTitle,
        clientForename,
        clientLastName,
        mobilePhone,
        email,
      } = this.insurerPortal

      const verifyCustomer: VerifyPortalAccessUserModel = {
        clientTitle,
        clientForename,
        clientLastName,
        mobilePhone,
        email,
        contactPreference: this.insurerPortal.contactPreference,
        termsAndConditionsAccepted: !this.clientTemplate.privacyPolicyText
          ? true
          : this.termsAndServicesAccepted,
        captchaResponse: this.recaptchaToken,
      }
      this.localVerifyCustomer = JSON.parse(JSON.stringify(verifyCustomer))
      await this.$store.dispatch(
        'generalModule/submitVerifyCustomer',
        verifyCustomer
      )
    } catch (err) {
      this.$store.dispatch(
        'generalModule/submitShowErrorSnackbar',
        new ErrorSnackBar('Something went wrong, please try again!'),
        { root: true }
      )
    }
  }

  private mounted() {
    if (this.appInEditMode) {
      this.verificationCode = '111111'
    }
    this.setTimerValue()
  }

  private get getTrackingId() {
    return this.$store.getters['generalModule/trackingId']
  }

  private async resendVerificationCode() {
    this.disableResendButton = true
    this.isResendSuccessful = false
    this.validateUserVerificationMessage = ''
    const requestVerificationCodeModel: RequestVerificationCodeModel = {
      trackingId: this.getTrackingId,
    }
    var response = await this.$store.dispatch(
      'insurerPortalModule/resendVerificationCode',
      requestVerificationCodeModel
    )

    if (response) {
      this.disableResendButton = false
      this.validateUserVerificationMessage = 'Verification code resent!'
      this.isResendSuccessful = true
      this.verificationCode = ''
      this.showTimer = true
      this.setTimerValue()
    } else {
      this.showTimer = false
      this.validateUserVerificationMessage =
        'Something went wrong. Please try again!'
    }
  }

  private setTimerValue() {
    if (!this.isResendSuccessful && !this.disableResendButton) {
      this.isResendSuccessful = false
    }
    if (!this.isUserVerifiedSuccess) {
      // set timer
      this.showTimer = true
      this.startTimer()
    } else {
      this.showTimer = false
    }
  }

  private startTimer(): void {
    // reset the timer
    clearInterval(this.timer)
    this.timeLeftToEnableResendButton = this.timeLimitToEnableResendButton
    if (this.showTimer) {
      this.timer = window.setInterval(() => {
        if (this.timeLeftToEnableResendButton > 0) {
          this.timeLeftToEnableResendButton--
        } else {
          this.showTimer = false
        }
      }, 1000)
    }
  }

  private get getTimeLeft(): string {
    return Shared.getMinutesFromSecond(
      this.timeLeftToEnableResendButton,
      '0',
      2
    )
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  public get isReCaptchaEnabled(): boolean {
    return this.environment.isReCaptchaEnabled
  }

  public created() {
    if (!this.isReCaptchaEnabled) {
      this.verifyCustomer()
    }
  }
  public validateVerificationCode() {
    this.isLoading = true

    this.validateUserVerificationMessage = ''

    // set properties to verify user portal access
    const validateVerificationCodeModel: ValidateVerificationCodeModel = {
      trackingId: this.getTrackingId,
      clientTitle: this.insurerPortal.clientTitle,
      clientForename: this.insurerPortal.clientForename,
      clientLastName: this.insurerPortal.clientLastName,
      code: this.verificationCode,
    }

    this.$store
      .dispatch(
        'insurerPortalModule/submitVerificationCode',
        validateVerificationCodeModel
      )
      .then((response: ResponseOfValidateVerificationCodeModel | null) => {
        if (response && response.response === ResponseType.Verified) {
          /// success, go to next step
          this.validateUserVerificationMessage =
            'The code was verified Successfully'
          this.$store.dispatch('generalModule/setUserVerifiedStatus', true)
          this.$emit('userVerified')
        } else {
          this.validateUserVerificationMessage = response
            ? response.message
            : 'Something went wrong. Please try again!'
        }
      })

    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
@mixin disabled-btn {
  cursor: not-allowed;
  pointer-events: none;
  color: #989797 !important;
}
.verification {
  &__container {
    font-family: inherit;
  }
  &__title {
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }
  &__resend-label {
    font-size: 1.4rem;
    line-height: 2rem;
    height: 100%;
  }
  &__error-text {
    font-size: 1.4rem;
    line-height: 2rem;
    height: 100%;
  }
  &__resend-btn {
    &--disabled {
      @include disabled-btn;
    }
  }
  &__resend-timer {
    font-weight: bolder;
    font-size: 1.4rem;
  }
}
</style>
