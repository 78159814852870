<template>
  <section role="region" aria-label="Customer availability">
    <v-container
      class="mt-3 pa-4"
      :style="{ backgroundColor: clientTemplate.secondaryColor }"
    >
      <v-row
        role="region"
        aria-label="First slot availability"
        class="pa-0 ma-0"
      >
        <v-col cols="12" class="mb-2 pa-0">
          <ValidationProvider v-slot="{ failedRules }" rules="required">
            <TimeSlotPicker
              v-model="today"
              :header-text="firstPickedDate"
              :date-selection="true"
              :filter-by-date="true"
              :filter-slot-by-time="isTodayDate(firstPickedDate)"
              slot-type="Today"
              :model="customerAvailability"
              :slot-items.sync="today"
              :picked-dates="pickedDates"
              :can-delete="false"
              @updateSlotItems="onSlotSelectionChange"
            />
            <div v-if="failedRules.required" class="error--text my-2 text-h6">
              This field is required
            </div>
          </ValidationProvider>
        </v-col>
        <v-col
          v-if="
            !hasSecondSlotAvailabilities &&
            tomorrow.length === 0 &&
            dayAfterTomorrow.length === 0 &&
            today.length > 0
          "
          cols="12"
          class="text-right py-0"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                text
                color="primary"
                label="Add second slot availability"
                aria-label="Add second slot availability"
                class="primary--text grey lighten-3 ma-0 elevation-0"
                v-on="on"
                @click="showSecondSlotAvailabilities"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>More Availability Slots</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <section role="region" aria-label="Second slot availability">
        <v-col
          v-if="
            hasSecondSlotAvailabilities ||
            tomorrow.length > 0 ||
            dayAfterTomorrow.length > 0
          "
          cols="12"
          class="mb-2 pa-0"
        >
          <ValidationProvider v-slot="{ failedRules }" rules="required">
            <TimeSlotPicker
              v-model="tomorrow"
              :header-text="secondPickedDate"
              :date-selection="true"
              :filter-by-date="true"
              :filter-slot-by-time="isTodayDate(secondPickedDate)"
              slot-type="Tomorrow"
              :model="customerAvailability"
              :slot-items.sync="tomorrow"
              :picked-dates="pickedDates"
              :can-delete="true"
              @updateSlotItems="onSlotSelectionChange"
              @delete="onDeleteTimeSlotPicker"
            ></TimeSlotPicker>
            <div v-if="failedRules.required" class="error--text my-2 text-h6">
              This field is required
            </div>
          </ValidationProvider>
        </v-col>
        <v-col
          v-if="
            !hasThirdSlotAvailabilities &&
            dayAfterTomorrow.length === 0 &&
            tomorrow.length > 0
          "
          cols="12"
          class="text-right py-0"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                text
                color="primary"
                label="Add third slot availability"
                aria-label="Add third slot availability"
                class="primary--text grey lighten-3 ma-0 elevation-0"
                v-on="on"
                @click="showThirdSlotAvailabilities"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>More Availability Slots</span>
          </v-tooltip>
        </v-col>
      </section>
      <section role="region" aria-label="Third slot availability">
        <v-col
          v-if="hasThirdSlotAvailabilities || dayAfterTomorrow.length > 0"
          cols="12"
          class="mb-2 pa-0"
        >
          <ValidationProvider v-slot="{ failedRules }" rules="required">
            <TimeSlotPicker
              v-model="dayAfterTomorrow"
              :header-text="thirdPickedDate"
              :date-selection="true"
              :filter-by-date="true"
              :filter-slot-by-time="isTodayDate(thirdPickedDate)"
              slot-type="DayAfterTomorrow"
              :model="customerAvailability"
              :slot-items.sync="dayAfterTomorrow"
              :picked-dates="pickedDates"
              :can-delete="true"
              @updateSlotItems="onSlotSelectionChange"
              @delete="onDeleteTimeSlotPicker"
            ></TimeSlotPicker>
            <div v-if="failedRules.required" class="error--text my-2 text-h6">
              This field is required
            </div>
          </ValidationProvider>
        </v-col>
      </section>
    </v-container>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import TimeSlot from '@/models/claim/TimeSlot'
import moment from 'moment'
import Shared from '@/common/shared'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import TimeSlotPicker from '@/components/TimeSlotPicker.vue'
import Environment from '@/common/environment'
import ClientTemplate from '@/models/client/client-template'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

@Component({
  components: {
    TimeSlotPicker,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class CustomerAvailabilityPreview extends Vue {
  private today: TimeSlot[] = []
  private tomorrow: TimeSlot[] = []
  private dayAfterTomorrow: TimeSlot[] = []
  private dayAfterTomorrowDate: Date = new Date()
  private firstPickedDate = ''
  private secondPickedDate = ''
  private thirdPickedDate = ''
  private hasSecondSlotAvailabilities = false
  private hasThirdSlotAvailabilities = false
  private pickedDates: string[] = []

  private set customerAvailability(value: CustomerAvailabilityModel) {
    this.$store.dispatch('insurerPortalModule/setCustomerAvailability', value)
  }
  private get customerAvailability(): CustomerAvailabilityModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
      .customerAvailability
  }
  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private startTimeToDate = (t: TimeSlot) => new Date(t.startTime.toString())

  private firstStartTimeOf = (sortedList: TimeSlot[]) =>
    sortedList.map((c) => c.startTime)[0]

  private addDay = (date: Date) => moment(date).add(1, 'days')

  private getFormattedDate = (d: Date) =>
    Shared.getFormatedDate(moment(d), this.environment.dateFormat)

  private getFormattedDateByMoment = (m: moment.Moment) =>
    Shared.getFormatedDate(m, this.environment.dateFormat)

  private created() {
    if (!this.customerAvailability) {
      this.customerAvailability = new CustomerAvailabilityModel()
    }

    const tempDate = new Date()
    this.today = Shared.SortData(
      this.customerAvailability.timeSlot.filter(
        (a: TimeSlot) =>
          this.startTimeToDate(a).getDate() === tempDate.getDate() &&
          this.startTimeToDate(a).getHours() >= tempDate.getHours()
      ),
      'startTime'
    )
    this.firstPickedDate = this.getFormattedDate(tempDate)

    tempDate.setDate(tempDate.getDate() + 1)
    this.tomorrow = Shared.SortData(
      this.customerAvailability.timeSlot.filter(
        (a: TimeSlot) =>
          this.startTimeToDate(a).getDate() === tempDate.getDate()
      ),
      'startTime'
    )
    this.secondPickedDate = this.getFormattedDate(tempDate)

    tempDate.setDate(tempDate.getDate() + 1)
    this.dayAfterTomorrowDate = new Date(tempDate)
    this.thirdPickedDate = this.getFormattedDate(this.dayAfterTomorrowDate)

    const tomorrowDate: Date = new Date()
    tomorrowDate.setDate(tomorrowDate.getDate() + 1)
    // Get custom selected date slots items if user had picked manually from date picker
    const getSlotsForCustomDate = this.customerAvailability.timeSlot.filter(
      (a: TimeSlot) => this.startTimeToDate(a) > tomorrowDate
    )
    if (getSlotsForCustomDate.length > 0) {
      this.thirdPickedDate = this.getFormattedDate(
        this.firstStartTimeOf(getSlotsForCustomDate)
      )
    }
    this.dayAfterTomorrow = Shared.SortData(getSlotsForCustomDate, 'startTime')
  }

  @Watch('currentStep')
  public resetValue() {
    if (this.currentStep === 6) {
      this.hasSecondSlotAvailabilities = this.tomorrow.length !== 0
      this.hasThirdSlotAvailabilities = this.dayAfterTomorrow.length !== 0
    }
  }

  private onSlotSelectionChange(data: {
    selectedSlots: TimeSlot[]
    chosenFormattedDate: any
    slotType: string
  }) {
    const {
      selectedSlots: slotItems,
      chosenFormattedDate: pickedDate,
      slotType,
    } = data
    if (slotType === 'Today') {
      this.today = slotItems
      this.firstPickedDate = pickedDate
    } else if (slotType === 'Tomorrow') {
      this.tomorrow = slotItems
      this.secondPickedDate = pickedDate
    } else if (slotType === 'DayAfterTomorrow') {
      this.dayAfterTomorrow = slotItems
      this.thirdPickedDate = pickedDate
    }

    this.save()
    this.pickedDates = this.getPickedDates()
  }

  private onDeleteTimeSlotPicker(slotType: string) {
    if (slotType === 'Tomorrow') {
      this.tomorrow = []
      this.hasSecondSlotAvailabilities = false
    } else if (slotType === 'DayAfterTomorrow') {
      this.dayAfterTomorrow = []
      this.hasThirdSlotAvailabilities = false
    }

    this.save()
    this.pickedDates = this.getPickedDates()
  }

  private save() {
    let availableTimeSlots: TimeSlot[] = this.today
      .concat(this.tomorrow)
      .concat(this.dayAfterTomorrow)
    // remove All and Daytime slots
    availableTimeSlots = availableTimeSlots.filter(
      (a: TimeSlot) => a.slotId > 0
    )
    this.customerAvailability.timeSlot = availableTimeSlots
  }

  private showSecondSlotAvailabilities() {
    const momentValue = this.addDay(
      this.firstStartTimeOf(this.getTodaySlotsItems())
    )

    this.secondPickedDate = this.getFormattedDateByMoment(momentValue)
    this.hasSecondSlotAvailabilities = !this.hasSecondSlotAvailabilities
  }

  private showThirdSlotAvailabilities() {
    const dateFormat = 'YYYY-MM-DD'
    const firstPickedDate = moment(
      this.firstStartTimeOf(this.getTodaySlotsItems())
    ).format(dateFormat)
    const secondPickedDate = moment(
      this.firstStartTimeOf(this.getTomorrowSlotsItems())
    ).format(dateFormat)
    if (secondPickedDate > firstPickedDate) {
      const momentValue = this.addDay(
        this.firstStartTimeOf(this.getTomorrowSlotsItems())
      )

      this.thirdPickedDate = this.getFormattedDateByMoment(momentValue)
    } else {
      const tempDate = this.addDay(
        this.firstStartTimeOf(this.getTomorrowSlotsItems())
      ).format(dateFormat)
      if (tempDate === firstPickedDate) {
        const momentValue = this.addDay(
          this.firstStartTimeOf(this.getTodaySlotsItems())
        )

        this.thirdPickedDate = this.getFormattedDateByMoment(momentValue)
      } else {
        const momentValue = this.addDay(
          this.firstStartTimeOf(this.getTomorrowSlotsItems())
        )

        this.thirdPickedDate = this.getFormattedDateByMoment(momentValue)
      }
    }
    this.hasThirdSlotAvailabilities = !this.hasThirdSlotAvailabilities
  }

  private getTodaySlotsItems() {
    const firstDateTimeSlots = this.customerAvailability.timeSlot.filter(
      (a: TimeSlot) =>
        a.slotId !== -1 && a.slotId !== 0 && a.availabilityOrder === 1
    )
    if (firstDateTimeSlots.length > 0) {
      return Shared.SortData(firstDateTimeSlots, 'startTime')
    }
    return []
  }

  private getTomorrowSlotsItems() {
    const secondDateTimeSlots = this.customerAvailability.timeSlot.filter(
      (a: TimeSlot) =>
        a.slotId !== -1 && a.slotId !== 0 && a.availabilityOrder === 2
    )
    if (secondDateTimeSlots.length > 0) {
      return Shared.SortData(secondDateTimeSlots, 'startTime')
    }
    return []
  }

  private getDayAfterTomorrowSlotsItems() {
    const thirdDateTimeSlots = this.customerAvailability.timeSlot.filter(
      (a) => a.slotId !== -1 && a.slotId !== 0 && a.availabilityOrder === 3
    )
    if (thirdDateTimeSlots.length > 0) {
      return Shared.SortData(thirdDateTimeSlots, 'startTime')
    }
    return []
  }

  private getPickedDates(): string[] {
    const dateFormat = 'YYYY-MM-DD'
    const pickedDates: string[] = []
    if (this.getTodaySlotsItems().length > 0) {
      pickedDates.push(
        Shared.getFormatedDate(
          moment(this.firstStartTimeOf(this.getTodaySlotsItems())),
          dateFormat
        )
      )
    }
    if (this.getTomorrowSlotsItems().length > 0) {
      pickedDates.push(
        Shared.getFormatedDate(
          moment(this.firstStartTimeOf(this.getTomorrowSlotsItems())),
          dateFormat
        )
      )
    }
    if (this.getDayAfterTomorrowSlotsItems().length > 0) {
      pickedDates.push(
        Shared.getFormatedDate(
          moment(this.firstStartTimeOf(this.getDayAfterTomorrowSlotsItems())),
          dateFormat
        )
      )
    }
    return pickedDates
  }

  private isTodayDate(date: string): boolean {
    return date === this.getFormattedDateByMoment(moment())
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }
}
</script>
