import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '../public/assets/css/style.css'
import '@mdi/font/css/materialdesignicons.css'
import 'babel-polyfill'
import Vuebar from 'vuebar'
import '@/validation/validation-config'
import { ValidationProvider } from 'vee-validate'
import ldUserPlugin from './plugins/ld-user-plugin'
import { LDEvaluationDetail } from 'launchdarkly-js-client-sdk'
import { datadogRum } from '@datadog/browser-rum'
import { startDatadog } from './plugins/datadog'
import ConfigurationController from './api/configuration-controller'

startDatadog()

Vue.component('ValidationProvider', ValidationProvider)
Vue.use(Vuetify)
Vue.use(Vuebar)

Vue.config.productionTip = false

const vuetifyConfig = new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
  },
})

Vue.use(ldUserPlugin, {
  envKey: process.env.VUE_APP_LD_CLIENT_ID,
  store,
  ldOptions: {
    streaming: true,
    inspectors: [
      {
        type: 'flag-used',
        name: 'dd-inspector',
        method: (key: string, detail: LDEvaluationDetail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value)
        },
      },
    ],
  },
})

ConfigurationController.GetConfiguration().then((clientSettings) => {
  new Vue({
    router,
    store,
    vuetify: vuetifyConfig,
    render: (h) =>
      h(App, {
        props: { clientSettings: clientSettings },
      }),
  }).$mount('#app')
})
