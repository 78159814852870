import { AxiosResponse } from 'axios'
import PolicyHolderApi from '@/api/PolicyHolderApiAxiosPlugin'
import { IClientTemplate } from '@/models/client/interfaces/client-template'

export default class ConfigurationController {
  public static GetConfiguration(): Promise<IClientTemplate | null> {
    const isPreview = window.location.pathname
      .toLowerCase()
      .endsWith('/preview')
    const portalId = ConfigurationController.GetPortalId()

    return ConfigurationController.GetConfigurationWithDetails(
      portalId,
      isPreview
    )
  }

  private static async GetConfigurationWithDetails(
    portalId: string,
    preview: boolean
  ): Promise<IClientTemplate | null> {
    try {
      const res: AxiosResponse = await PolicyHolderApi.get(
        `configuration?portalId=${portalId}&preview=${preview}`
      )
      if (res.status !== 200) {
        return null
      }
      return res.data
    } catch (err) {
      return null
    }
  }

  private static GetPortalId(): string {
    // Portal IDs can be sub domains, or a root path. For example:
    // - www.homeemergency.co.uk (www)
    // - www.hiscox.homeemergency.co.uk (hiscox)
    // - rsa.homeemergency.co.uk (rsa)
    // - homeemergency.co.uk/rsa (rsa)
    // - homeemergency.co.uk (www)
    // - localhost:8080 (www)
    // - localhost:8080/rsa (rsa)

    // Default case
    let portalId = 'www'

    // Both subdomains and top level domains support double dots, so we need to remove the TLD before we can find the subdomain.
    const domainWithoutTld = window.location.hostname
      .toLowerCase()
      .replace(/(\.co\.uk|\.dev|\.app|\.com)$/, '')

    const lastPeriod = domainWithoutTld.lastIndexOf('.')
    if (lastPeriod === -1) {
      // Doesn't have a subdomain. Check path instead.
      const path = window.location.pathname.split('/')[1] // Path always starts with a slash, so the first element is always empty.
      if (path) {
        portalId = path
      }
    } else {
      const subdomain = domainWithoutTld.substring(0, lastPeriod)
      if (subdomain === 'localhost' || subdomain === '127') {
        // Running locally, leave as default.
      } else if (subdomain === 'www') {
        // Default portal, leave as default.
      } else if (subdomain.startsWith('www.')) {
        // Special case for people trying to go to `www.<portalId>.homeemergency.co.uk`.
        portalId = subdomain.substring(4)
      } else {
        portalId = subdomain
      }
    }

    return portalId
  }
}
