<template>
  <div
    class="step-heading mb-6"
    :style="{ borderColor: complementaryColor }"
    role="region"
    aria-label="Step header"
  >
    <h1 class="custom-display" tabindex="0">
      <span :style="{ color: primaryColor }">{{ heading }}</span>
    </h1>
    <div class="mt-2 mb-2" tabindex="0">
      <p class="heading-text">{{ description }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class EditableHeader extends Vue {
  @Prop() private heading: string
  @Prop() private description: string
  @Prop() private primaryColor: string
  @Prop() private complementaryColor: string
}
</script>

<style scoped lang="scss">
.sub-text {
  font-size: 14px;
}

.custom-display {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.0073529412em !important; // copy from display-1 class
  font-weight: 400;
}

.heading-text {
  font-family: inherit;
  white-space: pre-line;
  font-size: 1.4rem;
  line-height: initial;
  color: initial;
}
</style>
