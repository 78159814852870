<template>
  <section role="region" aria-label="Emergency selection">
    <div v-if="emergencyDisplayList.length > 0" class="emergency">
      <div
        class="add-emergency pa-2"
        :style="{ background: clientTemplate.secondaryColor }"
      >
        <v-row class="flex-wrap ma-0">
          <v-col cols="12" class="mb-2 pa-0 pl-2">
            <h2 class="emergency-description">
              What best describes your situation?
            </h2>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div
              class="emergency-type"
              role="navigation"
              aria-label="emergency selection navigation"
            >
              <ValidationProvider
                ref="emergencyRef"
                v-slot="{ validate, errors }"
                name="emergency"
                rules="required"
                :custom-messages="{ required: 'Please select an emergency' }"
              >
                <v-list v-model="selectedEmergencyIds">
                  <template v-for="(emergency, index) in emergencyDisplayList">
                    <v-list-item
                      v-if="hasMoreEmergency || index < topEmergencyLength"
                      :key="index"
                    >
                      <v-list-item-content
                        class="pa-2 elevation-1"
                        role="button"
                        :aria-label="`${emergency.description} ${
                          isEmergencySelected(emergency.id)
                            ? 'selected'
                            : 'unselected'
                        }`"
                        :class="{
                          'select-emergency': isEmergencySelected(emergency.id),
                        }"
                        :style="{
                          borderColor: clientTemplate.complementaryColor,
                        }"
                        :tabindex="0"
                        @click="onEmergencySelection(emergency, validate)"
                        @keyup.enter.prevent="
                          onEmergencySelection(emergency, validate)
                        "
                      >
                        <v-avatar>
                          <img
                            class="avatar-image"
                            :src="
                              emergency.fileURL
                                ? emergency.fileURL
                                : '/img/emergency.svg'
                            "
                            alt=""
                          />
                        </v-avatar>
                        <v-list-item-title>{{
                          emergency.description
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item
                    v-if="emergencyDisplayList.length > 0 && !hasMoreEmergency"
                  >
                    <v-list-item-content
                      class="pa-2 elevation-1 add-other-emergency"
                      role="button"
                      :style="{
                        borderColor: clientTemplate.complementaryColor,
                      }"
                      :tabindex="0"
                      @click="clickMoreEmergencies"
                      @keyup.enter.prevent="clickMoreEmergencies"
                    >
                      <v-avatar>
                        <v-icon :style="{ color: clientTemplate.primaryColor }"
                          >add_circle_outline</v-icon
                        >
                      </v-avatar>
                      <v-list-item-title
                        :style="{
                          color: clientTemplate.primaryColor + '!important',
                        }"
                        >More Emergencies</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div v-if="errors.length > 0" class="pb-2 mb-2">
                  <div class="pa-2 lighten-4">
                    <p
                      :style="{ color: clientTemplate.complementaryColor }"
                      class="error-class"
                    >
                      {{ errors[0] }}
                    </p>
                  </div>
                </div>
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
      </div>

      <div
        v-for="(selectedEmergency, index) in getEmergenciesWithDetail"
        :id="`selected-emergency-${selectedEmergency.emergencyTypeId}`"
        :key="index + selectedEmergency.emergencyTypeDesc"
        class="emergency-details pa-4 mt-4"
        :style="{ background: clientTemplate.secondaryColor }"
      >
        <v-container fluid class="pa-0">
          <v-row class="flex-wrap ma-0">
            <v-col cols="12 pa-0">
              <h2 class="type-description float-left pt-2" tabindex="0">
                {{ selectedEmergency.emergencyTypeDesc }}
              </h2>
              <v-spacer></v-spacer>
              <v-btn
                icon
                text
                class="ma-0 float-right"
                tabindex="0"
                :style="{ color: clientTemplate.complementaryColor }"
                :label="`Remove ${selectedEmergency.emergencyTypeDesc} emergency`"
                :aria-label="`Remove ${selectedEmergency.emergencyTypeDesc} emergency`"
                @click="removeSelectedEmergency(selectedEmergency)"
              >
                <v-icon small>delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12 pa-0">
              <ValidationProvider
                v-slot="{ errors }"
                :name="`Emergency Detail ${index}`"
                rules="required"
                :custom-messages="{
                  required: 'Emergency Detail is required',
                }"
              >
                <v-select-custom
                  :id="'emergency-select-' + index"
                  :ref="'emergency' + index"
                  v-model="selectedEmergency.emergencyDetail"
                  :items="getSelectedEmergencyDetail(selectedEmergency)"
                  item-value="id"
                  item-text="description"
                  aria-label="Emergency Detail"
                  return-object
                  required
                  :error-messages="errors"
                  :class="'required emergency-select-' + index"
                  single-line
                  @change="onChangeEmergencyDetail(selectedEmergency)"
                >
                  <template slot="selection" slot-scope="data">
                    <v-list-item-content
                      :id="'selected-emergency' + index"
                      class="v-select__selection"
                      aria-haspopup="listbox"
                      role="button"
                      aria-expanded="false"
                      :aria-label="'Emergency Detail ' + data.item.description"
                    >
                      {{ data.item.description }}
                    </v-list-item-content>
                  </template>
                  <template slot="item" slot-scope="data">
                    <v-list-item-content
                      :id="data.item.id"
                      tabindex="0"
                      role="text"
                      :aria-label="
                        getSelectedItem(
                          data.item.description,
                          selectedEmergency.emergencyDetail
                        )
                          ? data.item.description + 'list item selected'
                          : data.item.description
                      "
                    >
                      {{ data.item.description }}
                    </v-list-item-content>
                  </template>
                </v-select-custom>
              </ValidationProvider>
            </v-col>
            <v-col cols="12 pa-0">
              <ValidationObserver v-slot="{ errors }">
                <ValidationProvider
                  :name="`First Noticed ${index}`"
                  rules="required"
                  :custom-messages="{
                    required: 'The First Noticed date is required',
                  }"
                >
                  <DateTimePicker
                    ref="dateTimePicker"
                    v-model="selectedEmergency.firstNoticedAt"
                    :date-time.sync="selectedEmergency.firstNoticedAt"
                    :is-static-location="false"
                    place-holder-text="First Noticed"
                    :max-date="maxFirstNoticedAtDate"
                    :is-current-time="true"
                    :is-validation-required="true"
                    :index="index"
                    initial-date-time-control-option-msg="When did you first notice this issue?"
                  />
                </ValidationProvider>
                <!-- If dateTimePicker have errors, only show its errors to prevent duplication -->
                <div
                  v-if="
                    !errors['dateTimePicker'] &&
                    errors[`First Noticed ${index}`]
                  "
                  class="error-msg"
                >
                  {{ errors[`First Noticed ${index}`][0] }}
                </div>
              </ValidationObserver>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import Environment from '@/common/environment'
import EmergencyModel from '@/models/EmergencyModel'
import AddEmergency from '@/models/AddEmergency'
import DateTimePicker from '@/components/DateTimePicker.vue'
import moment from 'moment'
import EmergencyDetailModel from '@/models/EmergencyDetailModel'
import ClientTemplate from '@/models/client/client-template'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import EmergencyQA from '@/models/EmergencyQA'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import VSelectCustom from './custom-vuetify/VSelectCustom'

@Component({
  components: {
    DateTimePicker,
    ValidationProvider,
    ValidationObserver,
    VSelectCustom,
  },
})
export default class EmergencySelection extends Vue {
  $refs!: {
    [k: string]: any
  }

  private hasMoreEmergency = false
  private maxFirstNoticedAtDate = ''
  private topEmergencyLength = 0

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  public get emergenciesQAs() {
    return this.insurerPortal.emergenciesQAs
  }

  private set emergenciesQAs(payload: EmergencyQA[]) {
    this.$store.dispatch('insurerPortalModule/setEmergenciesQAs', payload)
  }

  private get selectedEmergencyIds(): number[] {
    // need for validate function of ValidationProvider
    return this.emergencies.map((item) => item.emergencyTypeId)
  }

  @Watch('emergencies', { deep: true })
  private async revalidate() {
    await this.$refs.emergencyRef.validate()
  }

  @Watch('emergencyDisplayList')
  private updateEmergency() {
    this.topEmergencyLength = this.emergencyDisplayList.length
    this.hasMoreEmergency =
      this.topEmergencyLength === this.emergencyTypes.length
  }

  private getSelectedItem(description: string, emergency: EmergencyModel) {
    if (emergency && emergency.description) {
      return description === emergency.description
    }
    return false
  }

  private removeSelectedEmergency(emergency: AddEmergency) {
    this.getEmergenciesWithDetail.splice(
      this.getEmergenciesWithDetail.findIndex(
        (d: any) => d.emergencyTypeId === emergency.emergencyTypeId
      ),
      1
    )
  }

  private mounted() {
    this.maxFirstNoticedAtDate = moment().format(DateTimePicker.DATE_FORMAT)

    this.topEmergencyLength = this.emergencyDisplayList.length
    this.hasMoreEmergency =
      this.topEmergencyLength === this.emergencyTypes.length
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private clickMoreEmergencies() {
    this.hasMoreEmergency = true
    this.emergencyDisplayList = this.emergencyDisplayList.concat(
      this.emergencyTypes.filter((m) => {
        return !this.emergencyDisplayList.some((s) => {
          return m.id === s.id
        })
      })
    )
  }

  private get getEmergenciesWithDetail(): AddEmergency[] {
    return this.emergencies
  }
  private set getEmergenciesWithDetail(value: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', value)
  }

  private onEmergencySelection(
    emergency: EmergencyModel,
    validate: (e: any) => Promise<any>
  ): void {
    const selectedEmergency: AddEmergency = new AddEmergency()
    if (this.isEmergencySelected(emergency.id)) {
      this.getEmergenciesWithDetail.splice(
        this.getEmergenciesWithDetail.findIndex(
          (d: any) => d.emergencyTypeId === emergency.id
        ),
        1
      )
    } else {
      const defaultEmergencyDetail = new EmergencyDetailModel()
      defaultEmergencyDetail.description = 'Select Emergency Detail'
      ;(defaultEmergencyDetail as any).id = null
      defaultEmergencyDetail.tradeId = -1
      defaultEmergencyDetail.typeId = -1
      if (
        emergency &&
        emergency.emergencyDetails &&
        emergency.emergencyDetails.length > 0
      ) {
        emergency.emergencyDetails.unshift(defaultEmergencyDetail)
      } else {
        emergency.emergencyDetails = []
        emergency.emergencyDetails.push(defaultEmergencyDetail)
      }

      // Maintain list of emergencies in InsurerPortalModel
      selectedEmergency.emergencyTypeId = emergency.id
      selectedEmergency.emergencyTypeDesc = emergency.description
      selectedEmergency.fileURL = emergency.fileURL
      selectedEmergency.emergencyDetailDesc = defaultEmergencyDetail.description
      selectedEmergency.emergencyDetailId = defaultEmergencyDetail.id
      ;(selectedEmergency.emergencyDetail as any) = null
      this.getEmergenciesWithDetail &&
        this.getEmergenciesWithDetail.push(selectedEmergency)

      Vue.nextTick(() => {
        const emergencyDetailElement: HTMLElement | null =
          document.getElementById(`selected-emergency-${emergency.id}`)
        emergencyDetailElement?.scrollIntoView({ behavior: 'smooth' })
      })
    }

    this.updateInsurerPortalEmergenciesQAs(emergency.id)

    validate(this.selectedEmergencyIds)

    Vue.nextTick(() => {
      const currentEmergencyIndex =
        this.getEmergenciesWithDetail.indexOf(selectedEmergency)
      if (currentEmergencyIndex >= 0) {
        const commentRef: any = this.$refs['emergency' + currentEmergencyIndex]
        commentRef[0].blur()
      }
    })
  }

  private updateInsurerPortalEmergenciesQAs(emergencyId: number): void {
    if (this.emergenciesQAs.length > 0 && emergencyId) {
      const filteredEmergencyQAs = this.emergenciesQAs.filter(
        (e) => e.forEmergencyTypeId !== emergencyId
      )
      this.emergenciesQAs = filteredEmergencyQAs
    }
  }

  private getSelectedEmergencyDetail(item: any) {
    const emergency: EmergencyModel | undefined =
      this.emergencyDisplayList.find((e) => e.id === item.emergencyTypeId)
    if (emergency !== undefined) {
      return emergency.emergencyDetails
    }
  }

  private onChangeEmergencyDetail(item: any) {
    // Maintain list of emergencies in InsurerPortalModel
    Vue.nextTick(() => {
      const emergency: AddEmergency | undefined =
        this.getEmergenciesWithDetail.find(
          (e: any) => e.emergencyTypeId === item.emergencyTypeId
        )
      if (emergency !== undefined) {
        item.emergencyDetail =
          emergency.emergencyDetail?.id === null
            ? null
            : emergency.emergencyDetail
        if (item.emergencyDetail) {
          const detailItem: any = emergency.emergencyDetail
          emergency.emergencyDetailId = detailItem.id
          emergency.emergencyDetailDesc = detailItem.description
          emergency.tradeId = detailItem.tradeId
        }
      }
    })
  }

  private isEmergencySelected(id: number) {
    return this.selectedEmergencyIds.find((x: number) => x === id)
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private set emergencyTypes(emergencyList: EmergencyModel[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencyTypes', emergencyList)
  }

  private get emergencyTypes(): EmergencyModel[] {
    return this.$store.getters['insurerPortalModule/emergencyTypes']
  }

  private set emergencyDisplayList(emergencyList: EmergencyModel[]) {
    this.$store.dispatch(
      'insurerPortalModule/setEmergencyDisplayList',
      emergencyList
    )
  }

  private get emergencyDisplayList(): EmergencyModel[] {
    return this.$store.getters['insurerPortalModule/emergencyDisplayList']
  }

  private set emergencies(emergencies: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', emergencies)
  }

  private get emergencies(): AddEmergency[] {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
      .emergencies
  }
}
</script>

<style scoped lang="scss">
.emergency-type {
  .v-avatar {
    border-radius: 0%;
    object-fit: contain;
    margin: auto;
    .avatar-image {
      border-radius: 0%;
      object-fit: contain;
      margin: auto;
    }
  }

  ::v-deep .v-list-item {
    &__title {
      text-align: center;
      white-space: inherit;
      height: auto !important;
      margin-top: 5px;
      color: #212121 !important;
    }
  }

  ::v-deep .v-list {
    flex-wrap: wrap;
    display: flex;
    background-color: transparent;
    &-item {
      flex: 0 1 auto;
      height: auto !important;

      width: 50%;
      margin-bottom: 16px;
      display: flex;
      &__title {
        font-size: 16px;
        line-height: 24px;
      }
      &__content {
        background-color: #fff;
        cursor: pointer;
        transition: 0.3s all linear;
        height: 100%;
        align-items: flex-start;
      }
      &__content:hover {
        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2),
          0px 12px 17px 2px rgba(0, 0, 0, 0.14),
          0px 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
      }
    }
  }
}

.select-emergency {
  border-bottom: 3px solid #000;
  background-color: #f5f5f5 !important;
}

.add-other-emergency {
  ::v-deep .v-icon {
    font-size: 50px;
  }
}

.type-description {
  font-size: 1.15em;
}
.error-class {
  text-decoration: none;
  font-weight: bold;
  cursor: none;
  pointer-events: none;
}

.emergency-description {
  font-size: 1.17em;
}

.add-emergency {
  font-size: 1.4rem;
  line-height: 2rem;
  color: initial;
  letter-spacing: unset;
}

.emergency-details {
  ::v-deep .v-card__text {
    font-size: inherit;
  }
}

.v-select__selection {
  text-overflow: ellipsis;
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  flex-basis: auto;
}

@media (max-width: 420px) {
  .emergency-type {
    ::v-deep {
      .v-list-item {
        padding: 0px 4px !important;
        margin-bottom: 8px;
      }
    }
  }

  .v-expansion-panel__header {
    padding: 0px 4px !important;
  }

  .add-emergency.pa-4,
  .emergency-details.pa-4 {
    margin-bottom: 8px;
  }
}
.error-msg {
  color: #ff5252;
  font-size: 12px;
  font-weight: 400;
}
</style>
