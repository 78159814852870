<template>
  <section v-if="images.length > 0" role="region" :aria-label="regionLabel">
    <h3 class="text-h5 font-weight-bold">{{ imageHeader }}</h3>
    <div class="image-preview-wrapper">
      <div
        v-for="(img, index) in images"
        :key="`${img}-${index}`"
        class="image-preview"
      >
        <v-img
          class="image-preview__img"
          :src="img.previewUrl"
          :alt="img.fileName"
        ></v-img>
        <span
          class="image-preview__text text-h5 font-weight-bold"
          tabindex="0"
          >{{ img.fileName }}</span
        >
        <v-btn
          v-if="allowDelete"
          fab
          small
          class="image-preview__remove elevation-1 white"
          :label="removeImageLabelWithName(img)"
          :aria-label="removeImageLabelWithName(img)"
          @click="removeImage(img)"
        >
          <v-icon small :style="{ color: clientTemplate.complementaryColor }"
            >delete</v-icon
          >
        </v-btn>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import ClientTemplate from '@/models/client/client-template'
import ImageBase from '@/models/image/image-base'
@Component
export default class ImageDisplay extends Vue {
  @Prop({ default: '' })
  private regionLabel: string

  @Prop({ default: '' })
  private imageHeader: string

  @Prop({ default: '' })
  private removeImageLabel: string

  @Prop({ default: () => [] })
  private images: ImageBase

  @Prop({ default: true })
  private allowDelete: boolean

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private removeImageLabelWithName(img: ImageBase) {
    return `${this.removeImageLabel} - ${img.fileName}`
  }

  private removeImage(img: ImageBase) {
    this.$emit('removeImage', img)
  }
}
</script>

<style scoped lang="scss">
.image-preview-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;

  @media (max-width: 420px) {
    justify-content: center;
  }
}

.image-preview {
  $image-width: 150px;
  $image-height: 150px;

  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px;

  &__img {
    width: $image-width;
    height: $image-height;
  }

  &__remove {
    position: absolute !important;
    margin: 0 !important;
    top: -15px;
    right: -15px;
  }

  &__text {
    white-space: nowrap;
    overflow-x: scroll;
    font-weight: 700;
    width: $image-width;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
