<template>
  <div
    id="topScroll"
    class="portal-template"
    :style="{ backgroundColor: clientTemplate.primaryColor }"
  >
    <div role="region" aria-label="header">
      <v-container class="container--fluid pa-0">
        <v-app-bar fixed class="white">
          <v-toolbar-title>
            <img
              :src="
                clientTemplate.logoURL
                  ? clientTemplate.logoURL
                  : environment.policyHolderAppBaseLogoUrl
              "
              class="img-responsive logo-img"
              :alt="
                (clientTemplate.displayName
                  ? clientTemplate.displayName
                  : 'Policy') + ' - Logo'
              "
            />
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>
      </v-container>
    </div>
    <div class="focus-able-content"></div>
    <div class="content-section px-6" role="region" aria-label="main content">
      <div class="content-container">
        <div class="details-content elevation-1">
          <template v-if="!isQuestionModeLinkNotValid">
            <ValidationObserver ref="questionModeWrapper">
              <div class="template-tab">
                <div v-show="ready" class="mid-content">
                  <div>
                    <div>
                      <v-tabs
                        :value="currentTabHref"
                        background-color="white"
                        slider-color="primary"
                      >
                        <v-tab
                          v-for="(title, index) in stepTitles"
                          :key="`${title}-${index}`"
                          :href="`step${index + 1}`"
                        >
                          {{ title }}
                        </v-tab>

                        <!--Step 1-->
                        <!-- Explain tab -->
                        <v-tab-item value="step1">
                          <ValidationObserver>
                            <v-card flat>
                              <v-card-text>
                                <EditableHeader
                                  :heading.sync="clientTemplate.headingStep5"
                                  :description.sync="
                                    clientTemplate.descriptionStep5
                                  "
                                  :primary-color="clientTemplate.primaryColor"
                                  :complementary-color="
                                    clientTemplate.complementaryColor
                                  "
                                />
                                <section
                                  role="region"
                                  aria-label="Emergency question expansion panel"
                                >
                                  <EmergencyQuestionGenerator />
                                </section>
                              </v-card-text>
                            </v-card>
                          </ValidationObserver>
                        </v-tab-item>

                        <!--Step 2-->
                        <!-- Customer Availability tab -->
                        <v-tab-item value="step2">
                          <ValidationObserver>
                            <v-card flat>
                              <v-card-text>
                                <EditableHeader
                                  :heading.sync="clientTemplate.headingStep6"
                                  :description.sync="
                                    clientTemplate.descriptionStep6
                                  "
                                  :primary-color="clientTemplate.primaryColor"
                                  :complementary-color="
                                    clientTemplate.complementaryColor
                                  "
                                />
                                <div>
                                  <CustomerAvailabilityPreview />
                                </div>
                              </v-card-text>
                            </v-card>
                          </ValidationObserver>
                        </v-tab-item>

                        <!--Step 3-->
                        <!-- Upload tab -->
                        <v-tab-item value="step3">
                          <ValidationObserver>
                            <v-card flat>
                              <v-card-text>
                                <EditableHeader
                                  :heading.sync="clientTemplate.headingStep7"
                                  :description.sync="
                                    clientTemplate.descriptionStep7
                                  "
                                  :primary-color="clientTemplate.primaryColor"
                                  :complementary-color="
                                    clientTemplate.complementaryColor
                                  "
                                />
                                <ImageUpload :image-type="emergencyImageType" />
                              </v-card-text>
                            </v-card>
                          </ValidationObserver>
                        </v-tab-item>
                      </v-tabs>
                    </div>
                  </div>
                </div>
                <div
                  class="text-right pr-4 pa-2 white insureractions"
                  role="region"
                  aria-label="Stepper action button"
                >
                  <v-btn
                    v-if="currentStep === 2"
                    :style="{ color: clientTemplate.primaryColor }"
                    text
                    dark
                    label="Previous step"
                    aria-label="Previous step"
                    @click="previousStep()"
                  >
                    <v-icon small>arrow_back_ios</v-icon>&nbsp; Previous
                  </v-btn>
                  <v-btn
                    v-if="
                      showCustomerAvailabilityStep // true means time slot at step 6 didn't selected
                        ? currentStep === 2 //  if time slot didn't select => display submit btn at step 2
                        : currentStep === 1 //  if time slot selected => display submit btn at step 1
                    "
                    class="white--text"
                    :style="{ background: clientTemplate.primaryColor }"
                    :disabled="isLoading"
                    :loading="isLoading"
                    label="Submit"
                    aria-label="Submit"
                    @click="updateClaimDetail"
                  >
                    Submit
                  </v-btn>
                  <v-btn
                    v-if="currentStep === 3"
                    class="white--text"
                    :style="{ background: clientTemplate.primaryColor }"
                    :disabled="isLoading"
                    :loading="isLoading"
                    label="Done"
                    aria-label="Done"
                    @click="isThanksDialogOpen = true"
                  >
                    Done
                  </v-btn>
                  <v-btn
                    v-if="
                      showCustomerAvailabilityStep
                        ? currentStep === 1 // only show next btn at step 1
                        : false // if time slot already selected then step 1 display submit btn instead of next btn, then currentStep never be 2 => false
                    "
                    class="white--text"
                    :style="{ background: clientTemplate.primaryColor }"
                    :disabled="!ready || isLoading"
                    :loading="isLoading"
                    label="Next step"
                    aria-label="Next step"
                    @click="nextStep()"
                  >
                    Next &nbsp;
                    <v-icon small>arrow_forward_ios</v-icon>
                  </v-btn>
                </div>
                <div v-show="!ready">
                  <v-progress-circular
                    class="loading-spinner"
                    :width="2"
                    :size="50"
                    indeterminate
                    color="primary"
                  />
                </div>
              </div>
            </ValidationObserver>
          </template>
          <div
            v-else
            class="job-complete-notification px-5 d-flex justify-center align-center"
          >
            <MessageCard
              message="Unknown or expired link!"
              class="text-center"
            />
          </div>
        </div>
      </div>
    </div>
    <ThanksDialog :is-open="isThanksDialogOpen"></ThanksDialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import Shared from '@/common/shared'
import { ImageType } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import EmergencyQuestionGenerator from '@/components/EmergencyQuestionGenerator.vue'
import CustomerAvailabilityPreview from '@/components/CustomerAvailabilityPreview.vue'
import CustomerAvailabilityModel from '@/models/claim/CustomerAvailabilityModel'
import DateTimePicker from '@/components/DateTimePicker.vue'
import EditableHeader from '@/components/EditableHeader.vue'
import UpdateQuestionModeJobDetailModel from '@/models/claim/UpdateQuestionModeJobDetailModel'
import AddEmergencyAnswerModel from '@/models/claim/AddEmergencyAnswerModel'
import MessageCard from '@/components/MessageCard.vue'
import ClientTemplate from '@/models/client/client-template'
import ImageUpload from '@/components/image/ImageUpload.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ThanksDialog from '@/components/ThanksDialog.vue'

@Component({
  name: 'QuestionModePortal',
  components: {
    ThanksDialog,
    EmergencyQuestionGenerator,
    DateTimePicker,
    CustomerAvailabilityPreview,
    EditableHeader,
    MessageCard,
    ImageUpload,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class InsurerPortal extends Vue {
  private ready = false
  private isThanksDialogOpen = false
  private localTimer: Date = new Date()
  private localTimerForClaim: Date = new Date()
  private showCustomerAvailabilityStep = true //Question mode - True mean availability time slot at step 6 didn't selected, need show next btn at step 5 to go step 6. False mean time slot already selected, show submit btn at step 5 instead of next btn
  private isQuestionModeLinkNotValid = false
  private emergencyImageType: ImageType = ImageType.ExampleofEmergency

  $refs!: {
    questionModeWrapper: InstanceType<typeof ValidationObserver>
  }

  private get currentStep(): number {
    return this.$store.getters['generalModule/navigation'].currentStep
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get emergencies() {
    return this.insurerPortal.emergencies
  }

  private set emergencies(payload: AddEmergency[]) {
    this.$store.dispatch('insurerPortalModule/setEmergencies', payload)
  }

  private get customerAvailability(): CustomerAvailabilityModel {
    return this.insurerPortal.customerAvailability
  }

  private set customerAvailability(payload: CustomerAvailabilityModel) {
    this.$store.dispatch('insurerPortalModule/setCustomerAvailability', payload)
  }

  public stepTitles: string[] = ['Explain', 'Customer Availability', 'Upload']

  public gaNextStepEvents = [
    { step: 1, event: 'Emergency Question Completed - Question mode' },
    { step: 2, event: 'Customer Availability - Question mode' },
    { step: 3, event: 'Image Upload Completed - Question mode' },
  ]

  public gaPreviousStepEvents = [
    { step: 2, event: 'Back To Emergency Question List - Question mode' },
  ]

  public get currentTabHref(): string {
    return `step${this.currentStep}`
  }

  private get isLoading(): boolean {
    return this.$store.getters['generalModule/navigation'].isLoading
  }

  private set isLoading(value) {
    this.$store.dispatch('generalModule/setIsLoading', value)
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }
  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get getTrackingId(): string {
    return this.$store.getters['generalModule/trackingId']
  }
  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get clientName(): string {
    return this.$route.params.clientName
  }

  private get trackingIdParam(): string {
    return this.$route.params.trackingId
  }

  private handleLinkClicksInGA(
    categoryName: string,
    actionName: string,
    time: Date | null = null
  ) {
    if (this.appInEditMode) {
      // return if app is in edit mode
      return
    }
    // send event updates to google analytics
    Shared.sendEventsInGoogleAnalytics(
      categoryName,
      actionName,
      time ? time : this.localTimer
    )
    // reset timer for second button click
    this.localTimer = new Date()
  }

  // We take a param, incase we need to move forward
  // more than one step in the form, in the future
  public async nextStep(stepValue = 1) {
    if (!(await this.validateCurrentStep())) return

    const gaEvent = this.gaNextStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'NEXT')
    }

    this.$store.dispatch('generalModule/nextStep', stepValue)
    this.scrollToTop()
  }

  public previousStep(stepValue = 1) {
    const gaEvent = this.gaPreviousStepEvents.find(
      (x) => x.step === this.currentStep
    )
    if (gaEvent) {
      this.handleLinkClicksInGA(gaEvent.event, 'PREVIOUS')
    }
    this.$store.dispatch('generalModule/previousStep', stepValue)
    this.scrollToTop()
  }

  public async validateCurrentStep(): Promise<boolean> {
    const isValid = await this.activeStepValidator.validate()
    if (!isValid) {
      for (const key of Object.keys(this.activeStepValidator.fields).sort()) {
        if (this.activeStepValidator.fields[key].invalid) {
          this.activeStepValidator.refs[key].$el.scrollIntoView()
          return false
        }
      }
    }
    return true
  }

  public get activeStepValidator() {
    return this.$refs.questionModeWrapper.observers[this.currentStep - 1]
  }

  private async created() {
    if (!this.getTrackingId) {
      this.$store.dispatch(
        'generalModule/updateTrackingId',
        this.trackingIdParam
      )
    }

    try {
      const { isAllLoggedEmergenciesAccepted } = await this.$store.dispatch(
        'insurerPortalModule/getQuestionModeJobDetails'
      )

      if (isAllLoggedEmergenciesAccepted) {
        this.$router.push({
          name: 'claimtracking',
          params: {
            trackingId: this.trackingIdParam,
            clientName: this.clientName,
          },
        })
        return
      }

      this.ready = true
      this.showCustomerAvailabilityStep = !(
        this.customerAvailability.timeSlot &&
        this.customerAvailability.timeSlot.length <= 0
      )
    } catch (error) {
      // TODO: handle error
      this.$router.replace('/')
      this.isQuestionModeLinkNotValid = true
    }
    this.handleLinkClicksInGA('Client Portal', 'LOADED')
  }

  private async updateClaimDetail() {
    const {
      healthAndSafetyQAs,
      vulnerabilityQA,
      accessNoteQA,
      emergenciesQAs,
    } = this.insurerPortal

    const addEmergencyAnswers = emergenciesQAs.map(
      ({ id, answer, comment }) =>
        ({ id, answer, comment } as AddEmergencyAnswerModel)
    )

    const updateQuestionModeJobDetail = new UpdateQuestionModeJobDetailModel()
    updateQuestionModeJobDetail.emergenciesQAs = addEmergencyAnswers
    updateQuestionModeJobDetail.healthAndSafetyQAs = healthAndSafetyQAs
    updateQuestionModeJobDetail.vulnerabilityQA = vulnerabilityQA
    updateQuestionModeJobDetail.accessNoteQA = accessNoteQA
    if (this.customerAvailability.id) {
      updateQuestionModeJobDetail.customerAvailability =
        this.customerAvailability
    } else {
      updateQuestionModeJobDetail.customerAvailability =
        new CustomerAvailabilityModel()
      updateQuestionModeJobDetail.customerAvailability.timeSlot =
        this.customerAvailability.timeSlot
    }
    const subDomain = window.location.host
    // Update Question Mode Job Detail
    this.isLoading = true

    try {
      const result = await this.$store.dispatch(
        'insurerPortalModule/updateQuestionModeJobDetails',
        {
          subDomain: subDomain,
          model: updateQuestionModeJobDetail,
        }
      )
      if (result) {
        this.handleLinkClicksInGA('Customer Availability Filled', 'NEXT')
        this.handleLinkClicksInGA(
          'Claim Logged',
          'CLAIM LOGGED',
          this.localTimerForClaim
        )
        this.localTimerForClaim = new Date()
        if (this.showCustomerAvailabilityStep) {
          this.nextStep()
        } else {
          this.nextStep(2)
        }
      }
      this.isLoading = false
    } catch (error) {
      // TODO: handle error
      this.isLoading = false
    }
  }

  private scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
</script>

<style lang="scss" scoped>
.theme--light.v-card > .v-card__text {
  color: unset !important;
}
.content-container {
  height: 100vh;
  margin-top: -230px;
  padding-top: 230px;
  box-sizing: border-box;
}
.toolbar {
  z-index: 9;
}
.portal-template {
  height: 100%;
  min-width: 100%;
  ::v-deep .v-toolbar__title {
    max-width: 230px;
  }
}
.template-tab {
  height: 100%;
  .mid-content {
    overflow-y: auto;
    height: 100%;
    .v-tabs {
      height: 100%;
    }
  }
  ::v-deep .v-tabs__wrapper {
    display: none;
  }
  ::v-deep .v-tabs-bar {
    display: none;
  }
  ::v-deep .v-tabs__items {
    width: 100%;
  }
}

.logo-img {
  height: 42px;
  object-fit: contain;
}

.custom-display-1 {
  font-size: 24px !important;
  line-height: 28px !important;
}
.step-heading {
  border-left: 3px solid #000;
  padding-left: 30px;
}

.content-section {
  padding-top: 90px !important;
  padding-bottom: 24px !important;
  height: 100%;
  .details-content {
    height: 100%;
    max-width: 500px;
    margin: auto;
    background-color: #fff;
  }
}

.insureractions {
  border-top: 1px solid #e2e2e2 !important;
  ::v-deep .v-btn__content {
    padding: 0px 6px !important;
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .v-container {
    padding: 0px !important;
  }
}
@media (max-width: 548px) {
  .insureractions {
    left: 24px;
    right: 24px;
  }
}

@media (max-width: 420px) {
  .content-section {
    padding: 10px !important;
    padding-top: 70px !important;
  }
  .step-heading {
    padding-left: 25px;
  }
  header {
    .v-toolbar__title {
      max-width: 180px !important;
    }
  }

  .insureractions {
    bottom: 10px !important;
    left: 10px !important;
    right: 10px !important;
  }
}

.insuer-portal {
  height: 100%;
}
.loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -moz-transform: translate(-50%);
}

@media (min-width: 420px) {
  .content-container {
    margin-top: -250px;
    padding-top: 250px;
  }
}
</style>
