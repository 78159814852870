import Axios, { InternalAxiosRequestConfig, AxiosInstance } from 'axios'
import Environment from '@/common/environment'
import store from '@/store'

export function getBaseUrl(): string {
  const env: Environment = store.getters['generalModule/environment']
  return `${env.apiBaseUrl}/api/`
}

function generatePolicyHolderApiAxiosInstance(): AxiosInstance {
  const axios = Axios.create({
    validateStatus: (status: number) =>
      (status >= 200 && status < 300) || status === 404,
  })

  axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    config.headers = config.headers || {}
    if (!config.headers.Authorization) {
      const trackingId: string = store.getters['generalModule/trackingId']
      // set auth header from store value if not already set (auth.ts)
      config.headers.Authorization = 'bearer ' + trackingId
    }
    // make sure base URL is set
    config.baseURL = getBaseUrl()
    return config
  })

  // return instance
  return axios
}

const axiosInstance = generatePolicyHolderApiAxiosInstance()
export default axiosInstance
