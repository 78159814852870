import { render, staticRenderFns } from "./ImageDisplay.vue?vue&type=template&id=49c28cea&scoped=true"
import script from "./ImageDisplay.vue?vue&type=script&lang=ts"
export * from "./ImageDisplay.vue?vue&type=script&lang=ts"
import style0 from "./ImageDisplay.vue?vue&type=style&index=0&id=49c28cea&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@6.26.3_css-loader@6.11.0_webpack@5.98._lpz7yfflcfbikbc3glhfyeagle/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49c28cea",
  null
  
)

export default component.exports