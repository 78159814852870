<template>
  <div class="client-details" role="region" aria-label="Customer details">
    <div role="form" aria-label="Customer detail form">
      <v-container fluid class="px-2">
        <h2
          class="client-details__title"
          :style="{ color: clientTemplate.primaryColor }"
        >
          About You
        </h2>

        <v-row
          wrap
          class="mb-0 px-4 ma-0"
          :style="{ background: clientTemplate.secondaryColor }"
        >
          <v-col cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="title"
              rules="required"
            >
              <v-select-custom
                :id="'client-title'"
                v-model.trim="insurerPortal.clientTitle"
                aria-label="Title"
                return-object
                :items="clientTitleList"
                required
                :class="'required client-title'"
                single-line
                :error-messages="errors"
                placeholder="Select Title"
              >
                <template slot="selection" slot-scope="data">
                  <v-list-item-content
                    :id="'selected-title'"
                    class="v-select__selection"
                    aria-haspopup="listbox"
                    role="button"
                    aria-expanded="false"
                    :aria-label="'Title ' + data.item"
                  >
                    {{ data.item }}
                  </v-list-item-content>
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content
                    :id="data.item"
                    role="text"
                    tabindex="0"
                    :aria-label="
                      getSelectedItem(data.item)
                        ? data.item + ' list item selected'
                        : data.item
                    "
                  >
                    {{ data.item }}
                  </v-list-item-content>
                </template>
              </v-select-custom>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="first name"
              rules="required"
            >
              <v-text-field
                ref="clientForenameField"
                v-model.trim="insurerPortal.clientForename"
                label="First Name"
                aria-required="true"
                maxlength="50"
                required
                :error-messages="errors"
                class="required"
              >
              </v-text-field
            ></ValidationProvider>
          </v-col>
          <v-col cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="last name"
              rules="required"
            >
              <v-text-field
                v-model.trim="insurerPortal.clientLastName"
                :error-messages="errors"
                label="Last Name"
                aria-required="true"
                maxlength="50"
                required
                class="required"
              >
              </v-text-field
            ></ValidationProvider>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Contact Preference"
                rules="required"
              >
                <v-radio-group
                  id="contactPreference"
                  v-model="contactPreference"
                  :disabled="isUserVerifiedSuccess"
                  :error-messages="errors"
                  :mandatory="false"
                  name="contactPreferenceRadioGroup"
                  :class="
                    contactPreference !== contactPreferenceEnum.SMS
                      ? 'custom-value'
                      : 'show-number'
                  "
                >
                  <v-row class="mx-0">
                    <v-col cols="auto pa-0">
                      <div class="client-details__radio-btn">
                        <v-radio
                          color="primary"
                          :value="contactPreferenceEnum.SMS"
                          label="SMS"
                          name="contactPreferenceRadioSMS"
                          role="radio"
                        />
                      </div>
                      <div class="client-details__radio-btn">
                        <v-radio
                          class="pr-3"
                          color="primary"
                          :value="contactPreferenceEnum.Email"
                          label="Email"
                          name="contactPreferenceRadioEmail"
                          role="radio"
                        />
                      </div>
                    </v-col>
                    <v-col class="pa-0">
                      <div
                        class="mt-2"
                        :class="
                          contactPreference !== contactPreferenceEnum.SMS
                            ? 'client-details__input-content--hidden'
                            : ''
                        "
                      >
                        <PhoneNumber
                          ref="contactPreferenceSMS"
                          :is-validation-required="true"
                          :is-disabled="
                            isUserVerifiedSuccess ||
                            contactPreference !== contactPreferenceEnum.SMS
                          "
                        />
                      </div>
                      <div
                        class="mt-2"
                        :class="
                          contactPreference !== contactPreferenceEnum.Email
                            ? 'client-details__input-content--hidden'
                            : ''
                        "
                      >
                        <ValidationProvider
                          v-slot="{ errors: emailErrors }"
                          name="Contact Email"
                          :rules="{
                            required:
                              contactPreference === contactPreferenceEnum.Email,
                            email: true,
                          }"
                        >
                          <v-text-field
                            v-model.trim="insurerPortal.email"
                            :error-messages="emailErrors"
                            aria-label="Contact Email"
                            aria-required="true"
                            maxlength="50"
                            :disabled="
                              isUserVerifiedSuccess ||
                              contactPreference !== contactPreferenceEnum.Email
                            "
                            required
                            class="required pt-0"
                          >
                          </v-text-field
                        ></ValidationProvider>
                      </div>
                    </v-col>
                  </v-row>
                </v-radio-group>
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
        <div v-if="clientTemplate.privacyPolicyText" class="mt-4">
          <h2
            class="client-details__title"
            :style="{
              color: clientTemplate.primaryColor,
            }"
          >
            Terms & Conditions
          </h2>
          <div :style="{ background: clientTemplate.secondaryColor }">
            <div
              :style="{
                background: clientTemplate.secondaryColor,
              }"
              class="mt-2 pa-2 client-details__termsConditions"
            >
              <span
                :style="{
                  color: clientTemplate.complementaryColor,
                }"
              >
                Please read our terms and conditions carefully.
              </span>
              <a
                href="Javascript:void(0)"
                :style="{
                  color: clientTemplate.complementaryColor,
                }"
                @click="showPrivacyPolicyDetailsDialog"
                >Terms & Conditions</a
              >
              <div>
                <ValidationProvider
                  v-slot="{ invalid, validated }"
                  name="isTermsAndServicesAccepted"
                  :rules="{ required: { allowFalse: false } }"
                >
                  <v-checkbox
                    v-model="isTermsAndServicesAccepted"
                    required
                    :error="validated && invalid"
                    :color="
                      validated && invalid
                        ? clientTemplate.errorColor
                        : clientTemplate.complementaryColor
                    "
                    label="By submitting your claim you agree to be contacted by email and phone."
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import Environment from '@/common/environment'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { ContactPreference } from '@/common/enums'
import PhoneNumber from '@/components/PhoneNumber.vue'
import ClientTemplate from '@/models/client/client-template'
import VSelectCustom from './custom-vuetify/VSelectCustom'

@Component({
  components: { PhoneNumber, VSelectCustom },
})
export default class ClientDetail extends Vue {
  private clientTitleList: string[] = ['Mr', 'Mrs', 'Miss', 'Dr', 'Ms']
  private contactPreferenceEnum = ContactPreference

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['generalModule/isUserVerifiedSuccess']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }
  private get contactPreference(): ContactPreference | null {
    return this.insurerPortal.contactPreference
  }
  private set contactPreference(value: ContactPreference | null) {
    this.$store.dispatch('insurerPortalModule/submitContactPreference', value)
  }

  private getSelectedItem(title: string) {
    if (this.insurerPortal && this.insurerPortal.clientTitle) {
      return this.insurerPortal.clientTitle === title
    }
    return false
  }

  private showPrivacyPolicyDetailsDialog() {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  public get isTermsAndServicesAccepted(): boolean {
    return this.$store.getters['cookieModule/termsAndServicesAccepted']
  }

  public set isTermsAndServicesAccepted(isTermsAndServicesAccepted: boolean) {
    this.$store.dispatch(
      'cookieModule/acceptTermsAndServices',
      isTermsAndServicesAccepted
    )
  }
}
</script>

<style scoped lang="scss">
.client-details {
  &__title {
    margin-bottom: 15px;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }
  &__radio-btn {
    height: 58px;
    display: flex;
  }
  &__input-content {
    &--hidden {
      ::v-deep .v-text-field__slot {
        opacity: 0 !important;
      }
      ::v-deep .v-text-field__details {
        opacity: 0 !important;
      }

      ::v-deep .v-select__slot {
        opacity: 0 !important;
      }
    }
  }
  &__termsConditions {
    color: red;
    font-size: 1.4rem;
    line-height: 2rem;
  }
}
</style>
