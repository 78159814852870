<template>
  <div>
    <div v-if="localQA" class="vulnerable-card-preview">
      <v-col cols="12 pa-0">
        <h3 class="vulnerable-card-preview__header">
          {{ localQA.questionText }}
        </h3>
      </v-col>
      <v-col cols="12 pa-0">
        <v-radio-group
          id="health-safety-qa"
          v-model="localQA.isHealthAffected"
          :disabled="isDisableQuestion"
          @change="onSelectionChange()"
        >
          <v-radio label="Yes" color="primary" :value="true" />
          <v-radio label="No" color="primary" :value="false" />
        </v-radio-group>
        <v-select
          v-if="localQA.level"
          id="health-safety-qa"
          v-model="localQA.level"
          :items="getLevel"
          aria-label="Level"
          item-text="level"
          item-value="id"
          :disabled="isDisableQuestion"
          class="health-safety-qa"
          single-line
        >
          <template slot="selection" slot-scope="data">
            <v-list-item-content
              id="selected-health-safety-qa"
              class="v-select__selection"
              aria-haspopup="listbox"
              role="button"
              aria-expanded="false"
              :aria-label="'Level' + data.item.level"
            >
              {{ data.item.level }}
            </v-list-item-content>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-content
              :id="data.item.level"
              role="text"
              tabindex="0"
              :aria-label="
                getSelectedItem(data.item.level)
                  ? data.item.level + 'list item selected'
                  : data.item.level
              "
            >
              {{ data.item.level }}
            </v-list-item-content>
          </template>
        </v-select>
        <v-textarea
          v-if="localQA.isHealthAffected"
          v-model="localQA.answerDetail"
          label="Details"
          :disabled="isDisableQuestion"
        >
        </v-textarea>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import { Levels } from '@/common/enums'
import AddEmergency from '@/models/AddEmergency'
import InsurerPortalModel from '@/models/InsurerPortalModel'

interface IHealthAndSafetyLevel {
  id: string
  level: string
}

@Component
export default class HealthSafetyQA extends Vue {
  @Prop() emergency: AddEmergency
  private localQA: HealthAndSafetyQAModel | null = null

  private get questionAnswer() {
    return this.healthAndSafetyQAs.find(
      (e) =>
        e.forEmergencyTypeId === this.emergency.emergencyTypeId ||
        e.forEmergencyDetailId === this.emergency.emergencyDetailId
    )
  }
  private created() {
    this.onChangeQuestionAnswer(this.questionAnswer)
  }

  @Watch('questionAnswer')
  onChangeQuestionAnswer(value: HealthAndSafetyQAModel | undefined) {
    if (value) {
      if (!this.localQA) {
        this.localQA = { ...value }
      }
    }
  }

  @Watch('localQA', { deep: true })
  private onLocalQAChange(value: HealthAndSafetyQAModel | null) {
    this.$store.dispatch('insurerPortalModule/setHealthAndSafetyQAs', [value])
  }

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get healthAndSafetyQAs() {
    return this.insurerPortal.healthAndSafetyQAs ?? []
  }

  private set healthAndSafetyQAs(payload: HealthAndSafetyQAModel[]) {
    this.$store.dispatch('insurerPortalModule/setHealthAndSafetyQAs', payload)
  }

  private get acceptedEmergencies(): number[] {
    return this.$store.getters['insurerPortalModule/acceptedEmergencies']
  }

  private get isDisableQuestion(): boolean {
    //  question-mode: disable accepted emergency
    return (
      this.questionAnswer !== undefined &&
      this.questionAnswer.forEmergencyTypeId !== null &&
      this.acceptedEmergencies.length !== 0 &&
      this.acceptedEmergencies.includes(this.questionAnswer.forEmergencyTypeId)
    )
  }

  private onSelectionChange(): void {
    if (!this.localQA) {
      return
    }

    if (this.localQA.isHealthAffected && !this.localQA.level) {
      this.localQA.level = Levels[Levels.Low]
    }

    if (!this.localQA.isHealthAffected) {
      this.localQA.level = null
      this.localQA.answerDetail = null
    }
  }

  // bind dropdown for level from enum
  private get getLevel(): IHealthAndSafetyLevel[] {
    let keys: any[]
    const levelItems: IHealthAndSafetyLevel[] = []

    keys = Object.keys(Levels).filter(Number)
    keys.forEach((element) => {
      const entity: any = {}
      entity.id = Levels[element]
      entity.level = Levels[element]
      if (entity.level === 'Low') {
        entity.level = 'Low H&S Risk - Potential Risk of Injury'
      } else if (entity.level === 'Medium') {
        entity.level = 'Medium H&S Risk - Imminent Risk of Serious Injury'
      } else if (entity.level === 'High') {
        entity.level = 'High H&S Risk - Immediate Danger to Life'
      }
      levelItems.push(entity)
    })

    return levelItems
  }

  private getSelectedItem(level: string) {
    if (this.localQA && this.localQA.level) {
      return level === this.localQA.level
    }
    return false
  }
}
</script>

<style scoped lang="scss">
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.vulnerable-card-preview >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vulnerable-card-preview__header {
  font-size: 1.4rem;
  line-height: 2rem;
}
</style>
