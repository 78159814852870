<template>
  <div>
    <div v-if="localQA" class="vulnerable-card-preview">
      <v-col cols="12">
        <h3 class="vulnerable-card-preview__header">
          {{ localQA.questionText }}
        </h3>
      </v-col>
      <v-col cols="12">
        <ValidationProvider v-slot="{ errors }" rules="required">
          <v-radio-group
            id="vulnerability-qa"
            v-model="localQA.isHealthAffected"
            :error-messages="errors"
            @change="onSelectionChange()"
          >
            <v-radio label="Yes" color="primary" :value="true" />
            <v-radio label="No" color="primary" :value="false" />
          </v-radio-group>
        </ValidationProvider>
        <ValidationProvider
          v-if="localQA.isHealthAffected"
          v-slot="{ errors }"
          rules="required"
        >
          <v-select
            v-if="localQA.isHealthAffected"
            id="health-safety-qa"
            v-model="localQA.level"
            :items="getLevel"
            label="Severity level"
            item-text="level"
            item-value="id"
            class="health-safety-qa"
            :error-messages="errors"
          >
            <template slot="selection" slot-scope="data">
              <v-list-item-content
                id="selected-health-safety-qa"
                class="v-select__selection"
                aria-haspopup="listbox"
                role="button"
                aria-expanded="false"
                :aria-label="`Level${data.item.level}`"
              >
                {{ data.item.level }}
              </v-list-item-content>
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content
                :id="data.item.level"
                role="text"
                tabindex="0"
                :aria-label="
                  getSelectedItem(data.item.level)
                    ? data.item.level + 'list item selected'
                    : data.item.level
                "
              >
                {{ data.item.level }}
              </v-list-item-content>
            </template>
          </v-select>
        </ValidationProvider>
        <ValidationProvider
          v-if="localQA.isHealthAffected"
          v-slot="{ errors }"
          rules="required"
        >
          <v-textarea
            v-if="localQA.isHealthAffected"
            v-model="localQA.answerDetail"
            label="Details"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Levels } from '@/common/enums'
import VulnerabilityQAModel from '@/models/claim/VulnerabilityQAModel'
import InsurerPortalModel from '@/models/InsurerPortalModel'
import { ValidationProvider } from 'vee-validate'

interface IHealthAndSafetyLevel {
  id: string
  level: string
}

@Component({
  components: { ValidationProvider },
})
export default class VulnerabilityQA extends Vue {
  public localQA: VulnerabilityQAModel | null = null

  private get insurerPortal(): InsurerPortalModel {
    return this.$store.getters['insurerPortalModule/insurerPortalModel']
  }

  private get questionAnswer() {
    return this.insurerPortal.vulnerabilityQA
  }

  @Watch('questionAnswer', { immediate: true })
  onChangeQuestionAnswer(value: VulnerabilityQAModel | null) {
    if (value && !this.localQA) {
      this.localQA = { ...value }
    }
  }

  @Watch('localQA', { deep: true })
  private onLocalQAChange(value: VulnerabilityQAModel | null) {
    this.$store.dispatch('insurerPortalModule/setVulnerabilityQA', value)
  }

  public onSelectionChange(): void {
    if (!this.localQA) {
      return
    }

    if (
      this.localQA.isHealthAffected &&
      (!this.localQA.level || this.localQA.level == Levels[Levels.None])
    ) {
      this.localQA.level = Levels[Levels.Low]
    }

    if (!this.localQA.isHealthAffected) {
      this.localQA.level = null
      this.localQA.answerDetail = null
    }
  }

  // bind dropdown for level from enum
  public get getLevel(): IHealthAndSafetyLevel[] {
    return Object.keys(Levels)
      .filter(Number)
      .map((element: any) => ({
        id: Levels[element],
        level: Levels[element],
      }))
  }

  public getSelectedItem(level: string) {
    return this.localQA && this.localQA.level
      ? level === this.localQA.level
      : false
  }
}
</script>

<style scoped lang="scss">
.processing-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.vulnerable-card-preview >>> .progress-linear {
  position: absolute;
  bottom: -11px;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.vulnerable-card-preview__header {
  font-size: 1.4rem;
  line-height: 2rem;
}
</style>
