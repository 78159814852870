<template>
  <div>
    <img
      v-if="appInEditMode"
      src="/img/water-mark.png"
      class="watermark-container"
    />
    <div class="insuer-portal application theme--light">
      <v-app id="inspire">
        <v-main aria-label="Insurer portal application">
          <v-container fluid fill-height pa-0>
            <v-layout>
              <v-flex xs12>
                <v-fade-transition v-bar mode="out-in">
                  <router-view />
                </v-fade-transition>
              </v-flex>
            </v-layout>
          </v-container>
          <div
            v-if="clientTemplate.legalNoticeText"
            class="legal-notice"
            :class="{ 'legal-notice--expanded': legalNoticeExpanded }"
            @click="legalNoticeExpanded = !legalNoticeExpanded"
          >
            <v-icon class="legal-notice__icon">
              <template v-if="legalNoticeExpanded">expand_more</template>
              <template v-else>expand_less</template>
            </v-icon>
            <!-- eslint-disable vue/no-v-html -->
            <div
              v-if="clientTemplate.legalNoticeText"
              v-html="sanitizeHTML(clientTemplate.legalNoticeText)"
            />
            <!-- eslint enable -->
            <div class="legal-notice__container">
              <div class="legal-notice__content">
                <template v-if="clientTemplate.cookieNoticeText">
                  <router-link :to="{ path: cookieNoticePath }" append
                    >Cookie notice</router-link
                  >
                </template>
                <template v-if="clientTemplate.privacyPolicyText">
                  <a @click.stop="showPrivacyPolicyDetails"
                    ><u>Privacy policy</u></a
                  >
                </template>
              </div>
            </div>
          </div>
        </v-main>
        <Confirmation></Confirmation>
        <CookieBanner v-if="showDefaultCookieBanner" />
        <PrivacyPolicyModal />
        <v-snackbar
          v-model="errorSnackbarConfig.showSnackbar"
          :timeout="6000"
          bottom
          :color="errorSnackbarConfig.backgroundColour"
          multi-line
        >
          {{ errorSnackbarConfig.message }}
          <template #action>
            <v-btn
              text
              color="secondary"
              @click.native="errorSnackbarConfig.showSnackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-app>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { SetTheme, InsurerPortalTheme } from '@/common/themes'
import Environment, { EnvironmentMode, getEnvMode } from '@/common/environment'
import Confirmation from '@/components/Confirmation.vue'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal.vue'
import CookieBanner from '@/components/cookie/CookieBanner.vue'
import { cookieService } from './services/cookie-service'
import { IClientTemplate } from './models/client/interfaces/client-template'
import ClientTemplate from './models/client/client-template'
import ScriptTagSettings from './models/cookies/settings/script-tag-settings'
import ErrorSnackBar from './models/generic/error-snackbar'
import Shared from '@/common/shared'

@Component({
  components: {
    Confirmation,
    CookieBanner,
    PrivacyPolicyModal,
  },
})
export default class App extends Vue {
  @Prop()
  public clientSettings: IClientTemplate
  public Vuebar = require('vuebar')
  private showDefaultCookieBanner = false
  public legalNoticeExpanded = false
  private sanitizeHTML = Shared.sanitizeHTML

  public mounted() {
    SetTheme(this, new InsurerPortalTheme())
    this.setErrorColor()
    this.setInputTextColor()
  }

  private async created() {
    this.$store.dispatch(
      'clientModule/submitIsPreviewMode',
      this.$route.path.includes('/preview')
    )

    await this.$store.dispatch(
      'clientModule/submitClientTemplate',
      this.clientSettings
    )

    window.addEventListener('message', this.messageHandler, false)

    await this.$store.dispatch('generalModule/submitRetrieveEnvironmentSecrets')

    if (this.clientSettings.templateCookieRecord) {
      await this.setCookieBanner()
    }

    Vue.use(this.Vuebar)
    Vue.use(VueReCaptcha, {
      siteKey: this.environment.reCaptchaClientKey,
      loaderOptions: {
        autoHideBadge: true,
      },
    })

    const faviconUrl: string =
      this.clientTemplate.favIconURL !== ''
        ? this.clientTemplate.favIconURL
        : '/img/favicon.png'
    const link: any = document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = faviconUrl
    document.getElementsByTagName('head')[0].appendChild(link)
    document.title = this.clientTemplate.displayName

    // set map attribute
    const mapScript = document.createElement('script')
    mapScript.setAttribute(
      'src',
      'https://maps.googleapis.com/maps/api/js?key=' +
        this.environment.googleMapsApiKey +
        '&libraries=places,drawing,geometry'
    )
    if (document && document.head) {
      document.head.appendChild(mapScript)
    }
  }

  private get cookieNoticePath(): string {
    return this.$route.path.includes('/cookienotice') ? '' : 'cookienotice'
  }

  private get appInEditMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }

  private get environment(): Environment {
    return this.$store.getters['generalModule/environment']
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private messageHandler(e: MessageEvent): void {
    const hostMatches = e.origin
      .toLowerCase()
      .startsWith(this.environment.editHostUrl.toLowerCase())

    if (hostMatches === false) {
      const isDev = getEnvMode(this.$store) <= EnvironmentMode.Development
      if (isDev === false) {
        return
      }
    }

    const message = e.data
    if (!message) {
      return
    }

    switch (message.command) {
      case 'UpdateSetting':
        if (message.data) {
          // update clientTemplate
          this.$store.dispatch('clientModule/submitUpdateTemplateSetting', {
            name: message.data.name,
            value: message.data.value,
          })
        }
        break
    }
  }

  private get getErrorColor(): string {
    return this.clientTemplate.errorColor
  }

  @Watch('getErrorColor')
  private onErrorColorChange() {
    this.setErrorColor()
  }

  private setErrorColor() {
    // set configured error color for error-messages
    const styleElement = document.getElementById('errorColor')
    if (styleElement) {
      document.getElementsByTagName('head')[0].removeChild(styleElement)
    }
    const style = document.createElement('style')
    style.id = 'errorColor'
    style.type = 'text/css'
    const errorColor = this.clientTemplate.errorColor
    style.innerHTML =
      '.v-input__control .error--text, .v-input.error--text{color:' +
      errorColor +
      '!important;' +
      'caret-color:' +
      errorColor +
      '!important;}'
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  private get getInputTextColor(): string {
    return this.clientTemplate.inputTextColor
  }

  @Watch('getInputTextColor')
  private onInputTextColorChange() {
    this.setInputTextColor()
  }

  private showPrivacyPolicyDetails(): void {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  private setInputTextColor() {
    // set configured input text color
    const inputStyleElement = document.getElementById('inputTextColor')
    if (inputStyleElement) {
      document.getElementsByTagName('head')[0].removeChild(inputStyleElement)
    }
    const style = document.createElement('style')
    style.id = 'inputTextColor'
    style.type = 'text/css'
    const inputTextColor = this.getInputTextColor
    style.innerHTML =
      '.v-input__control .v-input__slot .v-text-field__slot, .v-input input{color:' +
      inputTextColor +
      '!important;} .v-input__control .v-input__slot .v-select__slot .v-select__selections, .v-select__selection--comma{color:' +
      inputTextColor +
      '!important;} .v-input__control .v-input__slot .v-text-field__slot, textarea {color:' +
      inputTextColor +
      '!important;}'
    document.getElementsByTagName('head')[0].appendChild(style)
  }

  private addCookieScripts() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { clearDup, reloadOTBanner, getCookie } = require('@/common/cookies')

    this.clientTemplate.templateCookieRecord.scripts.forEach((script) =>
      this.addScript(script, true)
    )
    ;[clearDup, reloadOTBanner, getCookie]
      .map((script: string) => ({ body: script } as ScriptTagSettings))
      .forEach((script) => this.addScript(script))
  }

  private addScript(settings: ScriptTagSettings, head = false) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = false
    script.defer = true

    if (settings.source) {
      script.src = settings.source
    }

    if (settings.body) {
      script.innerHTML = settings.body
    }

    if (settings.attributes) {
      settings.attributes.forEach((att) => {
        script.setAttribute(att.key, att.value)
      })
    }

    document[head ? 'head' : 'body'].appendChild(script)
  }

  private get useDefaultCookieBanner(): boolean {
    return this.$store.getters['cookieModule/useDefaultCookieBanner']
  }

  private async setCookieBanner() {
    await this.$store.dispatch('cookieModule/retrieveCookieRecord')

    if (this.useDefaultCookieBanner) {
      this.$store.dispatch(
        'cookieModule/setDefaultCookieSettings',
        cookieService.retrieveDefaultSettings()
      )
      this.showDefaultCookieBanner = true
    } else {
      this.addCookieScripts()
    }
  }

  private get errorSnackbarConfig(): ErrorSnackBar {
    return this.$store.getters['generalModule/errorSnackBar']
  }

  private set errorSnackbarConfig(config: ErrorSnackBar) {
    this.$store.dispatch('generalModule/submitSnackBar', config)
  }
}
</script>

<style lang="scss" scoped>
.insuer-portal {
  min-height: 100%;
  position: relative;
}
.application {
  width: 100%;
}

.v-application {
  width: 100%;
}

.legal-notice {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 20px 10%;
  background-color: white;
  font-size: 12px;
  border-top: lightgrey solid 1px;
  z-index: 99; // footer should always be on top

  p {
    height: 100%;
    margin: 0;
  }

  max-height: 56px;
  transition: max-height 0.15s ease-out;

  &--expanded {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__container {
    width: 50%;
    margin: 15px auto 0;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}
.application >>> .v-content,
.application >>> .v-content--wrap {
  height: 100%;
}
#app >>> .application--wrap {
  min-height: auto;
}
@media only screen and (max-width: 768px) {
  .container.fill-height {
    display: block;
  }
}

.watermark-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  opacity: 0.5;
}
</style>
