<template>
  <section role="region" aria-label="Upload Image">
    <h3 v-if="isPreviewMode">Cannot upload images in preview mode</h3>
    <div
      v-else
      class="pa-6 image-upload"
      :style="{ background: clientTemplate.secondaryColor }"
    >
      <h2 class="image-upload__header mb-3">{{ headerText }}</h2>
      <ImageFileSelection
        v-if="showImageSelectionModal"
        :image-type="imageType"
        :allow-multiple-uploads="allowMultipleUploads"
        @closeModal="showImageSelectionModal = false"
      />
      <div v-else>
        <v-btn :disabled="disableUpload" @click="showImageSelectionModal = true"
          >Add photo</v-btn
        >
        <ImageDisplay
          :images="failedUploads"
          :allow-delete="false"
          :image-header="`Failed Images`"
          :region-label="`View failed uploads`"
        />
        <ImageDisplay
          :images="uploadedImages"
          :image-header="`Uploaded Images`"
          :region-label="`View uploaded images`"
          :remove-image-label="`Remove Uploaded Image`"
          @removeImage="removeUploadedImage($event)"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ImageType } from '@/common/enums'
import ClientTemplate from '@/models/client/client-template'
import { Component, Vue, Prop } from 'vue-property-decorator'
import ImageFileSelection from '@/components/image/ImageFileSelection.vue'
import ImageBase from '@/models/image/image-base'
import ImageDisplay from '@/components/image/ImageDisplay.vue'

@Component({
  components: {
    ImageFileSelection,
    ImageDisplay,
  },
})
export default class ImageUpload extends Vue {
  @Prop({ default: true })
  private allowMultipleUploads: boolean
  @Prop({
    default:
      'Adding photos makes it much more likely that we can solve your emergency on our first visit',
  })
  private headerText: string
  @Prop({ default: ImageType.NotSet })
  private imageType: ImageType

  private showImageSelectionModal = false

  private get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  private get disableUpload(): boolean {
    return (
      (!this.allowMultipleUploads && this.uploadedImages.length === 1) ||
      (this.allowMultipleUploads && this.uploadedImages.length >= 10)
    )
  }

  private get uploadedImages(): ImageBase[] {
    const uploadedImages: ImageBase[] =
      this.$store.getters['imageModule/uploadedImages']
    return uploadedImages.filter((x) => x.imageType === this.imageType)
  }

  private get failedUploads(): ImageBase[] {
    const failedUploads: ImageBase[] =
      this.$store.getters['imageModule/failedUploaded']
    return failedUploads.filter((x) => x.imageType === this.imageType)
  }

  private async removeUploadedImage(image: ImageBase) {
    this.$store.dispatch('imageModule/submitUploadedImageToRemove', image)
  }

  private get isPreviewMode(): boolean {
    return this.$store.getters['clientModule/isPreviewMode']
  }
}
</script>

<style lang="scss">
.image-upload {
  &__header {
    font-size: 2rem;
    line-height: 2rem;
  }

  &__counter {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  &__btn {
    position: relative;
    padding: 20px;
    border: 1px solid grey;
    display: block;
    font-size: 1.4rem;
    line-height: 2rem;
    cursor: pointer;

    &--disabled {
      cursor: not-allowed;
    }
  }

  &__icon {
    position: absolute;
    right: 10px;
    transform: rotate(-90deg);
  }

  &__input {
    position: absolute;
    top: -500px;
  }

  &__icon {
    position: absolute;
    right: 10px;
  }

  &__input {
    position: absolute;
    top: -500px;
  }
}
</style>
