<template>
  <section role="region" aria-label="Customer verification">
    <div
      v-if="!termsAndServicesAccepted"
      role="form"
      aria-label="verification form"
    >
      <h2
        class="terms__title"
        :style="{
          color: clientTemplate.primaryColor,
        }"
      >
        Terms & Conditions
      </h2>
      <div :style="{ background: clientTemplate.secondaryColor }">
        <div
          :style="{
            background: clientTemplate.secondaryColor,
          }"
          class="mt-2 pa-2 userMessage"
        >
          <span>
            To use this portal, you must agree to our terms and conditions.
            Please read these carefully before proceeding.
          </span>
          <a href="#" @click.prevent="showPrivacyPolicyDetailsDialog">
            Terms & Conditions
          </a>
          <div>
            <ValidationProvider
              v-slot="{ invalid, validated }"
              name="isTermsAndServicesAccepted"
              :rules="{ required: { allowFalse: false } }"
            >
              <v-checkbox
                v-model="termsAndServiceCheckbox"
                required
                :error="validated && invalid"
                :color="
                  validated && invalid
                    ? clientTemplate.errorColor
                    : clientTemplate.complementaryColor
                "
                label="By checking this box you agree to our terms and conditions"
              />
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="termsAndServicesAccepted"
      role="form"
      aria-label="verification form"
    >
      <v-container
        v-if="!contactPreferenceSet"
        fluid
        class="px-2 pt-2 verification__container"
      >
        <h2
          class="verification__title mb-1"
          :style="{ color: clientTemplate.primaryColor }"
        >
          Contact preferences
        </h2>
        <v-row wrap class="ma-0 pt-3">
          <span class="nomineeMessage">
            We will shortly send a security code to your primary contact method.
            If you would prefer us to send this message to your
            <b>nominated contact</b>, please tick the box below:
          </span>
        </v-row>
        <v-row wrap class="ma-0">
          <v-checkbox
            v-model="useNominatedContact"
            label="Use nominated contact"
            >Test</v-checkbox
          >
        </v-row>
        <v-row wrap class="ma-0">
          <v-btn block @click="setContactPreference">Continue</v-btn>
        </v-row>
      </v-container>
      <v-container
        v-if="contactPreferenceSet"
        fluid
        class="px-2 pt-2 verification__container"
      >
        <h2
          class="verification__title mb-1"
          :style="{ color: clientTemplate.primaryColor }"
        >
          Verification Code
        </h2>
        <v-row
          wrap
          :style="{ background: clientTemplate.secondaryColor }"
          class="pa-3 ma-0"
        >
          <span class="userMessage">
            A verification code has been sent to the selected contact
            preference. Please enter the code below to verify yourself.
          </span>

          <v-col cols="12" class="pa-0">
            <ValidationProvider
              v-slot="{ errors }"
              name="Verification code"
              rules="required|numeric|length:6"
            >
              <v-text-field
                v-model="verificationCode"
                label="Verification Code"
                aria-required="true"
                counter="6"
                maxlength="6"
                required
                :error-messages="errors"
                class="required"
                :disabled="isUserVerifiedSuccess"
              >
              </v-text-field
            ></ValidationProvider>
          </v-col>
          <v-col
            v-if="!isUserVerifiedSuccess"
            cols="12"
            class="text-right mt-1 pa-0"
          >
            <span v-if="!showTimer" class="verification__resend-label">
              You didn't receive a code?
              <a
                :class="
                  showTimer || disableResendButton
                    ? 'verification__resend-btn--disabled'
                    : ''
                "
                class="verification__resend-btn"
                :disabled="showTimer"
                href="#"
                @click.prevent="resendVerificationCode"
                >Resend</a
              >
            </span>
            <span v-if="showTimer" class="verification__resend-timer">
              <v-icon class="px-1" small>timer</v-icon>
              <b>00:{{ getTimeLeft }}</b>
            </span>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="validateUserVerificationMessage" class="px-2">
        <span
          tabindex="0"
          class="verification__error-text"
          :style="{ color: clientTemplate.complementaryColor }"
        >
          {{ validateUserVerificationMessage }}
        </span>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Watch, Vue } from 'vue-property-decorator'
import Shared from '../common/shared'
import RequestVerificationCodeModel from '../models/RequestVerificationCodeModel'
import ClientTemplate from '@/models/client/client-template'
import { ResponseType } from '@/common/enums'
import ErrorSnackBar from '@/models/generic/error-snackbar'
import VerifyClaimTrackingUserModel from '@/models/VerifyClaimTrackingUserModel'
import ValidateClaimTrackingVerificationCodeModel from '@/models/ValidateClaimTrackingVerificationCodeModel'
import ResponseOfValidateVerificationCodeModel from '@/models/ResponseOfValidateVerificationCodeModel'
import SessionController from '@/api/sessionController'

@Component({})
export default class CustomerVerification extends Vue {
  @Prop() private jobId: string
  public termsAndServiceCheckbox = false

  public validateUserVerificationMessage = ''
  private verificationCodeValue = ''

  private regexSixDigit = new RegExp('\\d{6}')

  public showTimer = true
  public disableResendButton = false
  private isResendSuccessful = false
  private timer = 0
  private timeLimitToEnableResendButton = 60
  private timerStartTime: number
  private secondsRemaining = 59
  public useNominatedContact = false
  public contactPreferenceSet = false

  public setContactPreference() {
    this.contactPreferenceSet = true

    this.verifyCustomer()
    this.setTimerValue()
  }

  public get verificationCode(): string {
    return this.verificationCodeValue
  }

  private set verificationCode(value) {
    this.verificationCodeValue = value
    if (this.regexSixDigit.test(value)) {
      this.validateVerificationCode()
    }
  }

  public get isUserVerifiedSuccess(): boolean {
    return this.$store.getters['claimTrackingModule/isUserVerifiedSuccess']
  }

  public get termsAndServicesAccepted(): boolean {
    return this.$store.getters['claimTrackingModule/termsAndServicesAccepted']
  }

  @Watch('termsAndServiceCheckbox')
  private updateTermsAndServiceAccepted() {
    this.$store.dispatch(
      'claimTrackingModule/updateTermsAndServices',
      this.termsAndServiceCheckbox
    )
  }

  public showPrivacyPolicyDetailsDialog() {
    this.$store.dispatch('cookieModule/showPrivacyPolicyDetails', true)
  }

  private async verifyCustomer(): Promise<void> {
    try {
      const verifyCustomer: VerifyClaimTrackingUserModel = {
        jobId: this.jobId,
        termsAndConditionsAccepted:
          this.$store.getters['claimTrackingModule/termsAndServicesAccepted'],
        useNominatedContact: this.useNominatedContact,
      }

      SessionController.verifyCustomerForClaimTracking(verifyCustomer).then(
        (result) => {
          if (result && result.trackingId) {
            this.$store.dispatch(
              'claimTrackingModule/updateTrackingId',
              result.trackingId
            )
          }
        }
      )
    } catch (err) {
      this.$store.dispatch(
        'generalModule/submitShowErrorSnackbar',
        new ErrorSnackBar('Something went wrong, please try again!'),
        { root: true }
      )
    }
  }

  private mounted() {
    const storedJobId = this.$store.getters['claimTrackingModule/jobId']
    if (storedJobId !== this.jobId) {
      this.$store.dispatch(
        'claimTrackingModule/resetClaimTrackingState',
        this.jobId
      )
    }
  }

  private get trackingId() {
    return this.$store.getters['claimTrackingModule/trackingId']
  }

  public async resendVerificationCode() {
    this.disableResendButton = true
    this.isResendSuccessful = false
    this.validateUserVerificationMessage = ''
    const requestVerificationCodeModel: RequestVerificationCodeModel = {
      trackingId: this.trackingId,
    }
    var response = await SessionController.ReSendVerificationCode(
      requestVerificationCodeModel
    )

    if (response) {
      this.disableResendButton = false
      this.validateUserVerificationMessage = 'Verification code resent!'
      this.isResendSuccessful = true
      this.verificationCode = ''
      this.setTimerValue()
    } else {
      this.showTimer = false
      this.validateUserVerificationMessage =
        'Something went wrong. Please try again!'
    }
  }

  private setTimerValue() {
    if (!this.isResendSuccessful && !this.disableResendButton) {
      this.isResendSuccessful = false
    }
    if (!this.isUserVerifiedSuccess) {
      this.startTimer()
    } else {
      this.showTimer = false
    }
  }

  private startTimer(): void {
    clearInterval(this.timer)
    this.timerStartTime = Date.now()
    this.showTimer = true
    this.timer = window.setInterval(() => {
      const secondsElapsed = (Date.now() - this.timerStartTime) / 1000
      this.secondsRemaining = Math.max(
        0,
        Math.floor(this.timeLimitToEnableResendButton - secondsElapsed)
      )
      if (this.secondsRemaining <= 0) {
        clearInterval(this.timer)
        this.showTimer = false
      }
    }, 1000)
  }

  public get getTimeLeft(): string {
    return Shared.getMinutesFromSecond(this.secondsRemaining, '0', 2)
  }

  public get clientTemplate(): ClientTemplate {
    return this.$store.getters['clientModule/clientTemplate']
  }

  public validateVerificationCode() {
    this.validateUserVerificationMessage = ''

    // set properties to verify user portal access
    const validateVerificationCodeModel: ValidateClaimTrackingVerificationCodeModel =
      {
        trackingId: this.trackingId,
        jobId: this.jobId,
        code: this.verificationCode,
      }

    SessionController.ValidateClaimTrackingVerificationCode(
      validateVerificationCodeModel
    ).then((response: ResponseOfValidateVerificationCodeModel | null) => {
      if (response && response.response === ResponseType.Verified) {
        /// success, go to next step
        this.validateUserVerificationMessage =
          'The code was verified Successfully'
        this.$store.dispatch('claimTrackingModule/setUserVerifiedStatus', true)
        this.$store.dispatch('claimTrackingModule/setToken', response.message)
      } else {
        this.validateUserVerificationMessage = response
          ? response.message
          : 'Something went wrong. Please try again!'
      }
    })
  }
}
</script>

<style scoped lang="scss">
@mixin disabled-btn {
  cursor: not-allowed;
  pointer-events: none;
  color: #989797 !important;
}
.verification {
  &__container {
    font-family: inherit;
  }
  &__title {
    margin-top: 15px;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }
  &__resend-label {
    color: rgb(158, 38, 38);
    font-size: 1.4rem;
    line-height: 2rem;
    height: 100%;
  }
  &__error-text {
    font-size: 1.4rem;
    line-height: 2rem;
    height: 100%;
  }
  &__resend-btn {
    color: rgb(158, 38, 38);
    &--disabled {
      @include disabled-btn;
    }
  }
  &__resend-timer {
    color: rgb(158, 38, 38);
    font-weight: bolder;
    font-size: 1.4rem;
  }
}

.nomineeMessage {
  font-size: 1.4rem;
  line-height: 2rem;
}

.userMessage {
  color: rgb(158, 38, 38);
  font-size: 1.4rem;
  line-height: 2rem;
}

.terms {
  &__title {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 20px !important;
    font-weight: 500;
    line-height: 1 !important;
    letter-spacing: 0.02em !important;
  }
}
</style>
